const _isValidTime = (time, type) => {

  const error = "INVALID";

  //validate minutes
  const validateMins = (mins) => {
    try {
      mins = parseInt(mins);
      if (mins >= 0 && mins <= 60) {
        mins = mins.toString();
        mins = mins.length === 1 ? `0${mins}` : mins;
        return mins;
      } else {
        return error;
      }
    } catch (ex) {
      return error;
    }
  };

  //process time and check for the validity and 
  //parse it for the 24 hour format
  const processLongTimeFormat = (parts) => {

    let hour = parts[0],
      min = parts[1];

    try {
      hour = parseInt(hour);
    } catch (ex) {
      return error;
    }

    if (min.endsWith("am")) {
      //am format
      min = min.replace("am", "");
      if (hour > 0 && hour < 13) {
        hour = hour === 12 ? 0 : hour;
        hour = hour.toString();
        hour = hour.length === 1 ? `0${hour}` : hour;
        min = validateMins(min);
        if (min === error) return error;
      } else {
        return error;
      }
    } else if (min.endsWith("pm")) {
      //pm format
      min = min.replace("pm", "");
      if (hour > 0 && hour < 13) {
        hour = hour !== 12 ? hour += 12 : hour;
        hour = hour.toString();
        min = validateMins(min);
        if (min === error) return error;
      } else {
        return error;
      }
    } else {
      //24 hour format
      if (hour >= 0 && hour < 24) {
        hour = hour.toString();
        hour = hour.length === 1 ? `0${hour}` : hour;
        min = validateMins(min);
        if (min === error) return error;
      } else {
        return error;
      }
    }

    return {
      hour,
      min
    }

  }

  //Process 5pm, 4am, 6, 14
  const processShortTimeFormat = (time) => {
    try {

      let formattedTime;

      if (time.endsWith("am")) {
        formattedTime = processLongTimeFormat([time.replace("am", ""), '00am']);
      } else if (time.endsWith("pm")) {
        formattedTime = processLongTimeFormat([time.replace("pm", ""), '00pm']);
      } else {
        formattedTime = processLongTimeFormat([time, '00']);
      }

      return exportTime(formattedTime);

    } catch (ex) {
      return error;
    }
  }

  //Set the export format of the time
  const exportTime = (formattedTime) => {
    if (formattedTime === error) return error;
    const {
      hour,
      min
    } = formattedTime;
    return `${hour}:${min}`;
  }

  //Empty check
  if (!time || typeof time === undefined || time === null) {
    return error;
  }

  //Remove spaces and case sensitivity
  time = time.toLowerCase().split(" ").join("");; //09.35 AM -> 09.35 am -> 09.35am

  if (type === "at") {
    //Check for both . and : seperators
    if (time.indexOf(".") !== -1 && time.indexOf(":") === -1 && time.split(".").length === 2) {
      const formattedTime = processLongTimeFormat(time.split("."));
      return exportTime(formattedTime);
    } else if (time.indexOf(":") !== -1 && time.indexOf(".") === -1 && time.split(":").length === 2) {
      const formattedTime = processLongTimeFormat(time.split(":"));
      return exportTime(formattedTime);
    } else {
      //Process 5pm, 4am, 6, 14
      return processShortTimeFormat(time);
    }
  } else if (type === "in") {

    try {
      let h = 0,
        m = 0;
      if (time.indexOf("h") !== -1) {

        let hParts = time.split("h");
        h = hParts.length > 0 ? hParts[0] : 0;
        h = parseInt(h);
        let mValue = hParts.length > 1 ? hParts[1] : 0;

        if (mValue && mValue.indexOf("m") !== -1) {
          let mParts = mValue.split("m");
          m = mParts.length > 0 ? mParts[0] : 0;
          m = parseInt(m);
        }

        if (h > 24 || h < 0 || isNaN(h)) {
          return error;
        }

        if (m > 60 || m < 0 || isNaN(m)) {
          return error;
        }

      } else if (time.indexOf("m") !== -1) {

        let mParts = time.split("m");
        m = mParts.length > 0 ? mParts[0] : 0;
        m = parseInt(m);

        if (m > 60 || m < 0 || isNaN(m) || m === 0) {
          return error;
        }

      }

      return {
        h,
        m
      }

    } catch (ex) {
      return error;
    }

  }

};

export {
  _isValidTime
}