import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const PreloginSection = (props) => {
  return (
    <ReactCSSTransitionGroup
      transitionName="SlideIn"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}>

      <div className="Block">
        <div className="Block__Content">
          {props.imageUrl ? <img className="Image" src={props.imageUrl} alt="avatar" /> :
            props.isLogo ? <div className="Center"><div className="LoginLogo" /></div> : null}
          <h1>{props.title}</h1>
          <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
          <div>{props.error ? <p className="ServerError Margin-Top--4"><span className="ErrorTitle">Error:</span> {props.error}</p> : null}</div>
        </div>
        <div className="Block__Footer">
          {props.hideCancel ? null :
            <a target="_self" className="Button Button--Secondary Margin-Right--12" href='/'>
              Cancel
            </a>
          }
          <a target="_self" className="Button Button--Primary" href={props.url}>
            {props.buttonText}
          </a>
        </div>
      </div>

    </ReactCSSTransitionGroup>
  );
}

export default PreloginSection;