import React from 'react';
import { withRouter } from 'react-router-dom';
import { setActivePage } from './../../actions/index';
import { connect } from 'react-redux';
import SlideIn from './../../components/SlideIn';
import { PubSub } from 'pubsub-js';
import Utils from '../../utils/Utils';

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

class CheatSheetPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      section: 'schedule'
    }
  }

  componentDidMount() {
    this.props.setActivePage('/cheatsheet');
  }

  handleNewScheduleMessage = () => {
    Utils.trackEvent('cheatsheet:newScheduleMessage');
    PubSub.publish('handleNewScheduleMessage');
  }

  handleNewSelfDestructMessage = () => {
    Utils.trackEvent('cheatsheet:newSelfDestructMessage');
    PubSub.publish('handleNewSelfDestructMessage');
  }

  handleNewProMessage = () => {
    Utils.trackEvent('cheatsheet:newProMessage');
    PubSub.publish('handleNewProMessage');
  }

  handleViewMessages = () => {
    this.props.history.push('/messages');
  }

  handleSetSection = (section) => {
    this.setState({
      section
    });
  }

  render() {
    return (

      <SlideIn isShow={this.props.user.slack_profile}>
        <div className="Cheatsheet">

          <div className="TabsPanel">
            <div className="TabsPanel__Inner">
              <div className={"Tab Tab__First" + (this.state.section === 'schedule' ? ' Selected' : '')} onClick={this.handleSetSection.bind(this, 'schedule')}>Schedule</div>
              <div className={"Tab" + (this.state.section === 'send' ? ' Selected' : '')} onClick={this.handleSetSection.bind(this, 'send')}>Send</div>
              {/* <div  className={"Tab"  + (this.state.section==='delete' ? ' Selected' : '')} onClick={this.handleSetSection.bind(this, 'delete')}>Delete</div> */}
              <div className={"Tab Tab__Last" + (this.state.section === 'list' ? ' Selected' : '')} onClick={this.handleSetSection.bind(this, 'list')}>Timy</div>
            </div>
          </div>

          <SlideIn isShow={this.state.section === 'schedule'}>
            <div className="Section">
              <div className="Demo">
                <div className="DemoDescription">

                  <h3 className="SubTitle"><span>/schedule</span> a message with advanced options</h3>

                  <p className="CommandDescription">
                    Using <span className="Code">/schedule</span> command, you can send a message up to a <b>year</b> in advance. Send recurring messages and set custom time zones.
                    Timy will remember all the time zone mappings for each user or channel. Attach files to these messages using web app. You can later edit/clone or pause these messages as well.</p>
                  <p className="CommandDescription">This command is the swiss army knife for your all scheduling needs.</p>

                  <p className="DescSubTitle Bold Paragraph--Normal">Availability</p>
                  <p className="CommandDescription Paragraph--Normal"><b>PRO</b> users only</p>

                  <p className="DescSubTitle Bold Paragraph--Normal Margin-Top--24">Format</p>
                  <p className="Paragraph--Normal Margin-Top--4"><span className="Code Margin-Left--0">/schedule</span> <span className="Code">Your message</span></p>

                  <p className="DescSubTitle Paragraph--Normal Bold Margin-Top--24">Example</p>
                  <p className="Commands Margin-Top--4">
                    <span className="Command">/schedule</span> Hi guys, How is the task progress? <br />
                  </p>

                </div>
              </div>
            </div>

            <div className="CheatSheet__ButtonPanel">
              <button onClick={this.handleNewProMessage}
                className="Button Button--Light Button--Primary">New Scheduled Message
               </button>
            </div>

          </SlideIn>

          <SlideIn isShow={this.state.section === 'send'}>
            <div className="Section">
              <div className="Demo">
                <div className="DemoDescription">
                  <h3 className="SubTitle"><span>/send</span> a simple scheduled message</h3>

                  <p className="CommandDescription">
                    Using <span className="Code">/send</span> command, you can quickly schedule a message in your current time zone.
                   You can either use <span className="Hightlight">at</span> or <span className="Hightlight">in</span> suffixes to schedule your message up to a maximum of <b>24
                   hours</b> in future as shown below.</p>
                  <p className="CommandDescription">This command is great for quick needs and does not offer advanced customizations.</p>

                  <p className="DescSubTitle Bold Paragraph--Normal">Availability</p>
                  <p className="CommandDescription Paragraph--Normal">All users</p>

                  <p className="DescSubTitle Bold Paragraph--Normal Margin-Top--24">Format</p>
                  <p className="Paragraph--Normal Margin-Top--4"><span className="Code Margin-Left--0">/send</span> <span className="Code">Your message</span>
                    <span className="Hightlight">at</span> <span className="Code">12.30pm</span></p>
                  <p className="Paragraph--Normal Margin-Top--12"><span className="Code Margin-Left--0">/send</span> <span className="Code">Your message</span>
                    <span className="Hightlight">in</span> <span className="Code">1h30m</span></p>

                  <p className="DescSubTitle Paragraph--Normal Bold Margin-Top--24">Examples</p>
                  <p className="Commands Margin-Top--4">
                    <span className="Command">/send</span> Happy Birthday! at 12am <br />
                    <span className="Command">/send</span> I will be late for the meeting in 30m
                  </p>

                </div>
              </div>
            </div>

            <div className="CheatSheet__ButtonPanel">
              <button onClick={this.handleNewScheduleMessage}
                className="Button Button--Light Button--Primary">New Send Message
              </button>
            </div>
          </SlideIn>


          {/* <SlideIn isShow={this.state.section === 'delete'}>
            <div className="Section">
              <div className="Demo">
                <div className="DemoDescription">
                  <h3 className="SubTitle"><span>/delete</span> a message automatically</h3>
                  <p className="CommandDescription">
                    Using <span className="Code">/delete</span> command, you can send self-destruct messages.
                   You can either use <span className="Hightlight">at</span> or <span className="Hightlight">in</span> suffixes to schedule your message to be self-destruct in future as shown below.
                </p>
                  <p className="DescSubTitle">
                    Format
                </p>
                  <div>
                    <p><span className="Code">/delete</span> <span className="Code">Your message</span>
                      <span className="Code">at</span> <span className="Code">12.30pm</span></p>
                    <p><span className="Code">/delete</span> <span className="Code">Your message</span>
                      <span className="Code">in</span> <span className="Code">1h30m</span></p>
                  </div>
                  <p className="DescSubTitle">
                    Examples
                </p>
                  <p className="Commands">
                    <span className="Command">/delete</span> This is a secret! at 2pm <br />
                    <span className="Command">/delete</span> I'm hungry in 5m
                 </p>
                </div>
              </div>
            </div>

            <div className="CheatSheet__ButtonPanel">
              <button onClick={this.handleNewSelfDestructMessage}
                className="Button Button--Light Button--Primary">New Self-destruct Message
            </button>
            </div>
          </SlideIn> */}

          <SlideIn isShow={this.state.section === 'list'}>
            <div className="Section">
              <div className="Demo">
                <div className="DemoDescription">
                  <h3 className="SubTitle"><span>/timy</span> general commands</h3>
                  <p className="CommandDescription">
                    Using <span className="Code">/timy</span> command, you can list all your scheduled messages, simulate both /send and /schedule commands, open timy web app and get some help. This is the Timy toolkit for your quick needs.
                  </p>
                  <p className="DescSubTitle Paragraph--Normal Bold Margin-Top--24">Examples</p>
                  <p className="Commands Margin-Top--4">
                    <span className="Command">/timy</span> <b>list</b> : List all scheduled messages<br />
                    <span className="Command">/timy</span> <b>send [message] [in/at] [time]</b> : Same as /send slash command<br />
                    <span className="Command">/timy</span> <b>schedule [message]</b> : Same as /schedule slash command<br />
                    <span className="Command">/timy</span> <b>app</b> : Shortcut for the web app<br />
                    <span className="Command">/timy</span> <b>help</b> : Get some help
                  </p>
                </div>
              </div>
            </div>

            <div className="CheatSheet__ButtonPanel">
              <button onClick={this.handleViewMessages}
                className="Button Button--Light Button--Primary">View All Messages
            </button>
            </div>
          </SlideIn>


        </div>
      </SlideIn>
    )
  }
}

export default withRouter(connect(mapStateToProps, { setActivePage })(CheatSheetPage));