import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import Constants from '../../utils/Constants';
import PreLoginSection from '../../components/PreLoginSection';
import {
  connect
} from 'react-redux';
import {
  setActivePage
} from '../../actions/index';
import Utils from '../../utils/Utils';

class LoginPage extends React.Component {

  redirectAfterLogIn() {
    this.props.history.push('/messages');
  }

  componentDidMount() {
    this.props.setActivePage('/login');
    if (Utils.isLoggedIn()) {    
      this.redirectAfterLogIn();
    }
  }

  render() {
    return (<PreLoginSection 
      title="Timy"
      isLogo={true}
      message="Sign in with Timy to send scheduled messages in your Slack workspace."
      url={Constants.AUTH.URLS.TO_LOGIN}
      hideCancel={true}
      buttonText="Sign in with Slack" />
    );
  }

}

export default withRouter(connect(null, {
  setActivePage
})(LoginPage));