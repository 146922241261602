import React, { Component, Fragment } from 'react';
import InfiniteProgressBar from '../../components/InfiniteProgressBar';
import SlideIn from './../../components/SlideIn';
import DateTime from '../../components/DateTime';

class Message extends Component {

  render() {

    const message = this.props.message;

    return (
      <div key={message.message_id} className={"Message" +
        (this.props.isMessageDeleting && message.message_id === this.props.currentMessageId ? ' IsDeleting' : '')}>
        <div className={"Message__Avatar" + (message.message_type === "pro" && message.recurring_type !== "Only once" && !message.is_active ? ' Grayscale--7' : '')}>

          <SlideIn isShow={message.to_type === 'user' && message.target_user}>
            {message.to_type === 'user' && message.target_user ?
              <img className="Message__Avatar--User" src={message.target_user.image_192} alt="avatar" href="" />
              : null}
          </SlideIn>

          <SlideIn isShow={message.to_type === 'group' && message.target_group}>
            {message.to_type === 'group' && message.target_group ?
              <div className="Message__Avatar--Channel" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4">
                  </circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75">
                  </path>
                </svg>
              </div> : null}
          </SlideIn>

          <SlideIn isShow={message.to_type === 'channel' && message.target_channel && message.target_channel.is_private}>
            {message.to_type === 'channel' && message.target_channel && message.target_channel.is_private ?
              <div className="Message__Avatar--Channel" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round">
                  <rect x="3" y="11"
                    width="18" height="11" rx="2" ry="2"></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
              </div> : null}
          </SlideIn>

          <SlideIn isShow={message.to_type === 'channel' && message.target_channel && !message.target_channel.is_private}>
            {message.to_type === 'channel' && message.target_channel && !message.target_channel.is_private ?
              <div className="Message__Avatar--Channel" >#</div> : null}
          </SlideIn>

          <SlideIn isShow={(this.props.isUsersLoaded && this.props.isChannelsLoaded) &&
            !(message.to_type === 'user' && message.target_user) &&
            !(message.to_type === 'group' && message.target_group) &&
            !(message.to_type === 'channel' && message.target_channel && message.target_channel.is_private) &&
            !(message.to_type === 'channel' && message.target_channel && !message.target_channel.is_private)}>

            {(this.props.isUsersLoaded && this.props.isChannelsLoaded) &&
              !(message.to_type === 'user' && message.target_user) &&
              !(message.to_type === 'group' && message.target_group) &&
              !(message.to_type === 'channel' && message.target_channel && message.target_channel.is_private) &&
              !(message.to_type === 'channel' && message.target_channel && !message.target_channel.is_private) ?
              <div className="Message__Avatar--Channel" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4">
                  </circle><line x1="18" y1="8" x2="23" y2="13"></line>
                  <line x1="23" y1="8" x2="18" y2="13"></line></svg>
              </div> : null}
          </SlideIn>

        </div>
        <div className={"Message__Body" + (message.message_type === "pro" && message.recurring_type !== "Only once" && !message.is_active ? ' Grayscale--7' : '')}>
          <div className="Message_To">

            <SlideIn isShow={message.to_type === 'channel' && message.target_channel}>
              {message.to_type === 'channel' && message.target_channel ? <span>#{message.target_channel.label}</span> : null}
            </SlideIn>

            <SlideIn isShow={message.to_type === 'user' && message.target_user}>
              {message.to_type === 'user' && message.target_user ? <span>{message.target_user.label}</span> : null}
            </SlideIn>

            <SlideIn isShow={(this.props.isUsersLoaded && this.props.isChannelsLoaded) &&
              !(message.to_type === 'user' && message.target_user) &&
              !(message.to_type === 'group' && message.target_group) &&
              !(message.to_type === 'channel' && message.target_channel && message.target_channel.is_private) &&
              !(message.to_type === 'channel' && message.target_channel && !message.target_channel.is_private)}>
              <span className="Color--Red">Inaccessible Conversation</span>
            </SlideIn>

            <SlideIn className="Vertical--Center" isShow={message.to_type === 'group' && message.target_group}>
              <Fragment>
                {message.to_type === 'group' && message.target_group ?
                  message.target_group.map((person, index) => {
                    return <div className="GroupIcon" key={index}>
                      <img alt={person.name} className="GroupIcon__Avatar" src={person.avatar} />
                      <div className="GroupIcon__Tooltip">{person.name}</div>
                    </div>
                  }) : null}
                {message.to_type === 'group' && message.more > 0 ? <span className="GroupIcon__More"> and {message.more} more</span> : null}
              </Fragment>
            </SlideIn>

          </div>

          {message.message_type === 'delete' ? <div className="Margin-Top--12 Margin-Bottom--8">
            <span className="Font-Size--12 Tag--Danger">self-destruct</span>
          </div> : null}

          <div dangerouslySetInnerHTML={{ __html: message.messageHTML }} className="Message_Content"></div>

          {message.file ? <a className="Link__Plain" href={message.file.url} target="_blank" rel="noopener noreferrer"><div className="MessageComposerParent MessageFileBlock">
            <div className="FileBlock">
              <div className="MessageCompletedBlock">
                <svg className="MessageCompletedBlock__Icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
                  stroke="#5a44ff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M13 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V9l-7-7z" /><path d="M13 3v6h6" />
                </svg>
                <div title={message.file.name} className="MessageCompletedBlock__Desc">
                  <p className="MessageCompletedBlock__Desc--Title">{message.file.name}</p>
                  <p className="MessageCompletedBlock__Desc--SubTitle">{message.file.size} {message.file.type}</p>
                </div>
              </div>
            </div>
          </div></a> : null}


          <div className="Message_Attrs">
            <div className="Message_Attrs--Section">
              <h5>Date Time:</h5>
              <DateTime timestamp={message.utc_ts} id={message.message_id} displayDate={message.scheduled_date} />

              {message.recurring_type !== "Only once" ? <span className="Tag Gradient--Purple">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M17 2.1l4 4-4 4" /><path d="M3 12.2v-2a4 4 0 0 1 4-4h12.8M7 21.9l-4-4 4-4" />
                  <path d="M21 11.8v2a4 4 0 0 1-4 4H4.2" /></svg>
                {message.recurring_type}</span> : null}

            </div>
            <div className="Message_Attrs--Section">
              <h5>Time Zone:</h5>
              <p>{message.timezone}</p>
            </div>
          </div>

          {message.message_type === "pro" && (this.props.team && (this.props.team.status === 0 || this.props.team.status === 2)) ?
            <div className="Message__Error__Notification">
              <p className="Paragraph--Normal">This message <span className="Font-Color--Red"><b>won't be delivered.</b></span> Upgrade to <b>PRO</b> plan to enable this message.</p>
            </div> : null}

        </div>

        <SlideIn isShow={message.message_type === "pro" && message.recurring_type !== "Only once" && !message.is_active}>
          <span className="Tag__Absolute Message__Paused">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
              fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16">
              </rect></svg>
            Paused
          </span>
        </SlideIn>


        {!this.props.isMessageDeleting ? <div className="OptionsButton">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
            stroke="#999999" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12"
              r="1"></circle><circle cx="12" cy="5" r="1"></circle>
            <circle cx="12" cy="19" r="1"></circle>
          </svg>

          <div className="Popover MessageMenu">

            {message.message_type === "pro" && this.props.isActionable ? <div onClick={this.props.onEditProMessage} className="Popover__Item">
              <p className="Popover__Item__Text">Edit Message {this.props.team && (this.props.team.status === 0 || this.props.team.status === 2) ? <span className="ProBadgeLight">PRO</span> : null}</p>
            </div> : null}

            {message.message_type === "pro" && this.props.isActionable ? <div onClick={this.props.onCloneProMessage} className="Popover__Item">
              <p className="Popover__Item__Text">Clone {this.props.team && (this.props.team.status === 0 || this.props.team.status === 2) ? <span className="ProBadgeLight">PRO</span> : null}</p>
            </div> : null}

            {message.message_type === "pro" && message.recurring_type !== "Only once" && message.is_active ?
              <div onClick={this.props.onActivateMessage} className="Popover__Item">
                <p className="Popover__Item__Text">Pause {this.props.team && (this.props.team.status === 0 || this.props.team.status === 2) ? <span className="ProBadgeLight">PRO</span> : null}</p>
              </div> : null}

            {message.message_type === "pro" && message.recurring_type !== "Only once" && !message.is_active ?
              <div onClick={this.props.onActivateMessage} className="Popover__Item">
                <p className="Popover__Item__Text">Activate {this.props.team && (this.props.team.status === 0 || this.props.team.status === 2) ? <span className="ProBadgeLight">PRO</span> : null}</p>
              </div> : null}

            <div onClick={this.props.onDeleteMessage} className="Popover__Item">
              <p className="Popover__Item__Text">Delete</p>
            </div>
          </div>

        </div> : null}

        <InfiniteProgressBar classNames="MessageProgressBar"
          isShow={this.props.isMessageDeleting && message.message_id === this.props.currentMessageId}></InfiniteProgressBar>

      </div>
    );
  }
}

export default Message;
