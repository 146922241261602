import React, { Component } from 'react';
import GroupButton from './GroupButton';
import { PubSub } from 'pubsub-js';


class ConfirmPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      title: '',
      yesButtonText: '',
      noButtonText: '',
      message: ''
    }
    this.onYes = null;
    this.onCancel = null;
  }

  componentDidMount() {
    PubSub.subscribe('showConfirm', (msg, data) => {
      this.setState({
        isVisible: true,
        message: data.message,
        title: data.title ? data.title : 'Please Confirm',
        yesButtonText: data.yesButtonText ? data.yesButtonText : 'Yes',
        noButtonText: data.noButtonText ? data.noButtonText : 'No',
      }, () => {
        this.onYes = data.onYes;
        this.onCancel = data.onCancel;
      });
    });
  }

  handleNo = () => {
    this.setState({
      isVisible: false
    }, () => {
      if (this.onCancel && typeof this.onCancel === "function") {
        this.onCancel();
      }
    });
  }

  handleYes = () => {
    this.setState({
      isVisible: false
    }, () => {
      if (this.onYes && typeof this.onYes === "function") {
        this.onYes();
      }
    });
  }

  render() {
    return (
      <div role="dialog" aria-labelledby="confirm" tabIndex="-1" aria-describedby="pleaseConfirmAction"
        className={"Popup__Overlay" + (this.state.isVisible ? ' Open' : '')}>
        <div role="document" className="Popup__Container">
          <div className="Popup__Head">
            <h3>{this.state.title}</h3>
          </div>
          <div className="Popup__Body Alert">
            <p dangerouslySetInnerHTML={{ __html: this.state.message }}></p>
          </div>
          <div className="Popup__Footer">
            <div className="Popup__Footer__Actions">
              <GroupButton
                onClick={this.handleNo}
                classNames="Button--Secondary Margin-Right--12"
                text={this.state.noButtonText} />
              <GroupButton
                onClick={this.handleYes}
                classNames="Button--Primary"
                text={this.state.yesButtonText} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmPopup;