import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Utils from './../utils/Utils';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => {

  return (<Route {...rest} render={(props) => (
    Utils.isLoggedIn()
      ? (rest.user.user_state === 'onboarding' && window.location.pathname !== '/welcome') ? <Redirect to='/welcome'/> : 
        (rest.user.user_state !== 'onboarding' && window.location.pathname === '/welcome') ? <Redirect to='/messages'/> : <Component {...props} />
      : <Redirect to='/login' />
  )} />);

}
export default connect(mapStateToProps)(PrivateRoute);