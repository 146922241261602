import React from 'react';
import { withRouter } from 'react-router-dom';
import { setActivePage } from './../../actions/index';
import { connect } from 'react-redux';
import SlideIn from './../../components/SlideIn';
import GroupButton from '../../components/GroupButton';
import Utils from './../../utils/Utils';
import Constants from './../../utils/Constants';

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

class WelcomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      termsChecked: true,
      onboarding: true,
      plan_changes_emails: true,
      message_fail_emails: true,
      newsletter: true,
      offers_emails: true,
      isTermsUpdating: false
    }

    this.allEmails = React.createRef();
  }

  componentDidMount() {
    this.allEmails.current.checked = true;
    this.props.setActivePage('/welcome');
  }

  handleTermsCheckbox = () => {
    this.setState({
      termsChecked: this.refs.terms.checked
    });
  }

  handleUpdatesCheckbox = () => {
    this.setState({
      onboarding: this.refs.onboardingEmailCheckBox.checked,
      plan_changes_emails: this.refs.planChangesEmailCheckBox.checked,
      message_fail_emails: this.refs.failedMessagesEmailCheckBox.checked,
      newsletter: this.refs.newFeaturesEmailCheckBox.checked,
      offers_emails: this.refs.offersEmailCheckBox.checked
    }, () => {
      if (this.state.onboarding &&
        this.state.plan_changes_emails &&
        this.state.message_fail_emails &&
        this.state.newsletter &&
        this.state.offers_emails) {
        this.allEmails.current.checked = true;
        this.allEmails.current.indeterminate = false;
      } else if (!this.state.onboarding &&
        !this.state.plan_changes_emails &&
        !this.state.message_fail_emails &&
        !this.state.newsletter &&
        !this.state.offers_emails) {
        this.allEmails.current.checked = false;
        this.allEmails.current.indeterminate = false;
      } else {
        this.allEmails.current.indeterminate = true;
      }
    });
  }

  handleAllUpdatesCheckbox = () => {
    if (this.allEmails.current.checked || this.allEmails.current.indeterminate) {
      this.setState({
        onboarding: true,
        plan_changes_emails: true,
        message_fail_emails: true,
        newsletter: true,
        offers_emails: true
      });
    } else {
      this.setState({
        onboarding: false,
        plan_changes_emails: false,
        message_fail_emails: false,
        newsletter: false,
        offers_emails: false
      });
    }
  }


  handleOK = () => {

    Utils.trackEvent('welcome');

    if (this.state.termsChecked) {
      this.setState({
        isTermsUpdating: true
      });

      Utils.fetch({
        method: 'post',
        url: Constants.API.Terms_Update,
        data: {
          terms_agreed: this.state.termsChecked,
          onboarding: this.state.onboarding,
          plan_changes_emails: this.state.plan_changes_emails,
          message_fail_emails: this.state.message_fail_emails,
          newsletter: this.state.newsletter,
          offers_emails: this.state.offers_emails,
        }
      }).then(response => {
        window.location.href = '/messages';
      }).catch(error => {
        Utils.showToast('error', error.desc);
        this.setState({
          isTermsUpdating: false
        });
      });
    }
  }

  render() {
    return (
      <SlideIn className="Display--Block" isShow={this.props.user.slack_profile}>

        <div className={"Welcome Padding--0" + (this.state.isTermsUpdating ? ' Disabled' : '')}>
          <div className="Hero"></div>
          <h1 className="Heading">Welcome to timy!</h1>
          <p className="Text">Timy is the best way to send scheduled messages in Slack. Before we start, please check off the details below to set up your account.</p>
          <div className="Form">

            <label htmlFor="terms" className="Container"> I agree to the timy <a rel="noopener noreferrer" target="_blank" href="https://timy.website/terms">terms of use</a> and <a rel="noopener noreferrer" target="_blank" href="https://timy.website/privacy">privacy policy</a>*
              <input ref="terms" onChange={this.handleTermsCheckbox} id="terms" checked={this.state.termsChecked} type="checkbox" />
              <span className="Checkmark"></span>
            </label>


            <div className="SettingsSectionBlock">

              <label htmlFor="all_emails" className="Container"> Receive emails from Timy
                    <input ref={this.allEmails} onChange={this.handleAllUpdatesCheckbox} id="all_emails" type="checkbox" />
                <span className="Checkmark"></span>
              </label>

              <div className="PaddingLeft--36">

                <h5>Application</h5>

                <label htmlFor="onboarding_notifications" className="Container"> User onboarding
                    <input ref="onboardingEmailCheckBox" onChange={this.handleUpdatesCheckbox} checked={this.state.onboarding} id="onboarding_notifications" type="checkbox" />
                  <span className="Checkmark"></span>
                </label>

                <label htmlFor="plan_change_notifications" className="Container"> Trial period and subscription plan changes
                    <input ref="planChangesEmailCheckBox" onChange={this.handleUpdatesCheckbox} checked={this.state.plan_changes_emails} id="plan_change_notifications" type="checkbox" />
                  <span className="Checkmark"></span>
                </label>

                <label htmlFor="fail_notifications" className="Container"> When scheduled messages are failed to send
                    <input ref="failedMessagesEmailCheckBox" onChange={this.handleUpdatesCheckbox} checked={this.state.message_fail_emails} id="fail_notifications" type="checkbox" />
                  <span className="Checkmark"></span>
                </label>

                <h5>News and Updates</h5>

                <label htmlFor="feature_updates_notifications" className="Container"> New feature updates
                    <input ref="newFeaturesEmailCheckBox" onChange={this.handleUpdatesCheckbox} checked={this.state.newsletter} id="feature_updates_notifications" type="checkbox" />
                  <span className="Checkmark"></span>
                </label>

                <label htmlFor="offers_notifications" className="Container"> Offers and promotions
                    <input ref="offersEmailCheckBox" onChange={this.handleUpdatesCheckbox} checked={this.state.offers_emails} id="offers_notifications" type="checkbox" />
                  <span className="Checkmark"></span>
                </label>
              </div>
            </div>

            <div className="Row Center Submit">
              <GroupButton
                onClick={this.handleOK}
                classNames="Button--Primary"
                isLoading={this.state.isTermsUpdating}
                isDisabled={!this.state.termsChecked}
                text="Let's Go!" />
            </div>

          </div>
        </div>

      </SlideIn>
    )
  }
}

export default withRouter(connect(mapStateToProps, { setActivePage })(WelcomePage));