import React from 'react';
import { withRouter } from 'react-router-dom';
import { setActivePage, getUserMessagesData, getChannelListData, getUserListData, setMessageData } from './../../actions/index';
import { connect } from 'react-redux';
import NoMessagesComponent from './NoMessagesComponent';
import SlideIn from './../../components/SlideIn';
import InfiniteProgressBar from '../../components/InfiniteProgressBar';
import Constants from './../../utils/Constants';
import Utils from '../../utils/Utils';
import Processor from '../../utils/Processor';
import Message from './Message';
import { PubSub } from 'pubsub-js';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    messages: state.messages,
    team_users: state.team_users,
    team_channels: state.team_channels,
    team_groups: state.team_channels,
    channel_ids: state.channel_ids,
    user_ids: state.user_ids,
    team_direct_messages: state.team_direct_messages,
    is_messages_loading: state.is_messages_loading,
    is_users_loaded: state.is_users_loaded,
    is_messages_loaded: state.is_messages_loaded,
    is_channels_loaded: state.is_channels_loaded,
    is_groups_loaded: state.is_channels_loaded,
    is_team_users_loading: state.is_team_users_loading
  }
}

class MessagesPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_message_deleting: false
    };
  }

  componentDidMount() {
    this.props.setActivePage('/messages');
    this.getMessagesList();
  }

  getMessagesList() {

    const getChannelData = () => {
      if (!this.props.is_channels_loaded) {
        this.props.getChannelListData().then(res => {
          this.setChannelProfileData();
        });
      }
    }

    const getUserData = () => {
      if (!this.props.is_users_loaded) {
        this.props.getUserListData().then(res => {
          this.setUserProfileData();
          getChannelData();
        });
      } else {
        this.setUserProfileData();
        getChannelData();
      }
    }

    if (!this.props.is_messages_loaded) {
      this.props.getUserMessagesData().then(res => {
        getUserData();
      });
    } else {
      getUserData();
    }

  }

  setUserProfileData() {
    const messages = this.props.messages.map(message => {
      return Processor.processMessageForUser(this.props, message);
    });
    this.props.setMessageData(messages);
  }

  setChannelProfileData() {
    const messages = this.props.messages.map(message => {
      return Processor.processMessageForChannel(this.props, message);
    });
    this.props.setMessageData(messages);
  }

  handleMessageDeleteConfirm = () => {

    Utils.trackEvent('messages:deleteScheduledMessage');

    this.setState({
      is_message_deleting: true
    });

    Utils.fetch({
      method: 'delete',
      url: Constants.API.Message_Delete,
      data: {
        message_id: this.current_message_id
      }
    }).then(response => {
      Utils.showToast('success', Constants.MESSAGES.MESSAGE_DELETE_SUCCESS);

      this.props.setMessageData(this.props.messages.filter((message) => {
        if (message.message_id !== response.data.message_id) {
          return message;
        } else {
          return null;
        }
      }));

      this.setState({
        is_message_deleting: false
      });
    }).catch(error => {
      Utils.showToast('error', error.desc);
      this.setState({
        is_message_deleting: false
      });
    });

  }


  isProUser = () => {
    return (this.props.user.team.status === 1 || this.props.user.team.status === 3);
  }

  handleActivateMessage = (message_id, is_active) => {
    if (this.isProUser()) {

      Utils.trackEvent('messages:activateDeactivateScheduledMessage');
      this.current_message_id = message_id;

      this.setState({
        is_message_deleting: true
      });

      Utils.fetch({
        method: 'post',
        url: Constants.API.Message_Activate,
        data: {
          message_id,
          is_active: !is_active
        }
      }).then(response => {

        if (response.data.is_active) {
          Utils.showToast('success', Constants.MESSAGES.MESSAGE_ACTIVATE_SUCCESS);
        }

        this.props.setMessageData(this.props.messages.map((message) => {
          if (message.message_id === response.data.message_id) {
            message.is_active = response.data.is_active;
          }
          return message;
        }));

        this.setState({
          is_message_deleting: false
        });

      }).catch(error => {
        Utils.showToast('error', error.desc);
        this.setState({
          is_message_deleting: false
        });
      });

    } else {
      PubSub.publish('showProFeaturePopup');
    }

  }

  handleDeleteMessage = (message_id) => {
    this.current_message_id = message_id;
    PubSub.publish('showConfirm', {
      message: "Are you sure you want to delete this message?",
      onYes: this.handleMessageDeleteConfirm
    });
  }

  handleEditProMessage = (message) => {
    if (this.isProUser()) {
      PubSub.publish('handleEditProMessage', message);
    } else {
      PubSub.publish('showProFeaturePopup');
    }
  }

  handleCloneProMessage = (message) => {
    if (this.isProUser()) {
      PubSub.publish('handleCloneProMessage', message);
    } else {
      PubSub.publish('showProFeaturePopup');
    }
  }

  render() {

    return (
      <SlideIn className="Display--Block" isShow={this.props.user.slack_profile}>

        <InfiniteProgressBar classNames="PageProgressBar" isShow={this.props.is_messages_loading}></InfiniteProgressBar>

        <NoMessagesComponent isShow={this.props.messages.length === 0 && !this.props.is_messages_loading} />

        <div className={"MessageContainer" + (this.props.is_team_users_loading ? ' IsUsersLoading' : '')}>

          {this.props.user ? <SlideIn isShow={this.props.messages.length > 0 && !Utils.hasMessagePermission(this.props.user)}>
            <div className="Info Info--Error">
              <div className="Info__Desc">               
                <p><b>The workspace admin has disabled your Timy access.</b><br/>Scheduled messages won't be delivered.</p>
              </div>
            </div>
          </SlideIn> : null}

          <SlideIn isShow={!this.props.is_messages_loading}>
            {this.props.messages.map((message) => {
              return <Message
                key={message.message_id}
                message={message}
                team={this.props.user.team}
                isActionable={this.props.is_channels_loaded && this.props.is_users_loaded}
                isUsersLoaded={this.props.is_users_loaded}
                isChannelsLoaded={this.props.is_channels_loaded}
                onDeleteMessage={this.handleDeleteMessage.bind(this, message.message_id)}
                onEditProMessage={this.handleEditProMessage.bind(this, message)}
                onCloneProMessage={this.handleCloneProMessage.bind(this, message)}
                onActivateMessage={this.handleActivateMessage.bind(this, message.message_id, message.is_active)}
                currentMessageId={this.current_message_id}
                isMessageDeleting={this.state.is_message_deleting}>
              </Message>
            })}
          </SlideIn>

        </div>

      </SlideIn >
    )
  }
}

export default withRouter(connect(mapStateToProps,
  {
    setActivePage,
    getUserMessagesData,
    getChannelListData,
    getUserListData,
    setMessageData
  })(MessagesPage));