import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { setActivePage, getUserData, getUserListData } from '../../actions/index';
import SlideIn from './../../components/SlideIn';
import { connect } from 'react-redux';
import { PubSub } from 'pubsub-js';
import GroupButton from './../../components/GroupButton';
import Constants from './../../utils/Constants';
import Utils from './../../utils/Utils';
import SubscriptionCancelPopup from './SubscriptionCancelPopup';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    is_users_loaded: state.is_users_loaded,
    user_ids: state.user_ids
  }
}

class BillingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMonthlyPlanActivating: false,
      isYearlyPlanActivating: false,
      proPlanAddedBy: {
        label: '',
        image_192: ''
      }
    }
  }

  componentDidMount() {
    this.props.setActivePage('/billing');

    const setProPlanUser = () => {
      if (this.props.user_ids[this.props.user.team.subscription.created_by_user_id]) {
        this.setState({
          proPlanAddedBy: {
            label: this.props.user_ids[this.props.user.team.subscription.created_by_user_id].label,
            image_192: this.props.user_ids[this.props.user.team.subscription.created_by_user_id].image_192
          }
        });
      }
    }

    //Fetch users list and render the person details of pro plan activated if it matches the condition
    if (this.props.user.team.subscription.created_by_user_id !== this.props.user.user_id) {
      if (!this.props.is_users_loaded) {
        this.props.getUserListData().then(res => {
          setProPlanUser();
        });
      } else {
        setProPlanUser();
      }
    }

  }

  handleStartTrial = () => {
    PubSub.publish('showTrialStarter');
  }

  handleBuyMonthlyProPlan = () => {
    this.setState({
      isMonthlyPlanActivating: true
    });

    this.props.getUserData(true).then(res => {
      this.setState({
        isMonthlyPlanActivating: false
      });
      if ((this.props.user.team.status !== 3 && this.props.user.team.status !== 0) ||
        (this.props.user.team.status === 3 && this.props.user.team.subscription.status === Constants.PADDLE.STATES.DELETED)) {
        window.Paddle.Setup({
          vendor: Constants.PADDLE.VENDOR_ID
        });
        window.Paddle.Checkout.open({
          product: Constants.PADDLE.MONTHLY_PLAN_PRODUCT_ID,
          email: this.props.user.email,
          success: "/billing?subscription=success",
          passthrough: JSON.stringify({
            upgraded_by_user_email: this.props.user.email,
            upgraded_by_user_id: this.props.user.user_id,
            upgraded_by_team_id: this.props.user.team_id,
            upgraded_by_team_name: this.props.user.team_name
          })
        });
      } else {
        Utils.showToast('error', Constants.MESSAGES.ALREADY_ON_PRO_PLAN);
      }
    });
  }

  handleBuyYearlyProPlan = () => {
    this.setState({
      isYearlyPlanActivating: true
    });
    this.props.getUserData(true).then(res => {
      this.setState({
        isYearlyPlanActivating: false
      });
      if ((this.props.user.team.status !== 3 && this.props.user.team.status !== 0) ||
        (this.props.user.team.status === 3 && this.props.user.team.subscription.status === Constants.PADDLE.STATES.DELETED)) {
        window.Paddle.Setup({
          vendor: Constants.PADDLE.VENDOR_ID
        });
        window.Paddle.Checkout.open({
          product: Constants.PADDLE.YEARLY_PLAN_PRODUCT_ID,
          email: this.props.user.email,
          success: "/billing?subscription=success",
          passthrough: JSON.stringify({
            upgraded_by_user_email: this.props.user.email,
            upgraded_by_user_id: this.props.user.user_id,
            upgraded_by_team_id: this.props.user.team_id,
            upgraded_by_team_name: this.props.user.team_name
          })
        });
      } else {
        Utils.showToast('error', Constants.MESSAGES.ALREADY_ON_PRO_PLAN);
      }
    });
  }

  handleUpdateBilling = () => {
    window.Paddle.Setup({
      vendor: Constants.PADDLE.VENDOR_ID
    });
    window.Paddle.Checkout.open({
      override: this.props.user.team.subscription.update_url
    });
  }

  handleCancelBilling = () => {
    PubSub.publish("showSubscriptionCancelPopup", {
      subscription_id: this.props.user.team.subscription.subscription_id
    });
  }

  render() {

    return (

      <SlideIn isShow={this.props.user}>
        <div className="">

          <div className="TabsPanel">
            <div className="TabsPanel__Inner">
              <NavLink className="Link__Plain" to="/settings"><div className="Tab Tab__First">Preferences</div></NavLink>
              <div className="Tab Tab__Last Selected">Billing</div>
            </div>
          </div>

          <SlideIn isShow={this.props.user && this.props.user.team && this.props.user.team.status === 0}>
            <div className="Section">
              <div className="NoMessages Minimal">
                <div className="NoMessages__Desc__Container">
                  <div className="NoMessages__Logo"></div>
                  <h3 className="NoMessages__Title">
                    You're on Free Plan</h3>
                  <p className="NoMessages__Text MaxWidth--100">
                    Start a 14 day trial of <span className="ProBadge">PRO</span> plan and get access to all the features.
                    <br />No credit card required!
                  </p>
                  <button onClick={this.handleStartTrial} className="Button Button--Light Button--Primary">Start 14 day Trial</button>
                </div>
              </div>
            </div>
          </SlideIn>

          <SlideIn isShow={this.props.user && this.props.user.team && (this.props.user.team.status === 1 || this.props.user.team.status === 2)}>
            <div className="Section Vertical--Center Flex-Direction--Column">

              {this.props.user.team.status === 1 ? <h2 className="Text-Align--Center">Your workspace has <span className="Underline">{this.props.user.team && this.props.user.team.pro.remainingTrialDates} {this.props.user.team && this.props.user.team.pro.remainingTrialDates > 1 ? 'days' : 'day'}</span> left in the trial period.</h2> : null}
              {this.props.user.team.status === 2 ? <h2 className="Text-Align--Center">Your workspace is currently on Timy FREE plan.</h2> : null}

              <p className="Paragraph--Normal Text-Align--Center">Upgrade <b>{this.props.user.team_name}</b> to the PRO plan and get access to all features.
              <br />*Following prices are in USD for the <b className="Underline">entire workspace</b>. Local taxes are not included.</p>

              <div className="PricesContainer">
                <div className="PriceBlock">
                  <h3 className="PriceBlock__Title">Monthly Plan</h3>
                  <p className="Paragraph--Small Text-Align--Center Color--Gray">Billed monthly</p>
                  <p className="PriceBlock__Price">{Constants.PADDLE.PRICES.MONTHLY}<span className="PriceBlock__Price--Label">/month</span></p>
                  <GroupButton classNames="Button--Primary" onClick={this.handleBuyMonthlyProPlan}
                    isDisabled={this.state.isMonthlyPlanActivating} isLoading={this.state.isMonthlyPlanActivating} text={"Buy Now"}></GroupButton>
                  <p className="PriceBlock__Cancel">Cancel anytime</p>
                </div>
                <div className="PriceBlock">
                  <h3 className="PriceBlock__Title">Yearly Plan</h3>
                  <p className="Paragraph--Small Text-Align--Center Color--Gray">Billed yearly</p>
                  <p className="PriceBlock__Price">{Constants.PADDLE.PRICES.YEARLY}<span className="PriceBlock__Price--Label">/month</span></p>
                  <GroupButton classNames="Button--Primary" onClick={this.handleBuyYearlyProPlan}
                    isDisabled={this.state.isYearlyPlanActivating} isLoading={this.state.isYearlyPlanActivating}
                    text={"Buy Now"}></GroupButton>
                  <p className="PriceBlock__Cancel">Cancel anytime</p>
                  <div className="PriceBlock__Profit">Save 20%</div>
                </div>
              </div>

              <h2 className="Margin--0">With <span className="ProBadge">PRO</span> plan you'll be able to,</h2>
              <ul className="Ticks">
                <li>Send recurring messages (Every weekday, Every month...)</li>
                <li>Send unlimited messages in any time zone</li>
                <li>Increase scheduling time limit up to <b>one year</b></li>
                <li>Save time zone mappings for each user or channel</li>
                <li>Pause recurring messages if you are away</li>
                <li>Attach files to scheduling messages via web app</li>
                <li>Get Slackbot notifications for scheduled messages</li>
                <li>Edit/Clone scheduled messages</li>
                <li>Control individual Timy permissions (Admin only)</li>
              </ul>

            </div>
          </SlideIn>


          <SlideIn isShow={this.props.user && this.props.user.team && this.props.user.team.status === 3 &&
            this.props.user.team.subscription.created_by_user_id === this.props.user.user_id}>
            <div className="Section Margin-Top--24 Flex-Direction--Column">
              <p className="Paragraph--Normal Text-Align--Center"><b>{this.props.user.team_name}</b> is currently on Timy <span className="ProBadge"><b>PRO</b></span> plan and it is managed by <b>you</b>.</p>
              <div className="Subscription">

                {this.props.user.team && this.props.user.team.subscription.status === Constants.PADDLE.STATES.ACTIVE ? <>
                  <div className="Subscription__Columns">
                    <div className="Subscription__Columns--Block">
                      <h4>Plan</h4>
                      <p className="Paragraph--Normal">${this.props.user.team.subscription.unit_price} {this.props.user.team.subscription.subscription_plan} <span className="Font-Size--12 Tag--Success">ACTIVE</span></p>
                    </div>
                    <div className="Subscription__Columns--Block">
                      <h4>Next Billing Date</h4>
                      <p className="Paragraph--Normal">{this.props.user.team.subscription.next_bill_date} <br/> <a target="_blank" className="Link__Plain" rel="noopener noreferrer" href={this.props.user.team.subscription.receipt_url}><span className="Font-Size--12 Tag--Blank">INVOICE</span></a></p>
                    </div>
                  </div>
                  <div className="Subscription__Columns">
                    <div className="Subscription__Columns--Block">
                      <GroupButton onClick={this.handleUpdateBilling} classNames="Font-Size--14 Button--Secondary" text={"Update Billing"}></GroupButton>
                    </div>
                    <div className="Subscription__Columns--Block">
                      <GroupButton onClick={this.handleCancelBilling} classNames="Font-Size--14 Button--Dangerous" text={"Cancel PRO Plan"}></GroupButton>
                    </div>
                  </div>
                </> : null}

                {this.props.user.team && this.props.user.team.subscription.status === Constants.PADDLE.STATES.PAST_DUE ? <>
                  <div className="Subscription__Columns">
                    <div className="Subscription__Columns--Block">
                      <h4>Plan</h4>
                      <p className="Paragraph--Normal">${this.props.user.team.subscription.unit_price} {this.props.user.team.subscription.subscription_plan} <br /><span className="Font-Size--12 Tag--Warning">PAST DUE</span></p>
                    </div>
                    <div className="Subscription__Columns--Block">
                      <h4>Billing Date</h4>
                      <p className="Paragraph--Normal">{this.props.user.team.subscription.next_bill_date}</p>
                    </div>
                  </div>
                  <div className="Subscription__Columns">
                    <div className="Subscription__Columns--Block">
                      <h4>Failed Attempts</h4>
                      <p className="Paragraph--Normal">{this.props.user.team.subscription.attempt_number}</p>
                    </div>
                    <div className="Subscription__Columns--Block">
                      <h4>Next Attempt on</h4>
                      <p className="Paragraph--Normal">{this.props.user.team.subscription.next_retry_date}</p>
                    </div>
                  </div>
                  <p className="Paragraph--Normal Margin-Bottom--24"><span className="Font-Color--Red"><b>We could not bill you for the next billing cycle.</b></span> After 3 failed attempts the PRO plan will be cancelled automatically. Make sure your billing details are valid before the next attempt.</p>
                  <div className="Subscription__Columns">
                    <div className="Subscription__Columns--Block">
                      <GroupButton onClick={this.handleUpdateBilling} classNames="Font-Size--14 Button--Secondary" text={"Update Billing"}></GroupButton>
                    </div>
                    <div className="Subscription__Columns--Block">
                      <GroupButton onClick={this.handleCancelBilling} classNames="Font-Size--14 Button--Dangerous" text={"Cancel PRO Plan"}></GroupButton>
                    </div>
                  </div>
                </> : null}

                {this.props.user.team && this.props.user.team.subscription.status === Constants.PADDLE.STATES.DELETED ? <>
                  <div className="Subscription__Columns">
                    <div className="Subscription__Columns--Block">
                      <h4>Plan</h4>
                      <p className="Paragraph--Normal">${this.props.user.team.subscription.unit_price} {this.props.user.team.subscription.subscription_plan} <br /><span className="Font-Size--12 Tag--Danger">CANCELLED</span></p>
                    </div>
                    <div className="Subscription__Columns--Block">
                      <h4>Cancellation Date</h4>
                      <p className="Paragraph--Normal">{this.props.user.team.subscription.cancellation_effective_date}</p>
                    </div>
                  </div>

                  <p className="Paragraph--Normal Margin-Bottom--24"> {this.props.user.team_name} will be downgraded to FREE plan on <span className="Font-Color--Red">{this.props.user.team.subscription.cancellation_effective_date}</span>.</p>

                  <p className="Paragraph--Normal">Changed your mind? Don't worry, You can still upgrade to the PRO plan and keep using all features. During this cancellation time period another member from your workspace can also upgrade to the PRO plan.
                  <br /><br />*Following prices are in USD for the <b className="Underline">entire workspace</b>. Local taxes are not included.</p>

                  <div className="PricesContainer">
                    <div className="PriceBlock">
                      <h3 className="PriceBlock__Title">Monthly Plan</h3>
                      <p className="Paragraph--Small Text-Align--Center Color--Gray">Billed monthly</p>
                      <p className="PriceBlock__Price">{Constants.PADDLE.PRICES.MONTHLY}<span className="PriceBlock__Price--Label">/month</span></p>
                      <GroupButton classNames="Button--Primary" onClick={this.handleBuyMonthlyProPlan}
                        isDisabled={this.state.isMonthlyPlanActivating} isLoading={this.state.isMonthlyPlanActivating} text={"Buy Now"}></GroupButton>
                      <p className="PriceBlock__Cancel">Cancel anytime</p>
                    </div>
                    <div className="PriceBlock">
                      <h3 className="PriceBlock__Title">Yearly Plan</h3>
                      <p className="Paragraph--Small Text-Align--Center Color--Gray">Billed yearly</p>
                      <p className="PriceBlock__Price">{Constants.PADDLE.PRICES.YEARLY}<span className="PriceBlock__Price--Label">/month</span></p>
                      <GroupButton classNames="Button--Primary" onClick={this.handleBuyYearlyProPlan}
                        isDisabled={this.state.isYearlyPlanActivating} isLoading={this.state.isYearlyPlanActivating}
                        text={"Buy Now"}></GroupButton>
                      <p className="PriceBlock__Cancel">Cancel anytime</p>
                      <div className="PriceBlock__Profit">Save 20%</div>
                    </div>
                  </div>

                </> : null}

              </div>
            </div>
          </SlideIn>

          <SlideIn isShow={this.props.user && this.props.user.team && this.props.user.team.status === 3 &&
            this.props.user.team.subscription.created_by_user_id !== this.props.user.user_id}>
            <div className="Section Margin-Top--24 Flex-Direction--Column">
              <p className="Paragraph--Normal Text-Align--Center Margin-Bottom--24"><b>{this.props.user.team_name}</b> is currently on Timy <span className="ProBadge"><b>PRO</b></span> plan and it is managed by,</p>

              <section className='Center Vertical--Center Margin-Top--12 Subscription__Profile Flex-Direction--Column'>
                {this.state.proPlanAddedBy.label ? <div className='Vertical--Center'>
                  <div className="Subscription__Profile__Image__Wrapper">
                    <img alt='avatar' className='Profile__Image' src={this.state.proPlanAddedBy.image_192}></img>
                  </div>
                  <div className="Subscription__Profile_Details">
                    <span className="Subscription__Profile_Details--Name">{this.state.proPlanAddedBy.label}</span>

                    {this.props.user.team && this.props.user.team.subscription.status === Constants.PADDLE.STATES.ACTIVE ? <>
                      <p className="Paragraph--Normal Subscription__Profile_Details--Plan">${this.props.user.team.subscription.unit_price} {this.props.user.team.subscription.subscription_plan} <span className="Font-Size--12 Tag--Success">ACTIVE</span></p>
                    </> : null}

                    {this.props.user.team && this.props.user.team.subscription.status === Constants.PADDLE.STATES.PAST_DUE ? <>
                      <p className="Paragraph--Normal Subscription__Profile_Details--Plan">${this.props.user.team.subscription.unit_price} {this.props.user.team.subscription.subscription_plan} <span className="Font-Size--12 Tag--Warning">PAST DUE</span></p>
                    </> : null}

                    {this.props.user.team && this.props.user.team.subscription.status === Constants.PADDLE.STATES.DELETED ? <>
                      <p className="Paragraph--Normal Subscription__Profile_Details--Plan">${this.props.user.team.subscription.unit_price} {this.props.user.team.subscription.subscription_plan} <span className="Font-Size--12 Tag--Danger">Cancelled</span></p>
                    </> : null}

                  </div>
                </div> : <div className="LoadingSpinner"></div>}

                {this.props.user.team && this.props.user.team.subscription.status === Constants.PADDLE.STATES.DELETED ? <>
                  <p className="Paragraph--Normal Margin-Bottom--24 Margin-Top--24">It seems like {this.state.proPlanAddedBy.label} has cancelled Timy PRO Plan and {this.props.user.team_name} will be downgraded to FREE plan on <span className="Font-Color--Red">{this.props.user.team.subscription.cancellation_effective_date}</span>.</p>

                  <p className="Paragraph--Normal">Don't worry, You can upgrade <b>{this.props.user.team_name}</b> to the PRO plan again and keep using all features.
                  *Following prices are in USD for the <b className="Underline">entire workspace</b>. Local taxes are not included.</p>

                  <div className="PricesContainer">
                    <div className="PriceBlock">
                      <h3 className="PriceBlock__Title">Monthly Plan</h3>
                      <p className="Paragraph--Small Text-Align--Center Color--Gray">Billed monthly</p>
                      <p className="PriceBlock__Price">{Constants.PADDLE.PRICES.MONTHLY}<span className="PriceBlock__Price--Label">/month</span></p>
                      <GroupButton classNames="Button--Primary" onClick={this.handleBuyMonthlyProPlan}
                        isDisabled={this.state.isMonthlyPlanActivating} isLoading={this.state.isMonthlyPlanActivating} text={"Buy Now"}></GroupButton>
                      <p className="PriceBlock__Cancel">Cancel anytime</p>
                    </div>
                    <div className="PriceBlock">
                      <h3 className="PriceBlock__Title">Yearly Plan</h3>
                      <p className="Paragraph--Small Text-Align--Center Color--Gray">Billed yearly</p>
                      <p className="PriceBlock__Price">{Constants.PADDLE.PRICES.YEARLY}<span className="PriceBlock__Price--Label">/month</span></p>
                      <GroupButton classNames="Button--Primary" onClick={this.handleBuyYearlyProPlan}
                        isDisabled={this.state.isYearlyPlanActivating} isLoading={this.state.isYearlyPlanActivating}
                        text={"Buy Now"}></GroupButton>
                      <p className="PriceBlock__Cancel">Cancel anytime</p>
                      <div className="PriceBlock__Profit">Save 20%</div>
                    </div>
                  </div>

                </> : null}

              </section>

            </div>
          </SlideIn>

        </div>

        <SubscriptionCancelPopup></SubscriptionCancelPopup>

      </SlideIn>
    )
  }
}

export default withRouter(connect(mapStateToProps, { setActivePage, getUserData, getUserListData })(BillingPage));