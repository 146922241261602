import React, { Component } from 'react';
import GroupButton from './GroupButton';
import { PubSub } from 'pubsub-js';
import { connect } from 'react-redux';
import Utils from './../utils/Utils';
import Constants from './../utils/Constants';

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class TrialStarterPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            isTrialStarting: false,
            isTrialStarted: false,
            errorMessage: ''
        }
    }

    componentDidMount() {
        PubSub.subscribe('showTrialStarter', (msg, data) => {
            if (!data) {
                //This is called within the application itself
                this.setState({
                    isVisible: true,
                    isTrialStarting: true
                }, () => {
                    this.activateTrialPeriod();
                });
            } else {
                //Already activated and this was called from the ws via server
                this.setState({
                    isVisible: true,
                    isTrialStarting: false,
                    isTrialStarted: true
                });
            }
        });
    }

    activateTrialPeriod = () => {
        Utils.fetch({
            method: 'post',
            url: Constants.API.Trial_Activate
        }).then(response => {

            if (response.data.is_trial_started) {
                this.setState({
                    isTrialStarting: false,
                    isTrialStarted: true
                });
            } else {
                this.setState({
                    isTrialStarting: false,
                    isTrialStarted: false,
                    errorMessage: response.data.reason
                });
            }

        }).catch(error => {
            Utils.showToast('error', error.desc);
            this.setState({
                isVisible: false,
                isTrialStarting: false,
                isTrialStarted: false
            });
        });
    }

    handleOk = () => {
        window.location.reload();
    }

    render() {
        return (
            <div role="dialog" aria-labelledby="trial-start" tabIndex="-1" aria-describedby="trialStartPopup"
                className={"Popup__Overlay" + (this.state.isVisible ? ' Open' : '')}>
                <div role="document" className="Popup__Container">
                    <div className="Popup__Head">
                        <h3>Timy Trial Period</h3>
                    </div>
                    <div className="Popup__Body Modal">
                        {this.state.isTrialStarting ? <div className="Vertical--Center Center">
                            <div className="LoadingSpinner"></div>
                            <p className="Margin-Left--12"><b>Hold tight,</b> <br />Your 14 day trial period is starting...</p>
                        </div> : null}
                        {!this.state.isTrialStarted && this.state.errorMessage.length > 0 ? <div>
                            <p className="Margin-Left--12"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#ed143d"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2">
                                </polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
                                <br /><b>Oops!</b><br/>{this.state.errorMessage}</p>
                        </div> : null}
                        {this.state.isTrialStarted && this.props.user ? <div className="Vertical--Center Center Flex-Direction--Column">
                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24"
                                fill="none" stroke="#3be2a7" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14">
                                </path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                            <h3>That's Great!</h3>
                            <h2 className="Text-Align--Center Line-Height--24">A 14 day trial period of Timy <span className="ProBadge">PRO</span> plan has been activated for your workspace "{this.props.user.team_name}".</h2>
                            <p className="Paragraph--Normal">You can upgrade to PRO plan at any time. If you're not upgrading to the PRO plan, Timy will be automatically
                            downgraded to FREE plan at the end of your 14 day trial period.
                            With PRO plan you'll be able to,</p>
                            <ul className="Ticks">
                                <li>Send recurring messages (Every weekday, Every month...)</li>
                                <li>Send unlimited messages in any time zone</li>
                                <li>Increase scheduling time limit up to <b>one year</b></li>
                                <li>Save time zone mappings for each user or channel</li>
                                <li>Pause recurring messages if you are away</li>
                                <li>Attach files to scheduling messages via web app</li>
                                <li>Get Slackbot notifications for scheduled messages</li>
                                <li>Edit/Clone scheduled messages</li>
                                <li>Control individual Timy permissions (Admin only)</li>
                            </ul>
                            <h2>Tell your team mates and enjoy <span role="img" aria-label="enjoy">🎉</span></h2>

                        </div> : null}
                    </div>
                    <div className="Popup__Footer">
                        <div className="Popup__Footer__Actions">
                            <GroupButton
                                isDisabled={this.state.isTrialStarting}
                                onClick={this.handleOk}
                                classNames="Button--Primary"
                                text="OK" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(TrialStarterPopup);