class LocalStorage {
  static getItem(key) {
    try {
      let value = localStorage.getItem(key);
      return value === '' ? null : value;
    } catch (ex) {
      alert('This tab/window does not support local storage browser functionality. Please use a different tab/window.');
    }
  }

  static setItem(key, data, isJson) {
    try {
      if (isJson) {
        try {
          data = JSON.stringify(data);
        } catch (ex) {
          console.error("ERROR", ex);
        }
      }
      localStorage.setItem(key, data);
    } catch (ex) {
      alert('This tab/window does not support local storage browser functionality. Please use a different tab/window.');
    }
  }

  static removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (ex) {
      alert('This tab/window does not support local storage browser functionality. Please use a different tab/window.');
    }
  }
}

export default LocalStorage;