import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { isArray } from 'util';
import { connect } from 'react-redux';
import './../plugins/MentionBlot.js';
import './../plugins/EmojiBlot.js';
import Constants from './../utils/Constants';
import Emojis from './../utils/Emojis';
import Utils from './../utils/Utils';
import { PubSub } from 'pubsub-js';
import SlideIn from './SlideIn';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    message_popup: state.message_popup,
    team_users: state.team_users,
    team_channels: state.team_channels,
    team_groups: state.team_channels,
    channel_ids: state.channel_ids,
    user_ids: state.user_ids
  }
}


class TextEditor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      text: '',
      chosenUsers: [],
      isChosenUsersShown: false,
      userSearchText: '',
      userIterator: 0,

      chosenChannels: [],
      isChosenChannelsShown: false,
      channelSearchText: '',
      channelIterator: 0,

      chosenEmojis: [],
      isChosenEmojisShown: false,
      emojiSearchText: '',
      emojiIterator: 0,

      isFileBrowserShown: false,
      isFileAdded: false,
      fileState: '',
      fileProgress: 0,
      fileData: '',

      isMessageUpdate: this.props.message_popup.is_update
    }

    this.channelMentions = ['here', 'channel', 'everyone'];
    this.fileInput = React.createRef();
    this.fileXHR = new XMLHttpRequest();
    this.registerFileXHREvents();

    //Convert p tags to div
    let Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);

  }

  registerFileXHREvents = () => {

    this.fileXHR.upload.addEventListener("progress", (e) => {
      this.setState({
        fileProgress: parseInt((e.loaded / e.total) * 100),
        fileState: 'inProgress'
      });
    }, false);

    this.fileXHR.addEventListener("load", (e) => {
      const { file, ok, error } = JSON.parse(e.target.responseText);

      if (ok) {
        this.setState({
          fileProgress: 100,
          fileState: 'completed',
          isMessageUpdate: false,
          fileData: {
            url: file.permalink,
            id: file.id,
            name: file.name,
            type: file.pretty_type,
            size: Utils.getHumanReadableFileSize(file.size, true),
            mimetype: file.mimetype
          }
        }, () => {
          this.props.onFileUploaded(false, this.state.fileData);
        });
      } else {
        this.props.onFileUploaded(false);
        if (error === "missing_scope") {
          this.setState({
            fileProgress: 0,
            fileState: 'error_missing_scope'
          });
        } else {
          this.setState({
            fileProgress: 0,
            isFileAdded: false,
            fileState: 'error'
          });
          Utils.showToast('error', Constants.SLACK_CODES[error]);
        }
      }

    }, false);

    this.fileXHR.addEventListener("error", (e) => {
      this.setState({
        fileProgress: 0,
        isFileAdded: false,
        fileState: 'error'
      });
      Utils.showToast('error', Constants.ERROR_CODES.UNKNOWN_ERROR);
      this.props.onFileUploaded(false);

    }, false);

    this.fileXHR.addEventListener("abort", (e) => {
      this.setState({
        fileProgress: 0,
        isFileAdded: false,
        fileState: ''
      });
      this.props.onFileUploaded(false);
    }, false);

  }

  componentDidMount() {
    this.focusTextEditSubscriber = PubSub.subscribe('focusTextEdit', () => {
      if (this.refs.reactQuill) {
        let $editor = this.refs.reactQuill.getEditor();
        $editor.focus();

        //Accept only plain text when pasted
        //This is to avoid unwanted behaviour on pasted links
        $editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
          const plaintext = node.innerText;
          const Delta = Quill.import('delta');
          if (!this.isOpeningAtMentions) {
            if (plaintext !== "\n") {
              return new Delta().insert(plaintext + "\n");
            } else {
              return new Delta().insert(plaintext);
            }
          } else {
            return delta;
          }
        });

      }
    });

    this.restoreEditorContentSubscriber = PubSub.subscribe('restoreEditorContent', (event, data) => {
      if (data.to === "MessagePopup") {
        let $editor = this.refs.reactQuill.getEditor();
        $editor.setContents(data.editorContents);
      }
    });

    this.setFileDataSubscriber = PubSub.subscribe("setFileData", (event, data) => {
      if (data.from === "MessagePopup" && data.file_data) {
        this.setState({
          fileProgress: 100,
          fileState: 'completed',
          isFileAdded: true,
          fileData: data.file_data
        }, () => {
          this.props.onFileUploaded(false, this.state.fileData);
        });
      }
    });

    this.updatePlaceholder();
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.focusTextEditSubscriber);
    PubSub.unsubscribe(this.restoreEditorContentSubscriber);
    PubSub.unsubscribe(this.setFileDataSubscriber);
  }

  componentDidUpdate() {
    this.updatePlaceholder();
  }

  updatePlaceholder() {
    let $editor = this.refs.reactQuill.getEditor();
    if (this.props.userType === "user") {
      $editor.root.dataset.placeholder = "Message " + (this.props.selectedUser ? this.props.selectedUser.label : "");
      if (this.props.user.user_id === this.props.selectedUser.value) {
        $editor.root.dataset.placeholder = "Jot something down";
      }
    } else if (this.props.userType === "channel" && this.props.selectedChannel.label && !this.props.selectedChannel.is_mpim) {
      $editor.root.dataset.placeholder = "Message #" + this.props.selectedChannel.label;
    } else if (this.props.userType === "channel" && this.props.selectedChannel.is_mpim) {
      $editor.root.dataset.placeholder = "Message Group";
    } else {
      $editor.root.dataset.placeholder = "Your Message";
    }
  }

  handleChange = (content, delta, source, _editor) => {

    this.setState({ text: content }, () => {

      const editor = this.refs.reactQuill.getEditor();
      var keyboard = editor.keyboard.bindings;

      //Remove enter key binding from the quill editor
      if (keyboard[13]) {
        delete keyboard[13];
      }

      //Remove tab bindings
      if (keyboard[9]) {
        delete keyboard[9];
      }

      const unprivilegedEditor = this.refs.reactQuill.makeUnprivilegedEditor(editor);

      if (source === 'user') {

        const range = unprivilegedEditor.getSelection();
        if (range == null) {
          this.automaticInputsReplacer();
          return;
        }

        this.cursorPos = range.index;
        const startPos = Math.max(0, this.cursorPos - 31);
        const beforeCursorPos = unprivilegedEditor.getText(startPos, this.cursorPos - startPos);

        let inputType = "user";

        const mentionCharIndex = ['@', '#', ':'].reduce((prev, cur) => {
          const previousIndex = prev;
          const mentionIndex = beforeCursorPos.lastIndexOf(cur);
          return mentionIndex > previousIndex ? mentionIndex : previousIndex;
        }, -1);

        if (mentionCharIndex > -1) {

          const mentionCharPos = this.cursorPos - (beforeCursorPos.length - mentionCharIndex);
          this.mentionCharPos = mentionCharPos;

          const textAfter = beforeCursorPos.substring(mentionCharIndex + 1);

          // Indentify the input type
          switch (beforeCursorPos[mentionCharIndex]) {
            case '@': {
              inputType = "user";
              break;
            }
            case '#': {
              inputType = "channel";
              break;
            }
            case ':': {
              inputType = "emoji";
              break;
            }
            default: {
            }
          }

          if (textAfter.length >= 0 && /^[a-zA-Z0-9_]*$/.test(textAfter)) {

            if (inputType === 'user') {
              let chosenUsers = [];
              let team_users = [...this.props.team_users];

              //We can add @here and @channel mentions
              if (this.props.userType === 'channel') {

                team_users.push({
                  image_192: Constants.NOTIFY_ICON,
                  display_name: 'here',
                  label: 'Notify channel (online)',
                  is_bot: false,
                  value: '<!here>'
                });

                team_users.push({
                  image_192: Constants.NOTIFY_ICON,
                  display_name: 'channel',
                  label: 'Notify channel',
                  is_bot: false,
                  value: '<!channel>'
                });

                //IF the channel is #general we can add @everyone mention
                if (this.props.channelName === 'general') {
                  team_users.push({
                    image_192: Constants.NOTIFY_ICON,
                    display_name: 'everyone',
                    label: 'Notify workspace',
                    is_bot: false,
                    value: '<!everyone>'
                  })
                }
              }

              team_users.forEach(user => {

                let name = "";
                if (this.channelMentions.indexOf(user.display_name) !== -1) {
                  name = user.display_name;
                } else {
                  name = user.display_name + '   ' + user.label;
                }

                if (name.toLowerCase().indexOf(textAfter.toLowerCase()) !== -1) {
                  chosenUsers.push({
                    isSelected: chosenUsers.length === 0,
                    avatar: user.image_192,
                    label: user.label,
                    display_name: user.display_name,
                    is_bot: user.is_bot,
                    plain_id: user.value,
                    id: (this.channelMentions.indexOf(user.display_name) !== -1) ? user.value : `<@${user.value}>`
                  });
                }
              });

              this.setState({
                chosenUsers,
                isChosenUsersShown: true,
                userSearchText: textAfter,
                userIterator: 0
              });
            }

            if (inputType === 'channel') {
              let chosenChannels = [];

              this.props.team_channels.forEach(channel => {
                const name = channel.label;
                if (name.toLowerCase().indexOf(textAfter.toLowerCase()) !== -1 && channel.is_private === false) {
                  chosenChannels.push({
                    isSelected: chosenChannels.length === 0,
                    label: channel.label,
                    id: `<#${channel.value}|${channel.label}>`
                  });
                }
              });

              this.setState({
                chosenChannels,
                isChosenChannelsShown: true,
                channelSearchText: textAfter,
                channelIterator: 0
              });
            }

            if (inputType === 'emoji' && textAfter.length >= 2) {
              let chosenEmojis = [];

              Emojis.forEach(emoji => {
                if (emoji.toLowerCase().indexOf(textAfter.toLowerCase()) !== -1) {
                  chosenEmojis.push({
                    isSelected: chosenEmojis.length === 0,
                    emoji,
                    id: `:${emoji}:`,
                    emoji_id: `:${emoji}:`
                  });
                }
              });

              this.setState({
                chosenEmojis,
                isChosenEmojisShown: true,
                emojiSearchText: textAfter,
                emojiIterator: 0
              });


            }

          } else {
            this.hideAllWindows();
          }
        } else {
          this.hideAllWindows();
        }
      }

      this.automaticInputsReplacer();

    });

  }

  hideAllWindows() {
    this.setState({
      isChosenUsersShown: false,
      userIterator: 0,
      isChosenEmojisShown: false,
      emojiIterator: 0,
      isChosenChannelsShown: false,
      isFileBrowserShown: false,
      channelIterator: 0
    });
  }

  insertUser = (index) => {
    this.setSelectedItem('chosenUsers', index, this.state.chosenUsers);
    this.replaceUserName();
  }

  insertChannel = (index) => {
    this.setSelectedItem('chosenChannels', index, this.state.chosenChannels);
    this.replaceChannelName();
  }

  insertEmoji = (index) => {
    this.setSelectedItem('chosenEmojis', index, this.state.chosenEmojis);
    this.replaceEmojiName();
  }

  automaticInputsReplacer() {
    const editor = this.refs.reactQuill.getEditor();
    const unprivilegedEditor = this.refs.reactQuill.makeUnprivilegedEditor(editor);
    const range = unprivilegedEditor.getSelection();
    if (range == null) {
      this.props.onChange(this.getExportHtml());
      return;
    } else {
      const cursorPos = range.index;
      const startPos = Math.max(0, cursorPos - 31);
      const beforeCursorPos = unprivilegedEditor.getText(startPos, cursorPos - startPos);

      let emojis = Emojis.map(emoji => ` :${emoji}: `);
      emojis = emojis.concat(Constants.EMOTIONS_ARRAY);

      const mentionCharIndex = emojis.reduce((prev, cur) => {
        const previousIndex = prev;
        const mentionIndex = beforeCursorPos.lastIndexOf(cur);
        return mentionIndex > previousIndex ? mentionIndex : previousIndex;
      }, -1);

      if (mentionCharIndex > -1) {

        const mentionCharPos = cursorPos - (beforeCursorPos.length - mentionCharIndex);
        let emojiText = unprivilegedEditor.getText(mentionCharPos, cursorPos - mentionCharPos);
        if (Constants.EMOTIONS[emojiText]) {
          emojiText = Constants.EMOTIONS[emojiText];
        }

        setTimeout(() => {
          editor.deleteText(mentionCharPos, cursorPos - mentionCharPos, Quill.sources.API);
          editor.insertText(mentionCharPos, ' ', Quill.sources.API);
          editor.insertEmbed(mentionCharPos + 1, 'emoji', {
            name: Utils.replaceAll(emojiText.trim(), ':', ''),
            id: emojiText.trim()
          }, Quill.sources.API);
          editor.insertText(mentionCharPos + 2, ' ', Quill.sources.API);
          editor.setSelection(mentionCharPos + 3, Quill.sources.API);
        }, 50);

      }

      // Allow time to update the custom input objects
      this.props.onChange(this.getExportHtml());

    }

  }

  getExportHtml() {
    return Utils.recursiveDomParser(document.getElementsByClassName("ql-editor")[0].childNodes);
  }

  replaceUserName() {
    let user = this.getSelectedUser();
    const editor = this.refs.reactQuill.getEditor();

    setTimeout(() => {
      editor.deleteText(this.mentionCharPos, this.cursorPos - this.mentionCharPos, Quill.sources.API);
      editor.insertEmbed(this.mentionCharPos, 'mention', {
        name: '@' + user.display_name,
        id: user.id
      }, Quill.sources.API);
      editor.insertText(this.mentionCharPos + 1, ' ', Quill.sources.API);
      editor.setSelection(this.mentionCharPos + 2, Quill.sources.API);
      this.automaticInputsReplacer();
    }, 50);

    this.hideAllWindows();
  }

  replaceEmojiName() {
    let emoji = this.getSelectedEmoji();
    const editor = this.refs.reactQuill.getEditor();

    setTimeout(() => {
      editor.deleteText(this.mentionCharPos, this.cursorPos - this.mentionCharPos, Quill.sources.API);
      editor.insertEmbed(this.mentionCharPos, 'emoji', {
        name: emoji.emoji,
        id: emoji.emoji_id
      }, Quill.sources.API);
      editor.insertText(this.mentionCharPos + 1, ' ', Quill.sources.API);
      editor.setSelection(this.mentionCharPos + 2, Quill.sources.API);
      this.automaticInputsReplacer();
    }, 50);

    this.hideAllWindows();
  }

  replaceChannelName() {
    let channel = this.getSelectedChannel();
    const editor = this.refs.reactQuill.getEditor();

    setTimeout(() => {
      editor.deleteText(this.mentionCharPos, this.cursorPos - this.mentionCharPos, Quill.sources.API);
      editor.insertEmbed(this.mentionCharPos, 'mention', {
        name: '#' + channel.label,
        id: channel.id
      }, Quill.sources.API);
      editor.insertText(this.mentionCharPos + 1, ' ', Quill.sources.API);
      editor.setSelection(this.mentionCharPos + 2, Quill.sources.API);
      this.automaticInputsReplacer();
    }, 50);

    this.hideAllWindows();
  }

  setSelectedItem(key, index, array) {
    array = array.map((item, i) => {
      if (index === i) {
        item.isSelected = true;
        document.getElementById(item.id).scrollIntoView(false);
      } else {
        item.isSelected = false;
      }
      return item;
    });
    this.setState({
      [key]: array
    });
  }

  getSelectedUser() {
    let selectedUsers = this.state.chosenUsers.filter(user => {
      return user.isSelected ? user : null;
    });
    if (isArray(selectedUsers) && selectedUsers.length > 0) {
      return selectedUsers[0];
    } else {
      return false;
    }
  }

  getSelectedEmoji() {
    let selectedEmojis = this.state.chosenEmojis.filter(emoji => {
      return emoji.isSelected ? emoji : null;
    });
    if (isArray(selectedEmojis) && selectedEmojis.length > 0) {
      return selectedEmojis[0];
    } else {
      return false;
    }
  }

  getSelectedChannel() {
    let selectedChannels = this.state.chosenChannels.filter(channel => {
      return channel.isSelected ? channel : null;
    });
    if (isArray(selectedChannels) && selectedChannels.length > 0) {
      return selectedChannels[0];
    } else {
      return false;
    }
  }

  handleOpenAtMentions = (e) => {
    e.preventDefault();
    const editor = this.refs.reactQuill.getEditor();
    const unprivilegedEditor = this.refs.reactQuill.makeUnprivilegedEditor(editor);
    let range = unprivilegedEditor.getSelection();

    if (range == null) {
      editor.focus();
      range = unprivilegedEditor.getSelection();
    }

    const cursorPos = range.index;
    this.isOpeningAtMentions = true;
    editor.insertText(cursorPos, '@', 'user');
    editor.setSelection(cursorPos + 1, 'user');
    setTimeout(() => {
      this.isOpeningAtMentions = false;
    }, 150);

  }

  handleOpenFiles = (e) => {
    e.preventDefault();
    const editor = this.refs.reactQuill.getEditor();
    editor.focus();
    this.hideAllWindows();
    this.setState({
      isFileBrowserShown: !this.state.isFileBrowserShown
    });
  }

  handleAddFileFromComputer = (e) => {

  }

  handleFileSelectedFromComputer = (e) => {
    this.hideAllWindows();

    this.setState({
      isFileAdded: true,
      fileState: 'inProgress'
    });

    this.props.onFileUploaded(true);

    this.fileXHR.open("POST", `${Constants.API.SLACK.file.upload}?token=${this.props.user.token}`);
    const formdata = new FormData();
    formdata.append("file", this.fileInput.current.files[0]);
    this.fileXHR.send(formdata);

  }

  handleFileCancel = (e) => {
    this.fileXHR.abort();
  }

  handleFileDelete = () => {

    if (this.state.isMessageUpdate) {
      PubSub.publish('showConfirm', {
        message: "Are you sure you want to delete this file ? <br/>This won't delete the actual file from your Slack workspace.",
        onYes: () => {
          this.setState({
            fileState: 'deleting'
          });
          this.props.onFileUploaded(true);
          this.setState({
            fileData: {},
            isFileAdded: false,
            fileState: '',
            fileProgress: 0,
          });
          this.props.onFileUploaded(false);
        }
      });
    } else {
      PubSub.publish('showConfirm', {
        message: "Are you sure you want to delete this file ? <br/>This will delete the actual file from your Slack workspace.",
        onYes: () => {
          this.setState({
            fileState: 'deleting'
          });
          this.props.onFileUploaded(true);
          Utils.fetch({
            method: 'post',
            url: `${Constants.API.SLACK.file.delete}?token=${this.props.user.token}&file=${this.state.fileData.id}`
          }, true).then(response => {
            this.setState({
              fileData: {},
              isFileAdded: false,
              fileState: '',
              fileProgress: 0,
            });
            this.props.onFileUploaded(false);
          }).catch(error => {
            if (error.desc === "file_deleted" || error.desc === "file_not_found") {
              this.setState({
                fileData: {},
                isFileAdded: false,
                fileState: '',
                fileProgress: 0,
              });
              this.props.onFileUploaded(false);
            } else {
              Utils.showToast('error', Constants.SLACK_CODES[error.desc] ? Constants.SLACK_CODES[error.desc] : Constants.ERROR_CODES['UNKNOWN_ERROR']);
              this.setState({
                fileState: 'completed'
              });
              this.props.onFileUploaded(false);
            }
          });
        }
      });
    }
  }

  handleUpKeyForUsers() {
    if (this.state.isChosenUsersShown) {
      let nextUserIterator = 0;
      if (this.state.userIterator - 1 < 0) {
        nextUserIterator = this.state.chosenUsers.length - 1;
      } else {
        nextUserIterator = this.state.userIterator - 1;
      }
      this.setState({
        userIterator: nextUserIterator
      });
      this.setSelectedItem('chosenUsers', nextUserIterator, this.state.chosenUsers);
    }
  }

  handleUpKeyForEmojis() {
    if (this.state.isChosenEmojisShown) {
      let nextEmojiIterator = 0;
      if (this.state.emojiIterator - 1 < 0) {
        nextEmojiIterator = this.state.chosenEmojis.length - 1;
      } else {
        nextEmojiIterator = this.state.emojiIterator - 1;
      }
      this.setState({
        emojiIterator: nextEmojiIterator
      });
      this.setSelectedItem('chosenEmojis', nextEmojiIterator, this.state.chosenEmojis);
    }
  }

  handleUpKeyForChannels() {
    if (this.state.isChosenChannelsShown) {
      let nextChannelIterator = 0;
      if (this.state.channelIterator - 1 < 0) {
        nextChannelIterator = this.state.chosenChannels.length - 1;
      } else {
        nextChannelIterator = this.state.channelIterator - 1;
      }
      this.setState({
        channelIterator: nextChannelIterator
      });
      this.setSelectedItem('chosenChannels', nextChannelIterator, this.state.chosenChannels);
    }
  }

  handleDownKeyForUsers() {
    if (this.state.isChosenUsersShown) {
      const nextUserIterator = Math.abs((this.state.userIterator + 1) % this.state.chosenUsers.length);
      this.setState({
        userIterator: nextUserIterator
      });
      this.setSelectedItem('chosenUsers', nextUserIterator, this.state.chosenUsers);
    }
  }

  handleDownKeyForEmojis() {
    if (this.state.isChosenEmojisShown) {
      const nextEmojiIterator = Math.abs((this.state.emojiIterator + 1) % this.state.chosenEmojis.length);
      this.setState({
        emojiIterator: nextEmojiIterator
      });
      this.setSelectedItem('chosenEmojis', nextEmojiIterator, this.state.chosenEmojis);
    }
  }

  handleDownKeyForChannels() {
    if (this.state.isChosenChannelsShown) {
      const nextChannelIterator = Math.abs((this.state.channelIterator + 1) % this.state.chosenChannels.length);
      this.setState({
        channelIterator: nextChannelIterator
      });
      this.setSelectedItem('chosenChannels', nextChannelIterator, this.state.chosenChannels);
    }
  }


  handleKeyDownForUsers(e) {
    if (this.state.isChosenUsersShown && this.state.chosenUsers.length > 0) {
      e.preventDefault();
      if (e.which === 13) {
        //Enter key
        this.replaceUserName();
      }
    }
  }

  handleKeyDownForChannels(e) {
    if (this.state.isChosenChannelsShown && this.state.chosenChannels.length > 0) {
      e.preventDefault();
      if (e.which === 13) {
        //Enter key
        this.replaceChannelName();
      }
    }
  }

  handleKeyDownForEmojis(e) {
    if (this.state.isChosenEmojisShown && this.state.chosenEmojis.length > 0) {
      e.preventDefault();
      if (e.which === 13) {
        //Enter key
        this.replaceEmojiName();
      }
    }
  }

  handleKeyUp = (e) => {
    const hidingKeyCodes = [39, 37, 27];
    if (hidingKeyCodes.indexOf(e.which) !== -1) {
      this.hideAllWindows();
    } else if (e.which === 38) {
      //Up key            
      this.handleUpKeyForUsers(e);
      this.handleUpKeyForChannels(e);
      this.handleUpKeyForEmojis(e);

    } else if (e.which === 40) {
      //Down key
      this.handleDownKeyForUsers(e);
      this.handleDownKeyForChannels(e);
      this.handleDownKeyForEmojis(e);
    } else if (e.which === 9) {
      //Down key     
      this.handleDownKeyForUsers(e);
      this.handleDownKeyForChannels(e);
      this.handleDownKeyForEmojis(e);
    }
  }

  handleKeyDown = (e) => {
    if (e.which === 38) {
      //Up key
      this.handleKeyDownForUsers(e);
      this.handleKeyDownForChannels(e);
      this.handleKeyDownForEmojis(e);
    } else if (e.which === 40) {
      //Down key
      this.handleKeyDownForUsers(e);
      this.handleKeyDownForChannels(e);
      this.handleKeyDownForEmojis(e);
    } else if (e.which === 13) {
      //Enter key
      this.handleKeyDownForUsers(e);
      this.handleKeyDownForChannels(e);
      this.handleKeyDownForEmojis(e);
    } else if (e.which === 9) {
      //Enter key
      this.handleKeyDownForUsers(e);
      this.handleKeyDownForChannels(e);
      this.handleKeyDownForEmojis(e);
    }
  }

  handleOnBlur = () => {
    this.props.onBlur();
    setTimeout(() => {
      this.hideAllWindows();
    }, 0);
  }

  handleOnFocus = () => {
    this.props.onFocus();
  }

  handleGrantFilePermission = (e) => {
    e.preventDefault();
    this.props.onGrantFilePermission(this.refs.reactQuill.getEditor().getContents());
  }

  render() {
    return (

      <div className="MessageComposerParent">

        <SlideIn isShow={this.state.isFileAdded && this.props.messageType === 'pro'}
          transitionName="SlideIn"
          transitionAppear={true}
          className="Item When"
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}>

          <div className={"FileBlock" + (this.state.fileState === "error_missing_scope" ? " FileBlock--Error" : "")}>

            {this.state.fileState === "inProgress" ?
              <div className="MessageProgressBlock">
                <div className="MessageProgressBlock__Progress">
                  {this.state.fileProgress}%
                </div>
                <div className="MessageProgressBlock__Desc">
                  <p className="MessageProgressBlock__Desc--Title">{this.state.fileProgress === 100 ? 'Processing file...' : 'Uploading file...'}</p>
                  <div className="MessageProgressBar">
                    <span className={"MessageBar"}>
                      <span style={{ width: this.state.fileProgress + '%' }} className={"MessageProgress " + (this.state.fileProgress === 100 ? 'Infinite' : '')}></span>
                    </span>
                  </div>
                </div>
                <svg title="Cancel" onClick={this.handleFileCancel} className="MessageProgressBlock__Close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </div>
              : null}

            {this.state.fileState === "deleting" ?
              <div className="MessageProgressBlock">
                <div className="MessageProgressBlock__Progress">
                </div>
                <div className="MessageProgressBlock__Desc">
                  <p className="MessageProgressBlock__Desc--Title">Deleting file...</p>
                  <div className="MessageProgressBar">
                    <span className={"MessageBar"}>
                      <span className="MessageProgress Infinite"></span>
                    </span>
                  </div>
                </div>
              </div>
              : null}

            {this.state.fileState === "error_missing_scope" ?
              <div className="MessageProgressBlock MessageProgressBlock--Error">
                <div className="MessageProgressBlock__Progress">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#f52323"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                    </path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>
                </div>
                <div className="MessageProgressBlock__Desc">
                  <p className="MessageProgressBlock__Desc--Title">Hmm...</p>
                  <p className="MessageProgressBlock__Desc--Message">Slack™ is asking you to grant additional file permissions.</p>
                  <button target="_self" className="Button Group__Button Selected Margin-Bottom--8"
                    onClick={this.handleGrantFilePermission}>
                    Grant Permission
                  </button>
                </div>
              </div>
              : null}

            {this.state.fileState === "completed" ?
              <div className="MessageCompletedBlock">
                <svg className="MessageCompletedBlock__Icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
                  stroke="#5a44ff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M13 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V9l-7-7z" /><path d="M13 3v6h6" />
                </svg>
                <div title={this.state.fileData.name} className="MessageCompletedBlock__Desc">
                  <p className="MessageCompletedBlock__Desc--Title">{this.state.fileData.name}</p>
                  <p className="MessageCompletedBlock__Desc--SubTitle">{this.state.fileData.size} {this.state.fileData.type}</p>
                </div>
                <svg title="Cancel" onClick={this.handleFileDelete} className="MessageCompletedBlock__Close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </div>
              : null}

          </div>
        </SlideIn>

        <div className={"MessageComposer " + (this.props.isFileSupported && this.props.messageType === 'pro' ? 'IsFilesSupported' : '')}>

          <SlideIn isShow={this.props.isFileSupported && this.props.messageType === 'pro'}>
            <div title="Attach a file" className="MessageComposer__Files">
              <button disabled={this.state.fileState === "inProgress"} onClick={this.handleOpenFiles} className="Button--Clear">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                  stroke="#929292" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round">
                  <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
                  </path></svg>
              </button>
            </div>
          </SlideIn>

          <ReactQuill value={this.state.text}
            onKeyUp={this.handleKeyUp}
            onKeyDown={this.handleKeyDown}
            ref="reactQuill"
            id="messageEditor"
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onChange={this.handleChange} />


          <div title="Mention someone" className="MessageComposer__At">
            <button onClick={this.handleOpenAtMentions} className="Button--Clear">
              @
            </button>
          </div>

          {this.state.isFileBrowserShown ?
            <div className="FileSelect">
              <div className="FileListDescription">
                Add a file from...
              </div>
              <ul className="FileList">
                <li onClick={this.handleAddFileFromComputer} className={"FileItem "}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                    viewBox="0 0 24 24" fill="none" stroke="#444" strokeWidth="2"
                    strokeLinecap="round" strokeLinejoin="round"><rect x="2" y="3"
                      width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line>
                    <line x1="12" y1="17" x2="12" y2="21"></line>
                  </svg>
                  Your Device
                  <input onChange={this.handleFileSelectedFromComputer} type="file" title="" ref={this.fileInput} />
                </li>
              </ul>
              <div className="Notice">
                Files are directly uploaded to your Slack workspace as Private and will share with
                the target recipient at the scheduled time. Timy doesn't manipulate or store any of these files.
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#ffd400"
                  stroke="#4c4c4c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="8"></line>
                </svg>
              </div>
            </div> : null}

          {this.state.isChosenUsersShown && this.state.chosenUsers.length > 0 ?
            <div className="UserSelect">
              <div className="UserListDescription">
                People matching @{this.state.userSearchText}
              </div>
              <ul className="UserList">
                {this.state.chosenUsers.map((user, index) => {
                  return <li onClick={this.insertUser.bind(this, index)} key={index} id={user.id} className={"UserItem " + (user.isSelected ? "Selected" : "")}>
                    <img alt="avatar" src={user.avatar} />
                    {this.channelMentions.indexOf(user.display_name) !== -1 ? <span>@</span> : null}
                    {user.display_name}
                    {user.is_bot ? <span className="AppTag">APP</span> : <span className="SecondaryLabel">{user.label}</span>}
                    {this.props.user.user_id === user.plain_id ? <span className="SecondaryLabel">(You)</span> : null}
                  </li>
                })}
              </ul>
            </div> : null}

          {this.state.isChosenChannelsShown && this.state.chosenChannels.length > 0 ?
            <div className="UserSelect">
              <div className="UserListDescription">
                Public channels matching #{this.state.channelSearchText}
              </div>
              <ul className="UserList">
                {this.state.chosenChannels.map((channel, index) => {
                  return <li onClick={this.insertChannel.bind(this, index)} id={channel.id} key={index} className={"UserItem " + (channel.isSelected ? "Selected" : "")}>
                    #{channel.label}
                  </li>;
                })}
              </ul>
            </div> : null}

          {this.state.isChosenEmojisShown && this.state.chosenEmojis.length > 0 ?
            <div className="UserSelect">
              <div className="UserListDescription">
                Emojis matching :{this.state.emojiSearchText}
              </div>
              <ul className="UserList">
                {this.state.chosenEmojis.map((emoji, index) => {
                  return <li onClick={this.insertEmoji.bind(this, index)} id={emoji.emoji_id} key={index} className={"UserItem " + (emoji.isSelected ? "Selected" : "")}>
                    <i className={"em em-" + emoji.emoji}></i> <span className="MainItem">&nbsp; {emoji.emoji_id}</span>
                  </li>;
                })}
              </ul>
            </div> : null}

        </div>

        <div onClick={this.handleOpenAtMentions} className="MessageComposer__Help">
          <strong>*bold*</strong> <i>_italics_</i> ~strike~ <code className="Block">`code`</code> <code className="Block">```preformatted```</code> &gt;quote
        </div>

      </div>

    );
  }
}

export default connect(mapStateToProps)(TextEditor);