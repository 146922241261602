import React from 'react';
import { withRouter } from 'react-router-dom';
import SlideIn from './../../components/SlideIn';
import GroupButton from './../../components/GroupButton';
import { setActivePage } from './../../actions/index';
import { connect } from 'react-redux';

class NotFoundPage extends React.Component {

  componentDidMount(){
    this.props.setActivePage('/404');
  }

  handleEscape = () => {
    this.props.history.push('/messages');
  }

  render() {
    return (
      <SlideIn isShow={true} className="Display--Block">

        <div className="NotFound">
          <div className="Hero"></div>
          <h1 className="Heading">Page Not Found.</h1>
          <p className="Text">Timy couldn't find the page your are looking for.</p>
          <div className="Form">
            <div className="Row Center Submit">
              <GroupButton
                onClick={this.handleEscape}
                classNames="Button--Primary"
                text="Escape Me" />
            </div>

          </div>
        </div>

      </SlideIn>
    )
  }
}

export default withRouter(connect(null, { setActivePage })(NotFoundPage));
