import React, { Component } from 'react';
import moment from 'moment';
import TextLoop from "react-text-loop";
import { PubSub } from 'pubsub-js';

class DateTime extends Component {

  constructor(props) {
    super(props);
    this.UPDATE_INTERVAL = 60000;
    this.state = {
      timeTo: this.getTimeTo(this.props.timestamp)
    }
  }

  getTimeTo = (timestamp) => {
    const futureDate = moment.utc(timestamp, "X");
    const today = new moment().utc();
    return `In about ${today.to(futureDate, true)}`;
  }

  componentDidMount() {
    this.timerHandler = setInterval(() => {
      this.setState({
        timeTo: this.getTimeTo(this.props.timestamp)
      });
    }, this.UPDATE_INTERVAL);

    this.updateMessageTimeSubscriber = PubSub.subscribe('updateMessageTime', (event, data) => {
      if (data.id === this.props.id) {
        this.setState({
          timeTo: this.getTimeTo(this.props.timestamp)
        });
      }
    });

  }

  componentWillUnmount() {
    clearInterval(this.timerHandler);
    PubSub.unsubscribe(this.updateMessageTimeSubscriber);
  }

  render() {
    return (
      <span className="DateTime__Container">
        {this.props.timestamp && this.props.timestamp > 0 ? <TextLoop interval={6000}>
          <p>{this.props.displayDate}</p>
          <p>{this.state.timeTo}</p>
        </TextLoop> : <p>{this.props.displayDate}</p>}
      </span>
    );
  }
}

export default DateTime;