const API_PREFIX = '/api/v2',
  iS_LOCALHOST = window.location.hostname === 'localhost',
  API_DOMAIN = window.location.hostname === 'localhost' ? 'http://localhost:9000' : '',
  SOCKET = window.location.hostname === 'localhost' ? 'http://localhost:9001' : '',
  CLIENT_ID = window.location.hostname !== 'slack.timy.website' ? '14718800978.858943149747' : '14718800978.439698020501';

const getPath = (path) => {
  return API_DOMAIN + API_PREFIX + path;
}

const ERROR_CODES = {
  'ACCESS_DENIED': 'ACCESS_DENIED',
  'TOKEN_EXPIRED': 'TOKEN_EXPIRED',
  'INVALID_SLACK_TOKEN': 'INVALID_SLACK_TOKEN',
  'SERVER_ERROR': 'Oops! Something went wrong. Please try again in a moment.',
  'NETWORK_ERROR': 'Oops! Please make sure you are connected to internet.',
  'INVALID_PARAMS': 'INVALID_PARAMS',
  'INVALID_STATE': 'INVALID_STATE',
  'INVALID_CODE_LOGIN': 'INVALID_CODE_LOGIN',
  'INVALID_CODE_ADD_TO_SLACK': 'INVALID_CODE_ADD_TO_SLACK',
  'MISSING_SCOPE': 'MISSING_SCOPE',
  'MISSING_SLACK_TOKEN': 'MISSING_SLACK_TOKEN',
  'SLACK_ERROR': 'SLACK_ERROR',
  'UNKNOWN_ERROR': 'An error has been occurred. Please try again in a moment. Sorry for the inconvenience. '
};

const SLACK_CODES = {
  'posting_to_general_channel_denied': 'An admin has restricted posting to the #general channel.',
  'invalid_channel': 'One or more channels supplied are invalid.',
  'not_authed': 'No authentication token provided.',
  'invalid_auth': 'Provided token is invalid or the request originates from an IP address disallowed from making the request.',
  'account_inactive': 'User or the workspace is deleted.',
  'token_revoked': 'Timy permissions have been removed from you or user/workspace has been deleted.',
  'no_permission': 'You do not have the permissions necessary to complete the request. Make sure timy is a member of the conversation it\'s attempting to post a message to.',
  'org_login_required': 'Your workspace is undergoing an enterprise migration and will not be available until migration is complete.',
  'ekm_access_denied': 'Administrators have suspended the ability to post a message.',
  'invalid_arguments': 'The method was called with invalid arguments.',
  'invalid_arg_name': 'The method was passed an argument whose name falls outside the bounds of accepted or expected values.',
  'invalid_charset': 'Charset specified in the Content-Type header was invalid.',
  'invalid_form_data': 'The form data was either missing or syntactically invalid.',
  'invalid_post_type': 'The method was called via a POST request, but the specified Content-Type was invalid.',
  'missing_post_type': 'Request did not include a Content-Type header.',
  'team_added_to_org': 'The workspace associated with your request is currently undergoing migration to an Enterprise Organization.',
  'request_timeout': 'Request is timeout.',
  'fatal_error': 'Slack could not complete your operation(s) without encountering a catastrophic error.',
  'cant_delete_file': 'You do not have permission to delete this file.',
};

const Constants = {
  AUTH: {
    URLS: {
      TO_LOGIN: `https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.avatar,identity.team&client_id=${CLIENT_ID}&state=fromLogin`,
      TO_ADD_TO_SLACK: `https://slack.com/oauth/v2/authorize?scope=im:history,chat:write,commands&user_scope=channels:read,users:read,chat:write,groups:read,im:read,mpim:read&client_id=${CLIENT_ID}&state=fromAddToSlack`,
      TO_ADD_TO_SLACK_FILES: `https://slack.com/oauth/v2/authorize?scope=im:history,chat:write,commands&user_scope=channels:read,users:read,chat:write,files:write,groups:read,im:read,mpim:read&client_id=${CLIENT_ID}&state=fromAddToSlackFiles`,
      TO_ADD_TO_SLACK_SLACKBOT: `https://slack.com/oauth/v2/authorize?scope=im:history,chat:write,commands&user_scope=channels:read,users:read,chat:write,files:write,groups:read,im:read,mpim:read&client_id=${CLIENT_ID}&state=fromAddToSlackBot`
    },
    STATES: ['fromLogin', 'fromAddToSlack', 'fromAddToSlackFiles', 'fromAddToSlackBot'],
    LOGIN_AGAIN_ERROR_CODES: [ERROR_CODES.INVALID_CODE_LOGIN],
    ADD_TO_SLACK_AGAIN_ERROR_CODES: [ERROR_CODES.INVALID_CODE_ADD_TO_SLACK],
    NO_PERMISSION_ERROR_CODES: [ERROR_CODES.MISSING_SCOPE, ERROR_CODES.MISSING_SLACK_TOKEN],
    COMMON_ERROR_CODES: [ERROR_CODES.SERVER_ERROR, ERROR_CODES.INVALID_PARAMS, ERROR_CODES.INVALID_STATE]
  },
  API: {
    'Auth': getPath('/auth'),
    'User_Get': getPath('/user.get'),
    'User_Logout': getPath('/user.logout'),
    'Users_List': getPath('/users.list'),
    'Channels_List': getPath('/channels.list'),
    'Message_Post': getPath('/message.post'),
    'Slack_Logout': getPath('/slack.logout'),
    'Messages_List': getPath('/messages.list'),
    'Message_Delete': getPath('/message.delete'),
    'Message_Activate': getPath('/message.activate'),
    'Message_Parse': getPath('/message.parse'),
    'Terms_Update': getPath('/terms.update'),
    'SLACK': {
      'file': {
        'upload': 'https://slack.com/api/files.upload',
        'delete': 'https://slack.com/api/files.delete'
      }
    },
    'Trial_Activate': getPath('/trial.activate'),
    'Subscription_Cancel': getPath('/subscription.cancel'),
    'Settings_Save': getPath('/settings.save'),
    'Settings_Access_Revoke': getPath('/settings.access.revoke')
  },
  ERROR_CODES,
  SLACK_CODES,
  ACTIONS: {
    SET_USER_LOADER: "SET_USER_LOADER",
    GET_USER_DATA: "GET_USER_DATA",
    SET_USER_LIST_DATA: "GET_USER_LIST_DATA",
    SET_CHANNEL_LIST_DATA: "GET_CHANNEL_LIST_DATA",
    SET_DIRECT_MESSAGE_LIST_DATA: "SET_DIRECT_MESSAGE_LIST_DATA",
    SET_GROUP_LIST_DATA: "SET_GROUP_LIST_DATA",
    SET_CHANNEL_IDS: "SET_CHANNEL_IDS",
    SET_USER_IDS: "SET_USER_IDS",
    SHOW_FS_ERROR_POPUP: "SHOW_FS_ERROR_POPUP",
    SET_ACTIVE_PAGE: "SET_ACTIVE_PAGE",
    SET_CHANNEL_LIST_LOADING: "SET_CHANNEL_LIST_LOADING",
    SET_USERS_LIST_LOADING: "SET_USERS_LIST_LOADING",
    SET_MESSAGES_LOADING: "SET_MESSAGES_LOADING",
    SET_USER_MESSAGES_DATA: "SET_USER_MESSAGES_DATA",
    MESSAGE_POPUP_SHOW: "MESSAGE_POPUP_SHOW",
    MESSAGE_POPUP_UPDATE: "MESSAGE_POPUP_UPDATE",
    DISCARD_POPUP_UPDATE: "DISCARD_POPUP_UPDATE",
    UPDATE_TIMEZONE_MEMORY: "UPDATE_TIMEZONE_MEMORY",
    UPDATE_USER_SETTINGS: "UPDATE_USER_SETTINGS",
    UPDATE_TEAM_SETTINGS: "UPDATE_TEAM_SETTINGS"
  },
  PADDLE: {
    VENDOR_ID: 52585,
    MONTHLY_PLAN_PRODUCT_ID: 569540,
    YEARLY_PLAN_PRODUCT_ID: 569541,
    STATES: {
      ACTIVE: "active",
      TRIALING: "trialling",
      PAST_DUE: "past_due",
      DELETED: "deleted"
    },
    PRICES: {
      MONTHLY: "$8.34",
      YEARLY: "$6.67"
    }
  },
  ACCESS_CONTROL: {
    ALLOW_ALL: 0,
    BLOCK_ALL: 1,
    ALLOW_ALL_EXCEPT: 2,
    BLOCK_ALL_EXCEPT: 3
  },
  MESSAGES: {
    'MESSAGE_POST_SUCCESS': 'Your message has been added successfully.',
    'MESSAGE_UPDATE_SUCCESS': 'Your message has been updated successfully.',
    'MESSAGE_DELETE_SUCCESS': 'Your message has been deleted successfully.',
    'MESSAGE_ACTIVATE_SUCCESS': 'Your message has been activated successfully.',
    'ALREADY_ON_PRO_PLAN': 'Your workspace can not upgrade to the PRO plan at the moment.'
  },
  API_DOMAIN,
  iS_LOCALHOST,
  SOCKET,
  NOTIFY_ICON: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAYCAYAAAD+vg1LAAAAAXNSR0IArs4c6QAAAARn
  QU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAILSURBVEhL7ZRLaxpRHMVPVQwyytSsfcBUTRF16lJT3EkKrmJT0nSdChKb
  QpqszF6EGIOfoEGaj5Aumm/gXhzTTvADpDoqNjo6tvf29jH1QbEPKPS3uXD+w5n/HM6dW+NP4A9gYOdv57/xVxY2Hg6HqFarkGUZmqYx9
  RsLtYIYnr48xfX7a4y1MZIPk0gkEmz6mQlj8vZOpzN1i0ajgYs3F6jVahAEAVtPtnD26gw2mw07mR0YjUb2JGBiJ6XX66FUKqEu1Zmix2
  QyweVyIZ1OQ7wnQlVVGAzT09QZS5IE+Z2M7afbiEajVCMZFo4KiMVi2Hy8SbUvEONZ6F6njTS6wbJ9mSmL83fqZjAa6OcpbYUpi6NrRbf
  bRbFYxJV8xRQ9JCa324315Dr8fj/6/T5OiifgOG6iFbqNrVYrstksCscFiKIIu92OVCpF9Xg8jsyzDCwWCzXL5XJoNptTa0mYyJhsRQxX
  76+i1WpBHapwOp2Q6hK8Xi/2D/ax92IPbaWNw+whbY1wR9BtS5h585SWgnw+D4fDgWAwiHK5jN3nuwgEAnQ+GAzoRSHd9vl89Pyema3gb
  /NYubuCy7eX4HkenJVDpVJhU8BsNiMUCtGsfzQlzK1bJBLBzYcbnL8+x6A/wJJ5CaPRiE3nM9fY4/Fg49EGjSUcDmPtwdpElrNY6O/2M8
  zd+Ff414yBjx5rxFSJbW2uAAAAAElFTkSuQmCC`,
  EMOTIONS: {
    " :) ": " :slightly_smiling_face: ",
    " :( ": " :disappointed: ",
    " ;) ": " :wink: ",
    " 8) ": " :sunglasses: ",
    " :* ": " :kissing_heart: ",
    " :p ": " :stuck_out_tongue: ",
    " :P ": " :stuck_out_tongue: ",
    " <3 ": " :heart: ",
    " :| ": " :neutral_face: ",
    " :D ": " :smile: ",
    " >:( ": " :angry: ",
    " :> ": " :laughing: ",
    " D: ": " :anguished: ",
    " :O ": " :open_mouth: ",
    " :o ": " :open_mouth: ",
    " :\\ ": " :confused: ",
    " :/ ": " :confused: "
  },
  EMOTIONS_ARRAY: [" :) ", " :( ", " ;) ", " 8) ", " :* ", " :p ", " :P ", " <3 ", " :| ", " :D ", " >:( ", " :> ", " D: ", " :O ", " :o ", " :\\ ", " :/ "],
  RECURRING_TYPES: ["Only once", "Every Monday", "Every Tuesday", "Every Wednesday",
    "Every Thursday", "Every Friday", "Every Saturday", "Every Sunday", "Every weekday",
    "Every weekend", "Every 2 weeks", "Every month", "Every year"],
  TIME_ZONES: [
    [
      "(UTC-11:00) Midway Island, American Samoa",
      "Pacific/Midway"
    ],
    [
      "(UTC-10:00) Aleutian Islands",
      "America/Adak"
    ],
    [
      "(UTC-10:00) Hawaii",
      "Pacific/Honolulu"
    ],
    [
      "(UTC-09:30) Marquesas Islands",
      "Pacific/Marquesas"
    ],
    [
      "(UTC-09:00) Alaska",
      "America/Anchorage"
    ],
    [
      "(UTC-08:00) Baja California",
      "America/Tijuana"
    ],
    [
      "(UTC-08:00) Pacific Time (US and Canada)",
      "America/Los_Angeles"
    ],
    [
      "(UTC-07:00) Arizona",
      "America/Phoenix"
    ],
    [
      "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      "America/Chihuahua"
    ],
    [
      "(UTC-07:00) Mountain Time (US and Canada), Navajo Nation",
      "America/Denver"
    ],
    [
      "(UTC-06:00) Central America",
      "America/Belize"
    ],
    [
      "(UTC-06:00) Central Time (US and Canada)",
      "America/Chicago"
    ],
    [
      "(UTC-06:00) Easter Island",
      "Pacific/Easter"
    ],
    [
      "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      "America/Mexico_City"
    ],
    [
      "(UTC-06:00) Saskatchewan",
      "America/Regina"
    ],
    [
      "(UTC-05:00) Bogota, Lima, Quito",
      "America/Bogota"
    ],
    [
      "(UTC-05:00) Chetumal",
      "America/Cancun"
    ],
    [
      "(UTC-05:00) Eastern Time (US and Canada)",
      "America/New_York"
    ],
    [
      "(UTC-05:00) Haiti",
      "America/Port-au-Prince"
    ],
    [
      "(UTC-05:00) Havana",
      "America/Havana"
    ],
    [
      "(UTC-05:00) Indiana (East)",
      "America/Indiana/Indianapolis"
    ],
    [
      "(UTC-04:00) Asuncion",
      "America/Asuncion"
    ],
    [
      "(UTC-04:00) Atlantic Time (Canada)",
      "America/Halifax"
    ],
    [
      "(UTC-04:00) Caracas",
      "America/Caracas"
    ],
    [
      "(UTC-04:00) Cuiaba",
      "America/Cuiaba"
    ],
    [
      "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      "America/Manaus"
    ],
    [
      "(UTC-04:00) Santiago",
      "America/Santiago"
    ],
    [
      "(UTC-04:00) Turks and Caicos",
      "America/Grand_Turk"
    ],
    [
      "(UTC-03:30) Newfoundland",
      "America/St_Johns"
    ],
    [
      "(UTC-03:00) Araguaina",
      "America/Fortaleza"
    ],
    [
      "(UTC-03:00) Brasilia",
      "America/Sao_Paulo"
    ],
    [
      "(UTC-03:00) Cayenne, Fortaleza",
      "America/Cayenne"
    ],
    [
      "(UTC-03:00) City of Buenos Aires",
      "America/Buenos_Aires"
    ],
    [
      "(UTC-03:00) Greenland",
      "America/Godthab"
    ],
    [
      "(UTC-03:00) Montevideo",
      "America/Montevideo"
    ],
    [
      "(UTC-03:00) Saint Pierre and Miquelon",
      "America/Miquelon"
    ],
    [
      "(UTC-03:00) Salvador",
      "America/Bahia"
    ],
    [
      "(UTC-02:00) Fernando de Noronha",
      "America/Noronha"
    ],
    [
      "(UTC-01:00) Azores",
      "Atlantic/Azores"
    ],
    [
      "(UTC-01:00) Cabo Verde Islands",
      "Atlantic/Cape_Verde"
    ],
    [
      "(UTC) Dublin, Edinburgh, Lisbon, London",
      "Europe/London"
    ],
    [
      "(UTC) Monrovia, Reykjavik",
      "Africa/Monrovia"
    ],
    [
      "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      "Europe/Amsterdam"
    ],
    [
      "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      "Europe/Belgrade"
    ],
    [
      "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      "Europe/Brussels"
    ],
    [
      "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      "Europe/Warsaw"
    ],
    [
      "(UTC+01:00) Casablanca",
      "Africa/Casablanca"
    ],
    [
      "(UTC+01:00) West Central Africa",
      "Africa/Algiers"
    ],
    [
      "(UTC+01:00) Windhoek",
      "Africa/Windhoek"
    ],
    [
      "(UTC+02:00) Amman",
      "Asia/Amman"
    ],
    [
      "(UTC+02:00) Athens, Bucharest",
      "Europe/Athens"
    ],
    [
      "(UTC+02:00) Beirut",
      "Asia/Beirut"
    ],
    [
      "(UTC+02:00) Cairo",
      "Africa/Cairo"
    ],
    [
      "(UTC+02:00) Damascus",
      "Asia/Damascus"
    ],
    [
      "(UTC+02:00) Gaza, Hebron",
      "Asia/Gaza"
    ],
    [
      "(UTC+02:00) Harare, Pretoria",
      "Africa/Harare"
    ],
    [
      "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      "Europe/Helsinki"
    ],
    [
      "(UTC+02:00) Jerusalem",
      "Asia/Jerusalem"
    ],
    [
      "(UTC+02:00) Kaliningrad",
      "Europe/Kaliningrad"
    ],
    [
      "(UTC+02:00) Tripoli",
      "Africa/Tripoli"
    ],
    [
      "(UTC+03:00) Baghdad",
      "Asia/Baghdad"
    ],
    [
      "(UTC+03:00) Istanbul",
      "Asia/Istanbul"
    ],
    [
      "(UTC+03:00) Kuwait, Riyadh",
      "Asia/Kuwait"
    ],
    [
      "(UTC+03:00) Minsk",
      "Europe/Minsk"
    ],
    [
      "(UTC+03:00) Moscow, St. Petersburg",
      "Europe/Moscow"
    ],
    [
      "(UTC+03:00) Nairobi",
      "Africa/Nairobi"
    ],
    [
      "(UTC+03:30) Tehran",
      "Asia/Tehran"
    ],
    [
      "(UTC+04:00) Abu Dhabi, Muscat",
      "Asia/Muscat"
    ],
    [
      "(UTC+04:00) Astrakhan, Ulyanovsk, Volgograd",
      "Europe/Astrakhan"
    ],
    [
      "(UTC+04:00) Baku",
      "Asia/Baku"
    ],
    [
      "(UTC+04:00) Izhevsk, Samara",
      "Europe/Samara"
    ],
    [
      "(UTC+04:00) Port Louis",
      "Indian/Mauritius"
    ],
    [
      "(UTC+04:00) Tbilisi",
      "Asia/Tbilisi"
    ],
    [
      "(UTC+04:00) Yerevan",
      "Asia/Yerevan"
    ],
    [
      "(UTC+04:30) Kabul",
      "Asia/Kabul"
    ],
    [
      "(UTC+05:00) Tashkent, Ashgabat",
      "Asia/Tashkent"
    ],
    [
      "(UTC+05:00) Ekaterinburg",
      "Asia/Yekaterinburg"
    ],
    [
      "(UTC+05:00) Islamabad, Karachi",
      "Asia/Karachi"
    ],
    [
      "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      "Asia/Kolkata"
    ],
    [
      "(UTC+05:30) Sri Jayawardenepura",
      "Asia/Colombo"
    ],
    [
      "(UTC+05:45) Kathmandu",
      "Asia/Katmandu"
    ],
    [
      "(UTC+06:00) Astana",
      "Asia/Almaty"
    ],
    [
      "(UTC+06:00) Dhaka",
      "Asia/Dhaka"
    ],
    [
      "(UTC+06:30) Yangon (Rangoon)",
      "Asia/Rangoon"
    ],
    [
      "(UTC+07:00) Novosibirsk",
      "Asia/Novosibirsk"
    ],
    [
      "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      "Asia/Bangkok"
    ],
    [
      "(UTC+07:00) Barnaul, Gorno-Altaysk",
      "Asia/Barnaul"
    ],
    [
      "(UTC+07:00) Hovd",
      "Asia/Hovd"
    ],
    [
      "(UTC+07:00) Krasnoyarsk",
      "Asia/Krasnoyarsk"
    ],
    [
      "(UTC+07:00) Tomsk",
      "Asia/Tomsk"
    ],
    [
      "(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
      "Asia/Chongqing"
    ],
    [
      "(UTC+08:00) Irkutsk",
      "Asia/Irkutsk"
    ],
    [
      "(UTC+08:00) Kuala Lumpur, Singapore",
      "Asia/Kuala_Lumpur"
    ],
    [
      "(UTC+08:00) Perth",
      "Australia/Perth"
    ],
    [
      "(UTC+08:00) Taipei",
      "Asia/Taipei"
    ],
    [
      "(UTC+08:00) Ulaanbaatar",
      "Asia/Ulaanbaatar"
    ],
    [
      "(UTC+08:30) Pyongyang",
      "Asia/Pyongyang"
    ],
    [
      "(UTC+08:45) Eucla",
      "Australia/Eucla"
    ],
    [
      "(UTC+09:00) Chita",
      "Asia/Chita"
    ],
    [
      "(UTC+09:00) Osaka, Sapporo, Tokyo",
      "Asia/Tokyo"
    ],
    [
      "(UTC+09:00) Seoul",
      "Asia/Seoul"
    ],
    [
      "(UTC+09:00) Yakutsk",
      "Asia/Yakutsk"
    ],
    [
      "(UTC+09:30) Adelaide",
      "Australia/Adelaide"
    ],
    [
      "(UTC+09:30) Darwin",
      "Australia/Darwin"
    ],
    [
      "(UTC+10:00) Brisbane",
      "Australia/Brisbane"
    ],
    [
      "(UTC+10:00) Canberra, Melbourne, Sydney",
      "Australia/Canberra"
    ],
    [
      "(UTC+10:00) Guam, Port Moresby",
      "Pacific/Guam"
    ],
    [
      "(UTC+10:00) Hobart",
      "Australia/Hobart"
    ],
    [
      "(UTC+10:00) Vladivostok",
      "Asia/Vladivostok"
    ],
    [
      "(UTC+10:30) Lord Howe Island",
      "Australia/Lord_Howe"
    ],
    [
      "(UTC+11:00) Bougainville Island",
      "Pacific/Bougainville"
    ],
    [
      "(UTC+11:00) Chokurdakh",
      "Asia/Srednekolymsk"
    ],
    [
      "(UTC+11:00) Magadan",
      "Asia/Magadan"
    ],
    [
      "(UTC+11:00) Norfolk Island",
      "Pacific/Norfolk"
    ],
    [
      "(UTC+11:00) Sakhalin",
      "Asia/Sakhalin"
    ],
    [
      "(UTC+11:00) Solomon Islands, New Caledonia",
      "Pacific/Guadalcanal"
    ],
    [
      "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
      "Asia/Anadyr"
    ],
    [
      "(UTC+12:00) Auckland, Wellington",
      "Pacific/Auckland"
    ],
    [
      "(UTC+12:00) Fiji Islands",
      "Pacific/Fiji"
    ],
    [
      "(UTC+12:45) Chatham Islands",
      "Pacific/Chatham"
    ],
    [
      "(UTC+13:00) Nuku'alofa",
      "Pacific/Tongatapu"
    ],
    [
      "(UTC+13:00) Samoa",
      "Pacific/Apia"
    ],
    [
      "(UTC+14:00) Kiritimati Island",
      "Pacific/Kiritimati"
    ]
  ]
}

export default Constants;