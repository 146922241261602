import React, { Component } from 'react';
import GroupButton from './GroupButton';
import { PubSub } from 'pubsub-js';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class AccessDeniedPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    componentDidMount() {
        PubSub.subscribe('showAccessDeniedPopup', (msg) => {
            this.setState({
                isVisible: true
            });
        });
    }

    handleClose = () => {
        this.setState({
            isVisible: false
        });
    }


    render() {
        return (
            <div role="dialog" aria-labelledby="pro-feature" tabIndex="-1" aria-describedby="AccessDeniedPopup"
                className={"Popup__Overlay" + (this.state.isVisible ? ' Open' : '')}>
                <div role="document" className="Popup__Container">
                    <div className="Popup__Body Modal">

                        <div className="Vertical--Center Center Flex-Direction--Column">
                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24"
                                fill="none" stroke="#ed3a14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                            </svg>
                            <h3>Access Denied!</h3>
                            <h2 className="Text-Align--Center Line-Height--24">The admin has disabled your Timy access.</h2>
                            <p className="Paragraph--Normal">Please contact your workspace admin to request Timy access. 
                            You won't be able to schedule new messages and the existing messages won't be delivered until you have given the required permission by your admin.</p>
                        </div>

                    </div>

                    <div className="Popup__Footer">
                        <div className="Popup__Footer__Actions">
                            <GroupButton
                                onClick={this.handleClose}
                                classNames="Button--Primary"
                                text="OK" />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(AccessDeniedPopup);