import React, { Component } from 'react';
import GroupButton from './GroupButton';
import SlideIn from './SlideIn';
import Utils from '../utils/Utils';
import Constants from './../utils/Constants';

export default class FullScreenErrorPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading: false
    }
  }

  handleSlackTokenDelete = () => {
    this.setState({
      is_loading: true
    });
    Utils.fetch({
      method: 'post',
      url: Constants.API.Slack_Logout
    }).then(response => {
      this.setState({
        is_loading: false
      });
      window.location.href = '/auth';
    }).catch(error => {
      this.setState({
        is_loading: false
      });
    });
  }

  render() {
    return (
      <SlideIn isShow={this.props.data.isVisible}>
        <div className="FullScreenErrorPopup">
          <div className="FullScreenErrorPopup__Container">

            <div className="Block">
              <div className="Block__Content">
                <h1>{this.props.data.title}</h1>
                <p>{this.props.data.message}</p>
              </div>
              <div className="Block__Footer">

                <fieldset disabled={this.state.is_loading}>

                  <a target="_self" className="Button Button--Secondary Margin-Right--12" href='https://timy.website'>
                    Get me out of Here
                  </a>

                  <GroupButton
                    onClick={this.handleSlackTokenDelete}
                    classNames="Button--Primary"
                    isLoading={this.state.is_loading}
                    text="Fix this" />

                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </SlideIn>
    );
  }

}