import React, { Component } from 'react';

class GroupButton extends Component {

  render() {
    return (
      <button disabled={this.props.isDisabled} onClick={this.props.onClick}
        className={"Button " +
          (this.props.isSelected ? ' Selected ' : ' ') + (this.props.classNames ? this.props.classNames : ' ')}>
          <span className="Loader__Wrapper">
            {this.props.isLoading ? <i className="ButtonLoader"></i> : null}
            {this.props.text}
          </span>
      </button>
    );
  }
}

export default GroupButton;