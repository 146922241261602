import Constants from './../utils/Constants';
import Utils from './../utils/Utils';
import store from './../store/index';
import Processor from './../utils/Processor';

export function setUserLoader(payload) {
  return {
    type: Constants.ACTIONS.SET_USER_LOADER,
    payload
  }
};

export function setActivePage(payload) {
  document.title = `Timy · ${Utils.capitalize(payload.replace('/', ''))}`;
  return {
    type: Constants.ACTIONS.SET_ACTIVE_PAGE,
    payload
  }
};

export function updateUserSettings(payload) { 
  return {
    type: Constants.ACTIONS.UPDATE_USER_SETTINGS,
    payload
  }
};

export function updateTeamSettings(payload) { 
  return {
    type: Constants.ACTIONS.UPDATE_TEAM_SETTINGS,
    payload
  }
};

export function getUserData(hideLoader) {
  return (dispatch) => {
    if(!hideLoader){
      dispatch({
        type: Constants.ACTIONS.SET_USER_LOADER,
        payload: true
      });
    }  
    return Utils.fetch({
      method: 'get',
      url: Constants.API.User_Get
    }).then(response => {
      dispatch({
        type: Constants.ACTIONS.GET_USER_DATA,
        payload: response.data.user
      });
      dispatch({
        type: Constants.ACTIONS.SET_USER_LOADER,
        payload: false
      });
      return response;
    }).catch(error => {
      dispatch({
        type: Constants.ACTIONS.SET_USER_LOADER,
        payload: false
      });
      return error;
    });
  };
}

export function showFullScreenPopup(payload) {
  return {
    type: Constants.ACTIONS.SHOW_FS_ERROR_POPUP,
    payload
  }
}

export function updateMessagePopup(payload) {
  return {
    type: Constants.ACTIONS.MESSAGE_POPUP_UPDATE,
    payload
  }
}

export function discardMessagePopup(payload) {
  return {
    type: Constants.ACTIONS.DISCARD_POPUP_UPDATE,
    payload
  }
}

export function updateTimeZoneMemory(payload) {
  return {
    type: Constants.ACTIONS.UPDATE_TIMEZONE_MEMORY,
    payload
  }
}

export function showMessagePopup(payload) {
  if (payload === true) {
    document.getElementsByTagName('body')[0].classList.add('Model--Open');
    document.getElementsByTagName('html')[0].classList.add('Model--Open');
  } else {
    document.getElementsByTagName('body')[0].classList.remove('Model--Open');
    document.getElementsByTagName('html')[0].classList.remove('Model--Open');
  }

  return {
    type: Constants.ACTIONS.MESSAGE_POPUP_SHOW,
    payload
  }
}



export function setMessageData(payload) {
  return (dispatch) => {

    const state = store.getState();

    if (state.is_users_loaded) {
      const messages = payload.map(message => {
        return Processor.processMessageForUser(state, message);
      });
      dispatch({
        type: Constants.ACTIONS.SET_USER_MESSAGES_DATA,
        payload: messages
      });
    }

    if (state.is_channels_loaded) {
      const messages = payload.map(message => {
        return Processor.processMessageForChannel(state, message);
      });
      dispatch({
        type: Constants.ACTIONS.SET_USER_MESSAGES_DATA,
        payload: messages
      });
    }

    if (!state.is_channels_loaded && !state.is_users_loaded) {
      dispatch({
        type: Constants.ACTIONS.SET_USER_MESSAGES_DATA,
        payload: payload
      });
    }

  };
}

//Get user list
export function getUserListData() {
  return (dispatch) => {
    dispatch({
      type: Constants.ACTIONS.SET_USERS_LIST_LOADING,
      payload: true
    });
    return Utils.fetch({
      method: 'get',
      url: Constants.API.Users_List
    }).then(response => {

      let usersObject = {};
      response.data.users.forEach(user => {
        usersObject[user.value] = user;
      });

      dispatch({
        type: Constants.ACTIONS.SET_USER_LIST_DATA,
        payload: response.data.users
      });

      dispatch({
        type: Constants.ACTIONS.SET_USER_IDS,
        payload: usersObject
      });

      dispatch({
        type: Constants.ACTIONS.SET_USERS_LIST_LOADING,
        payload: false
      });
      return response;
    }).catch(error => {
      dispatch({
        type: Constants.ACTIONS.SET_USERS_LIST_LOADING,
        payload: false
      });
      return error;
    });
  };
}

//Get messages list
export function getUserMessagesData() {
  return (dispatch) => {
    dispatch({
      type: Constants.ACTIONS.SET_MESSAGES_LOADING,
      payload: true
    });
    return Utils.fetch({
      method: 'get',
      url: Constants.API.Messages_List
    }).then(response => {
      dispatch(setMessageData(response.data.messages));
      dispatch({
        type: Constants.ACTIONS.SET_MESSAGES_LOADING,
        payload: false
      });
      return response;
    }).catch(error => {
      dispatch({
        type: Constants.ACTIONS.SET_MESSAGES_LOADING,
        payload: false
      });
      return error;
    });
  };
}


//Get channel list
export function getChannelListData() {
  return (dispatch) => {
    dispatch({
      type: Constants.ACTIONS.SET_CHANNEL_LIST_LOADING,
      payload: true
    });
    return Utils.fetch({
      method: 'get',
      url: Constants.API.Channels_List
    }).then(response => {

      let groups = [], direct_messages = [], channelsObject = {};
      response.data.channels = response.data.channels.filter(channel => {

        channelsObject[channel.value] = channel;

        if (channel.is_mpim) {
          groups.push(channel);
          return null;
        } if (channel.is_im) {
          direct_messages.push(channel);
          return null;
        } else {
          return channel;
        }
      });

      dispatch({
        type: Constants.ACTIONS.SET_GROUP_LIST_DATA,
        payload: groups
      });

      dispatch({
        type: Constants.ACTIONS.SET_CHANNEL_IDS,
        payload: channelsObject
      });

      dispatch({
        type: Constants.ACTIONS.SET_DIRECT_MESSAGE_LIST_DATA,
        payload: direct_messages
      });

      dispatch({
        type: Constants.ACTIONS.SET_CHANNEL_LIST_DATA,
        payload: response.data.channels.concat(groups)
      });

      dispatch({
        type: Constants.ACTIONS.SET_CHANNEL_LIST_LOADING,
        payload: false
      });

      return response;
    }).catch(error => {
      dispatch({
        type: Constants.ACTIONS.SET_CHANNEL_LIST_LOADING,
        payload: false
      });
      return error;
    });
  };
}