// eslint-disable-next-line
import babelPolyfill from 'babel-polyfill';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from "react-redux";
import openSocket from 'socket.io-client';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "./styles/app.scss";
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header';
import Footer from './components/Footer';

import AuthPage from "./pages/auth/AuthPage";
import MessagesPage from "./pages/messages/MessagesPage";
import Utils from './utils/Utils';
import FullScreenLoader from './components/FullScreenLoader';

import store from "./store/index";
import { connect } from "react-redux";
import { setUserLoader, getUserData, getUserMessagesData } from "./actions/index";
import FullScreenErrorPopup from './components/FullScreenErrorPopup';
import NotFoundPage from './pages/not-found/NotFoundPage';
import PrivateRoute from './components/PrivateRoute';
import WelcomePage from './pages/welcome/WelcomePage';
import CheatSheetPage from './pages/cheatsheet/CheatSheetPage';
import SettingsPage from './pages/settings/SettingsPage';
import BillingPage from './pages/billing/BillingPage';
import LoginPage from './pages/login/LoginPage';
import MessagePopup from './components/MessagePopup';
import ConfirmPopup from './components/ConfirmPopup';
import TrialStarterPopup from './components/TrialStarterPopup';
import AccessDeniedPopup from './components/AccessDeniedPopup';
import { ToastContainer } from 'react-toastify';
import Constants from './utils/Constants';
import { PubSub } from 'pubsub-js';
import ProFeaturePopup from './components/ProFeaturePopup';


const mapStateToProps = (state) => {
  return {
    messages: state.messages,
    is_user_loading: state.is_user_loading,
    user: state.user,
    fullscreen_error_popup: state.fullscreen_error_popup
  };
}

class App extends React.Component {

  componentDidMount() {
    this.init();
    PubSub.subscribe('initSocket', () => {
      this.init();
    });
    document.getElementsByTagName('body')[0].classList.add("Patterns");
  }

  init() {
    if (Utils.isLoggedIn()) {
      this.props.getUserData().then(res => {

        //SOCKET CONNECTION
        //==========================================
        if (!Constants.iS_LOCALHOST) {
          const socket = openSocket(Constants.SOCKET);

          socket.on(`addedMessagesOn__${this.props.user.team_id}___${this.props.user.user_id}`, messageId => {
            const filteredMessages = this.props.messages.filter(message => {
              return message.message_id === messageId;
            });
            if (filteredMessages.length === 0) {
              Utils.showToast('success', 'Messages list has been updated');
              this.props.getUserMessagesData();
            }
          });

          socket.on(`deletedMessagesOn__${this.props.user.team_id}___${this.props.user.user_id}`, messageId => {
            const filteredMessages = this.props.messages.filter(message => {
              return message.message_id === messageId;
            });
            if (filteredMessages.length > 0) {
              Utils.showToast('success', 'Messages list has been updated');
              this.props.getUserMessagesData();
            }
          });

          socket.on(`updatedProMessageOn__${this.props.user.team_id}___${this.props.user.user_id}`, proMessageId => {
            Utils.showToast('success', 'Messages list has been updated');
            this.props.getUserMessagesData();
          });

          socket.on(`teamPlanChangedOn__${this.props.user.team_id}`, teamStatus => {
            if (teamStatus === 1) {
              PubSub.publish('showTrialStarter', true);
            } else {
              window.location.reload();
            }
          });

        }

      });
    }
  }

  render() {
    return (
      <Router>
        <section className="Main">
          <section className="Frame">
            <section className="Page">
              <Header />
              <div className="Content">
                <Switch>
                  <Route exact path="/auth/" component={AuthPage} />
                  <Route exact path="/login" component={LoginPage} />

                  <Route exact path="/" render={() => (
                    Utils.isLoggedIn() ? <Redirect to="/messages" /> : <Redirect to="/login" />
                  )} />

                  <PrivateRoute exact path="/welcome/" component={WelcomePage} />
                  <PrivateRoute exact path="/messages/" component={MessagesPage} />
                  <PrivateRoute exact path="/cheatsheet/" component={CheatSheetPage} />
                  {this.props.user && this.props.user.user_id ? <PrivateRoute exact path="/settings/" component={SettingsPage} /> : Utils.isLoggedIn() ? null : <Redirect to="/login" />}
                  {this.props.user && this.props.user.user_id ? <PrivateRoute exact path="/billing/" component={BillingPage} /> : Utils.isLoggedIn() ? null : <Redirect to="/login" />}
                  {Utils.isLoggedIn() ? (this.props.user && this.props.user.user_id) ? <Route component={NotFoundPage} /> : null : <Route component={NotFoundPage} />}

                </Switch>

              </div>

              <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange={false}
                draggable={false}
                pauseOnHover={false}
              />

              <FullScreenLoader isLoading={this.props.is_user_loading} />
              <MessagePopup />
              <ConfirmPopup />
              <TrialStarterPopup />
              <AccessDeniedPopup />
              <ProFeaturePopup />
              <FullScreenErrorPopup data={this.props.fullscreen_error_popup} />

            </section>
          </section>
          <Footer />
        </section>
      </Router>
    );
  }

}

const ConnectedApp = connect(mapStateToProps, {
  setUserLoader,
  getUserData,
  getUserMessagesData
})(App);

const rootElement = document.getElementById("App");
ReactDOM.render(<Provider store={store}>
  <ConnectedApp />
</Provider>, rootElement);
