import React, { Component } from 'react';
import GroupButton from '../../components/GroupButton';
import { getUserData } from '../../actions/index';
import { PubSub } from 'pubsub-js';
import { connect } from 'react-redux';
import Utils from '../../utils/Utils';
import Constants from '../../utils/Constants';

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class SubscriptionCancelPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            isCancelling: false,
            subscriptionId: null,
            reason: ''
        }
    }

    componentDidMount() {
        PubSub.subscribe('showSubscriptionCancelPopup', (msg, data) => {
            this.reasonInput = React.createRef();
            this.setState({
                isVisible: true,
                subscriptionId: data.subscription_id,
                isCancelling: false,
                reason: ''
            }, () => {
                setTimeout(() => {
                    this.reasonInput.current.focus();
                }, 500);
            });

        });
    }

    handleDowngrade = () => {
        this.setState({
            isCancelling: true,
        });
        Utils.fetch({
            method: 'post',
            url: Constants.API.Subscription_Cancel,
            data: {
                subscription_id: this.state.subscriptionId,
                reason: this.state.reason
            }
        }).then(response => {
            this.props.getUserData(true).then(res => {
                Utils.showToast('success', `${this.props.user.team_name} has been downgraded to FREE plan successfully.`);
                this.setState({
                    isCancelling: false,
                    isVisible: false
                });
            });
        }).catch(error => {
            Utils.showToast('error', error.desc);
            this.setState({
                isCancelling: false
            });
        });
    }

    handleCancel = () => {
        this.setState({
            isVisible: false
        })
    }

    handleReasonChange = (e) => {
        this.setState({
            reason: e.target.value
        })
    }

    render() {
        return (
            <div role="dialog" aria-labelledby="trial-start" tabIndex="-1" aria-describedby="subscriptionCancelPopup"
                className={"Popup__Overlay" + (this.state.isVisible ? ' Open' : '')}>
                <div role="document" className="Popup__Container SubscriptionCancelPopup">
                    <div className="Popup__Head">
                        <h3>Cancel Pro Plan</h3>
                    </div>
                    <div className="Popup__Body Modal">                      
                        <div className="Vertical--Center Flex-Direction--Column">
                            <h2 className="Line-Height--24 SubscriptionCancelPopup__SubTitle">It's sad to see you go. <span role="img" aria-label="enjoy">😞</span></h2>
                            <p className="Paragraph--Normal">Can you please tell us why you are downgrading so we can improve Timy in future.</p>
                            <div className="Form">
                                <div className="FormGroup">
                                    <label className="FormGroup__Label" htmlFor="reason">
                                        Reason for Downgrade (Required):
                                    </label>
                                    <textarea disabled={this.state.isCancelling} className="FormGroup__Input" ref={this.reasonInput} onChange={this.handleReasonChange} value={this.state.reason}
                                        id="reason" name="reason" placeholder="Ex: Price was too high"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Popup__Footer">
                        <div className="Popup__Footer__Actions">
                            <GroupButton
                                isDisabled={this.state.isCancelling}
                                onClick={this.handleCancel}
                                classNames="Font-Size--14 Button--Secondary Margin-Right--12"
                                text="Cancel" />
                            <GroupButton
                                isDisabled={this.state.isCancelling || this.state.reason.length === 0}
                                onClick={this.handleDowngrade}
                                isLoading={this.state.isCancelling}
                                classNames="Font-Size--14 Button--Dangerous"
                                text="Downgrade" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, { getUserData })(SubscriptionCancelPopup);