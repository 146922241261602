import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { setUserLoader, setActivePage, updateUserSettings, updateTeamSettings, getUserListData } from '../../actions/index';
import queryString from 'query-string';
import Select from 'react-select';
import SlideIn from './../../components/SlideIn';
import { connect } from 'react-redux';
import Utils from './../../utils/Utils';
import Constants from './../../utils/Constants';
import InfiniteProgressBar from './../../components/InfiniteProgressBar';
import Axios from 'axios';
import { PubSub } from 'pubsub-js';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    is_users_loaded: state.is_users_loaded,
    is_user_loading: state.is_user_loading,
    team_users: state.team_users
  }
}

class SettingsPage extends React.Component {

  constructor(props) {
    super(props);

    this.userAccessOptions = [
      {
        value: 0,
        label: 'Allow all users'
      },
      {
        value: 1,
        label: 'Block all users'
      },
      {
        value: 2,
        label: 'Allow all users except...'
      },
      {
        value: 3,
        label: 'Block all users except...'
      }
    ];

    this.state = {
      isSettingsSave: false,
      filteredUsers: [],
      accessControlStateOption: this.userAccessOptions[0]
    };

    this.allEmails = React.createRef();
    this.failedMessagesEmailCheckBox = React.createRef();
    this.planChangesEmailCheckBox = React.createRef();
    this.newFeaturesEmailCheckBox = React.createRef();
    this.offersEmailCheckBox = React.createRef();
    this.slackbotNotificationCheckBox = React.createRef();

    this.CancelToken = Axios.CancelToken;
    this.settingsSource = this.CancelToken.source();
  }

  componentDidMount() {

    this.failedMessagesEmailCheckBox.current.checked = this.props.user.settings.message_fail_emails;
    this.planChangesEmailCheckBox.current.checked = this.props.user.settings.plan_changes_emails;
    this.newFeaturesEmailCheckBox.current.checked = this.props.user.settings.newsletter;
    this.offersEmailCheckBox.current.checked = this.props.user.settings.offers_emails;

    if (this.isAdmin()) {
      this.setState({
        // eslint-disable-next-line
        accessControlStateOption: this.userAccessOptions.filter((userAccessOption) => {
          if (userAccessOption.value === this.props.user.team.admin.access_control_state) {
            return userAccessOption;
          }
        })[0]
      });
    }

    this.setEmailPreferencesCheckedState();

    this.slackbotNotificationCheckBox.current.checked = this.props.user.settings.slackbot_alerts;

    if (!this.isProUser()) {
      this.slackbotNotificationCheckBox.current.checked = false;
      this.setState({
        accessControlStateOption: this.userAccessOptions[0],
        filteredUsers: []
      });
    }
    this.props.setActivePage('/settings');

    //Check for the Slackbot notifications
    const { slackBotPermission } = queryString.parse(window.location.search);
    if (slackBotPermission && slackBotPermission === "true") {
      Utils.showToast('success', 'Slackbot permissions have been granted successfully.');
      window.history.pushState({}, document.title, window.location.pathname);
    }

    //If this is the admin fetch user list  
    if (this.isAdmin()) {
      if (!this.props.is_users_loaded) {
        this.props.getUserListData().then(res => {
          this.prepareFilteredUsers();
        });
      } else {
        this.prepareFilteredUsers();
      }
    }

  }

  prepareFilteredUsers = () => {
    if (this.isProUser()) {
      this.setState({
        // eslint-disable-next-line           
        filteredUsers: this.props.team_users.filter(user => {
          if (this.props.user.team.admin.filtered_users.indexOf(user.value) !== -1) {
            return user;
          }
        })
      });
    }
  }

  handleRevokeAccess = () => {
    PubSub.publish('showConfirm', {
      title: 'Please Confirm',
      message: "Revoking Timy access will delete all the scheduled messages in the queue. Are you sure?",
      yesButtonText: "Yes",
      noButtonText: "No",
      onYes: () => {
        this.props.setUserLoader(true);
        Utils.fetch({
          method: 'post',
          url: Constants.API.Settings_Access_Revoke
        }).then(response => {
          this.props.setUserLoader(false);
          PubSub.publish('handleLogout');
        }).catch(error => {
          this.props.setUserLoader(false);
          Utils.showToast('error', error.desc);
        });
      }
    });
  }

  handleSlackbotNotificationSave = () => {
    if (this.isProUser()) {
      this.handleSettingsSave({
        slackbot_alerts: this.slackbotNotificationCheckBox.current.checked
      }, () => {
        if (this.slackbotNotificationCheckBox.current) {
          this.slackbotNotificationCheckBox.current.checked = !this.slackbotNotificationCheckBox.current.checked;
        }
      });
    } else {
      this.slackbotNotificationCheckBox.current.checked = !this.slackbotNotificationCheckBox.current.checked;
      PubSub.publish('showProFeaturePopup');
    }

  }

  handleEmailFailedMessages = () => {
    this.setEmailPreferencesCheckedState();
    this.handleSettingsSave({
      message_fail_emails: this.failedMessagesEmailCheckBox.current.checked
    }, () => {
      if (this.failedMessagesEmailCheckBox.current) {
        this.failedMessagesEmailCheckBox.current.checked = !this.failedMessagesEmailCheckBox.current.checked;
        this.setEmailPreferencesCheckedState();
      }
    });
  }

  handleEmailPlanChanges = () => {
    this.setEmailPreferencesCheckedState();
    this.handleSettingsSave({
      plan_changes_emails: this.planChangesEmailCheckBox.current.checked
    }, () => {
      if (this.planChangesEmailCheckBox.current) {
        this.planChangesEmailCheckBox.current.checked = !this.planChangesEmailCheckBox.current.checked;
        this.setEmailPreferencesCheckedState();
      }
    });
  }

  handleEmailFeatureUpdates = () => {
    this.setEmailPreferencesCheckedState();
    this.handleSettingsSave({
      newsletter: this.newFeaturesEmailCheckBox.current.checked
    }, () => {
      if (this.newFeaturesEmailCheckBox.current) {
        this.newFeaturesEmailCheckBox.current.checked = !this.newFeaturesEmailCheckBox.current.checked;
        this.setEmailPreferencesCheckedState();
      }
    });
  }

  handleEmailOfferUpdates = () => {
    this.setEmailPreferencesCheckedState();
    this.handleSettingsSave({
      offers_emails: this.offersEmailCheckBox.current.checked
    }, () => {
      if (this.offersEmailCheckBox.current) {
        this.offersEmailCheckBox.current.checked = !this.offersEmailCheckBox.current.checked;
        this.setEmailPreferencesCheckedState();
      }
    });
  }

  handleEmailNotificationsAll = () => {

    //Store current state of the checkboxes
    const currentEmailPreferences = {
      plan_changes_emails: this.planChangesEmailCheckBox.current.checked,
      message_fail_emails: this.failedMessagesEmailCheckBox.current.checked,
      offers_emails: this.offersEmailCheckBox.current.checked,
      newsletter: this.newFeaturesEmailCheckBox.current.checked
    }

    if (this.allEmails.current.indeterminate || this.allEmails.current.checked) {

      this.planChangesEmailCheckBox.current.checked = true;
      this.failedMessagesEmailCheckBox.current.checked = true;
      this.newFeaturesEmailCheckBox.current.checked = true;
      this.offersEmailCheckBox.current.checked = true;

      this.handleSettingsSave({
        plan_changes_emails: true,
        message_fail_emails: true,
        offers_emails: true,
        newsletter: true
      }, () => {
        if (this.allEmails.current) {
          this.planChangesEmailCheckBox.current.checked = currentEmailPreferences.plan_changes_emails;
          this.failedMessagesEmailCheckBox.current.checked = currentEmailPreferences.message_fail_emails;
          this.newFeaturesEmailCheckBox.current.checked = currentEmailPreferences.newsletter;
          this.offersEmailCheckBox.current.checked = currentEmailPreferences.offers_emails;
          this.setEmailPreferencesCheckedState();
        }
      });

    } else {
      this.planChangesEmailCheckBox.current.checked = false;
      this.failedMessagesEmailCheckBox.current.checked = false;
      this.newFeaturesEmailCheckBox.current.checked = false;
      this.offersEmailCheckBox.current.checked = false;

      this.handleSettingsSave({
        plan_changes_emails: false,
        message_fail_emails: false,
        offers_emails: false,
        newsletter: false
      }, () => {
        if (this.allEmails.current) {
          this.planChangesEmailCheckBox.current.checked = currentEmailPreferences.plan_changes_emails;
          this.failedMessagesEmailCheckBox.current.checked = currentEmailPreferences.message_fail_emails;
          this.newFeaturesEmailCheckBox.current.checked = currentEmailPreferences.newsletter;
          this.offersEmailCheckBox.current.checked = currentEmailPreferences.offers_emails;
          this.setEmailPreferencesCheckedState();
        }
      });

    }
  }

  handleSettingsSave = (settings, onFail, onSuccess) => {

    this.setState({ isSettingsSave: true });

    Utils.fetch({
      method: 'post',
      url: Constants.API.Settings_Save,
      cancelToken: this.settingsSource.token,
      data: {
        settings
      }
    }).then(response => {

      this.props.updateUserSettings({
        ...this.props.user.settings,
        slackbot_alerts: this.slackbotNotificationCheckBox.current.checked,
        plan_changes_emails: this.planChangesEmailCheckBox.current.checked,
        message_fail_emails: this.failedMessagesEmailCheckBox.current.checked,
        offers_emails: this.offersEmailCheckBox.current.checked,
        newsletter: this.newFeaturesEmailCheckBox.current.checked,
        access_control_state: this.state.accessControlStateOption.value
      });
      // Utils.showToast('success', "Settings updated successfully.");
      this.setState({ isSettingsSave: false });

      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }

    }).catch(error => {
      this.setState({ isSettingsSave: false });
      if (onFail && typeof onFail === "function") {
        onFail();
      }

      if (error.code === "missing_scope" || error.code === "invalid_arguments") {
        PubSub.publish('showConfirm', {
          title: 'Authorize Timy',
          message: "Timy needs additional permissions to send Slackbot notifications.",
          yesButtonText: "Grant Permission",
          noButtonText: "Cancel",
          onYes: () => {
            window.location.href = Constants.AUTH.URLS.TO_ADD_TO_SLACK_SLACKBOT
          }
        });
      } else {
        Utils.showToast('error', error.desc);
      }

    });
  }

  setEmailPreferencesCheckedState = () => {
    if (this.failedMessagesEmailCheckBox.current.checked &&
      this.planChangesEmailCheckBox.current.checked &&
      this.newFeaturesEmailCheckBox.current.checked &&
      this.offersEmailCheckBox.current.checked) {
      this.allEmails.current.checked = true;
      this.allEmails.current.indeterminate = false;
    } else if (!this.failedMessagesEmailCheckBox.current.checked &&
      !this.planChangesEmailCheckBox.current.checked &&
      !this.newFeaturesEmailCheckBox.current.checked &&
      !this.offersEmailCheckBox.current.checked) {
      this.allEmails.current.checked = false;
      this.allEmails.current.indeterminate = false;
    } else {
      this.allEmails.current.indeterminate = true;
    }
  }

  componentWillUnmount() {
    this.settingsSource.cancel();
  }

  isProUser = () => {
    return (this.props.user.team.status === 1 || this.props.user.team.status === 3);
  }

  isAdmin = () => {
    return (this.props.user.slack_profile && this.props.user.slack_profile.is_admin);
  }

  onUserSelected = (filteredUsers) => {
    this.previousFilteredUsers = this.state.filteredUsers;
    this.setState({
      filteredUsers
    }, () => {
      this.userAccessOptionSelected(this.state.accessControlStateOption);
    });
  }

  customUserSingleValue = ({ data }) => (
    <div className="select__single-value CustomSelectValue">
      <img alt="Avatar" className="CustomSelectValue__Avatar" src={data.image_192} />
      <span title={data.label} className="CustomSelectValue__Text">{data.label}</span>
      {data.is_bot ? <span className="AppTag">APP</span> : null}
    </div>
  );

  customUserOptionLabel = (data) => (
    <>
      <img alt="Avatar" className="CustomSelectValue__Avatar" src={data.image_192} />
      <span title={data.label} className="CustomSelectValue__Text">{data.label}</span>
      {data.is_bot ? <span className="AppTag">APP</span> : null}
    </>
  );

  getFilteredUsers = () => {
    if (this.state.filteredUsers !== null) {
      return this.state.filteredUsers.map((user) => {
        return user.value;
      });
    } else {
      return [];
    }
  }

  userAccessOptionSelected = (userAccessOption) => {

    if (this.isProUser()) {

      const previousUserAccessOption = this.state.accessControlStateOption;      

      this.setState((prevState, props) => ({
        accessControlStateOption: userAccessOption,
        filteredUsers: prevState.accessControlStateOption.value !== userAccessOption.value ? [] : prevState.filteredUsers,
        isAccessControlSaving: true
      }), () => {

        const _filtered_users = this.getFilteredUsers();

        this.handleSettingsSave({
          access_control_state: userAccessOption.value,
          filtered_users: _filtered_users
        }, () => {
          //on error  
          this.setState({
            accessControlStateOption: previousUserAccessOption,
            filteredUsers: this.previousFilteredUsers || this.state.filteredUsers,
            isAccessControlSaving: false
          });
        }, () => {

          this.setState({
            isAccessControlSaving: false
          });

          this.props.updateTeamSettings({
            ...this.props.user.team,
            admin: {
              ...this.props.user.team.admin,
              access_control_state: userAccessOption.value,
              filtered_users: _filtered_users
            }
          })
        });

      });

    } else {
      this.setState({
        accessControlStateOption: this.userAccessOptions[0],
        filteredUsers: []
      });
      PubSub.publish('showProFeaturePopup');
    }

  }

  render() {
    return (

      <SlideIn isShow={this.props.user}>
        <div className="">

          <div className="TabsPanel">
            <div className="TabsPanel__Inner">
              <div className="Tab Tab__First Selected">Preferences</div>
              <NavLink className="Link__Plain" to="/billing"><div className="Tab Tab__Last">Billing</div></NavLink>
            </div>
          </div>

          <div className="Welcome Relative Padding--0 Margin-Top--24">
            <InfiniteProgressBar classNames="Top--m12 PageProgressBar FullScreenProgressBar" isShow={this.state.isSettingsSave}></InfiniteProgressBar>
            <div className="Form Padding--0">
              <form name="settings">
                <fieldset>

                  {this.props.user.slack_profile && this.props.user.slack_profile.is_admin ? <div className="SettingsSectionBlock">
                    <h3 className="SettingsSubTitle">Access Control (Admin only)  {this.props.user.team && (this.props.user.team.status === 0 || this.props.user.team.status === 2) ? <span>&nbsp;<span className="ProBadge ScheduleButton__ProBadge Paragraph--Small"><b>PRO</b></span></span> : ''}</h3>
                    <p className="Paragraph--Normal Margin-Bottom--8">
                      Control who can use Timy to send scheduled messages in your workspace.
                    </p>

                    <Select
                      className="User__Search Margin-Bottom--12 SelectLeftBorder"
                      classNamePrefix="select"
                      onChange={this.userAccessOptionSelected}
                      isClearable={false}
                      isSearchable={false}
                      ref="userAccessControl"
                      value={this.state.accessControlStateOption}
                      options={this.userAccessOptions}
                    />

                    <div className="To_Whom MultiSelect">

                      {this.isProUser() && !this.props.is_users_loaded && this.state.accessControlStateOption.value > 1 ? <div className="Paragraph--Normal Vertical--Center Center">
                        <div className="LoadingSpinner Margin-Right--8"></div> Loading users...</div> : null}

                      {this.state.accessControlStateOption.value > 1 && this.props.is_users_loaded ? <div className="Input__Group">
                        <button type="button" className="Button Left__Radius Group__Button Selected Margin--0 Center--Force">
                          {this.state.isAccessControlSaving ? <div className="LoadingSpinner Spinner--Small Margin-Right--8"></div> : null}
                          {this.state.accessControlStateOption.value === 2 ? 'Block' : 'Allow'}
                        </button>
                        <Select
                          className="User__Search User__Filter"
                          classNamePrefix="select"
                          defaultValue={null}
                          isDisabled={false}
                          isLoading={false}
                          menuPlacement="auto"
                          isClearable={false}
                          isRtl={false}
                          openMenuOnFocus={true}
                          isSearchable={true}
                          noOptionsMessage={() => 'No users found'}
                          placeholder="Select users"
                          onChange={this.onUserSelected}
                          ref="user"
                          name="users"
                          isMulti
                          value={this.state.filteredUsers}
                          components={{ SingleValue: this.customUserSingleValue }}
                          formatOptionLabel={this.customUserOptionLabel}
                          options={this.props.team_users} />
                      </div> : null}

                    </div>

                  </div> : null}

                  <div className="SettingsSectionBlock">
                    <h3 className="SettingsSubTitle">Slack Alerts</h3>

                    <label htmlFor="slackbot_alerts" className="Container">
                      Send Slackbot alerts when scheduled messages are delivered
                    {this.props.user.team && (this.props.user.team.status === 0 || this.props.user.team.status === 2) ? <span>&nbsp;<span className="ProBadge ScheduleButton__ProBadge Paragraph--Small"><b>PRO</b></span></span> : '.'}
                      <input ref={this.slackbotNotificationCheckBox} onChange={this.handleSlackbotNotificationSave} id="slackbot_alerts" type="checkbox" />
                      <span className="Checkmark"></span>
                    </label>
                  </div>

                  <div className="SettingsSectionBlock">
                    <h3 className="SettingsSubTitle">Email Preferences</h3>

                    <label htmlFor="all_emails" className="Container"> Receive emails from Timy
                    <input ref={this.allEmails} onChange={this.handleEmailNotificationsAll} id="all_emails" type="checkbox" />
                      <span className="Checkmark"></span>
                    </label>

                    <div className="PaddingLeft--36">

                      <h5>Application</h5>

                      <label htmlFor="plan_change_notifications" className="Container"> Trial period and subscription plan changes
                    <input ref={this.planChangesEmailCheckBox} onChange={this.handleEmailPlanChanges} id="plan_change_notifications" type="checkbox" />
                        <span className="Checkmark"></span>
                      </label>

                      <label htmlFor="fail_notifications" className="Container"> When scheduled messages are failed to send
                    <input ref={this.failedMessagesEmailCheckBox} onChange={this.handleEmailFailedMessages} id="fail_notifications" type="checkbox" />
                        <span className="Checkmark"></span>
                      </label>

                      <h5>News and Updates</h5>

                      <label htmlFor="feature_updates_notifications" className="Container"> New feature updates
                    <input ref={this.newFeaturesEmailCheckBox} onChange={this.handleEmailFeatureUpdates} id="feature_updates_notifications" type="checkbox" />
                        <span className="Checkmark"></span>
                      </label>

                      <label htmlFor="offers_notifications" className="Container"> Offers and promotions
                    <input ref={this.offersEmailCheckBox} onChange={this.handleEmailOfferUpdates} id="offers_notifications" type="checkbox" />
                        <span className="Checkmark"></span>
                      </label>
                    </div>
                  </div>

                  <div className="SettingsSectionBlock">
                    <h3 className="SettingsSubTitle Color--Red">Danger Zone</h3>
                    <button type="button" onClick={this.handleRevokeAccess}
                      className="Button Button--Light Button--Secondary">Revoke Timy Access
                    </button>
                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
      </SlideIn >
    )
  }
}

export default withRouter(connect(mapStateToProps, { setUserLoader, setActivePage, updateUserSettings, updateTeamSettings, getUserListData })(SettingsPage));