import React, { Component } from 'react';
import GroupButton from './GroupButton';
import { PubSub } from 'pubsub-js';
import { connect } from 'react-redux';
import Constants from './../utils/Constants';

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class ProFeaturePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    componentDidMount() {
        PubSub.subscribe('showProFeaturePopup', (msg) => {
            this.setState({
                isVisible: true
            });
        });
    }

    handleClose = () => {
        this.setState({
            isVisible: false
        });
    }

    handleUpgradeToPro = () => {
        window.location.href = "/billing";
    }

    handleStartTrial = () => {
        this.setState({
            isVisible: false
        }, () => {
            PubSub.publish('showTrialStarter');
        });
    }

    render() {
        return (
            <div role="dialog" aria-labelledby="pro-feature" tabIndex="-1" aria-describedby="proFeaturePopup"
                className={"Popup__Overlay" + (this.state.isVisible ? ' Open' : '')}>
                <div role="document" className="Popup__Container">
                    <div className="Popup__Head">
                        {/* <h3>Pro Feature!</h3> */}
                    </div>
                    <div className="Popup__Body Modal">
                        {/* {this.state.isTrialStarting ? <div className="Vertical--Center Center">
                            <div className="LoadingSpinner"></div>
                            <p className="Margin-Left--12"><b>Hold tight,</b> <br />Your 14 day trial period is starting...</p>
                        </div> : null} */}

                        <div className="Vertical--Center Center Flex-Direction--Column">
                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72"
                                viewBox="0 0 24 24" fill="none" stroke="#edb314"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                                </polygon>
                            </svg>
                            <h3>PRO Feature!</h3>
                            <h2 className="Text-Align--Center Line-Height--24">Your workspace "{this.props.user.team_name}" is currently on Timy <b>FREE</b> plan.</h2>
                            <p className="Paragraph--Normal">Please upgrade to the <span className="ProBadge"><b>PRO</b></span> plan to use this feature. With PRO plan you'll be able to,</p>
                            <ul className="Ticks">
                                <li>Send recurring messages (Every weekday, Every month...)</li>
                                <li>Send unlimited messages in any time zone</li>
                                <li>Increase scheduling time limit up to <b>one year</b></li>
                                <li>Save time zone mappings for each user or channel</li>
                                <li>Pause recurring messages if you are away</li>
                                <li>Attach files to scheduling messages via web app</li>
                                <li>Get Slackbot notifications for scheduled messages</li>
                                <li>Edit/Clone scheduled messages</li>
                                <li>Control individual Timy permissions (Admin only)</li>
                            </ul>
                            <p className="Paragraph--Normal Flex-Align--Left">PRO version will cost only,
                            </p>
                            <div className="Paragraph--Normal ProPricingDetails Flex-Align--Left">
                                <p><b>{Constants.PADDLE.PRICES.YEARLY}</b> per <b>workspace</b>, per month, when billed <i>yearly</i></p>
                                <p><b>{Constants.PADDLE.PRICES.MONTHLY}</b> per <b>workspace</b>, per month, when billed <i>monthly</i></p>
                            </div>

                            {this.props.user.team && this.props.user.team.status === 0 ? <p className="Paragraph--Normal Flex-Align--Left">Start a 14 day trial and see it for yourself.<br />
                                No credit card required!</p> : null}

                        </div>

                    </div>

                    <div className="Popup__Footer">
                        <div className="Popup__Footer__Actions">
                            <GroupButton
                                onClick={this.handleClose}
                                classNames="Button--Secondary Margin-Right--12"
                                text="Cancel" />
                            {this.props.user.team && this.props.user.team.status === 0 ? <GroupButton
                                onClick={this.handleStartTrial}
                                classNames="Button--Primary"
                                text="Start 14 day Trial" /> : null}
                            {this.props.user.team && this.props.user.team.status === 2 ? <GroupButton
                                onClick={this.handleUpgradeToPro}
                                classNames="Button--Primary"
                                text="Upgrade to PRO" /> : null}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(ProFeaturePopup);