import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const FullScreenLoader = (props) => {
  return (
    props.isLoading ?
      <div className="FullScreenLoader">
        <ReactCSSTransitionGroup
          transitionName="ScaleIn"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}>
          <div className="FullScreenLoader__Container">
            <div className="LoadingSpinner"></div>
          </div>
        </ReactCSSTransitionGroup>
      </div>
      : null
  );
}

export default FullScreenLoader;