import LocalStorage from "./LocalStorage";
import axios from 'axios';
import Constants from './Constants';
import store from './../store/index';
import {
  connect
} from 'react-redux';
import {
  toast
} from 'react-toastify';

class Utils {
  static isLoggedIn() {
    return this.getCookie("_l") === "1";
  }

  static getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  static capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  static trackEvent(eventName) {
    window.dataLayer.push({
      'event': eventName
    });
  }


  static logout() {
    window.location.href = '/login';
  }

  static replaceAll = function (text, search, replacement) {
    if (text && typeof text === "string") {
      return text.replace(new RegExp(search, 'g'), replacement);
    } else {
      return text;
    }
  }

  static showToast(type, message) {
    toast.dismiss();
    switch (type) {
      case 'error':
        {
          toast.error(message, {
            autoClose: 4000
          });
          break;
        }
      case 'success':
        {
          toast.success(message, {
            autoClose: 3500
          });
          break;
        }
      default:
        { }
    }
  }

  static setBodyScroll(isNoScroll) {
    document.body.className = isNoScroll ? 'Model--Open' : '';
    document.getElementsByTagName('html')[0].className = isNoScroll ? 'Model--Open' : '';
  }

  static recursiveDomParser(nodeList) {
    let html = "";

    if (nodeList.length === 0) {
      return html;
    }

    nodeList.forEach(node => {
      node.childNodes.forEach(childNode => {
        if (childNode.nodeName === "#text") {
          html += Utils.escapeSlackMessage(childNode.textContent);
        } else if (childNode.nodeName === "IMG" || childNode.nodeName === "SPAN") {
          html += childNode.dataset.id;
        }
      });
      html += "\n";
    });

    if (this.replaceAll(html.trim(), "\n", "") === "") {
      return "";
    } else {
      return html;
    }

  }

  static escapeSlackMessage(input) {
    input = this.replaceAll(input, "&", "&amp;");
    input = this.replaceAll(input, "\n>", "\n&gt;");
    if (input && input[0] === '>') {
      input = input.replace(">", "&gt;");
    }
    input = this.replaceAll(input, "`>", "`_#_quote_#_");
    input = this.replaceAll(input, "<", "&lt;");
    input = this.replaceAll(input, "`_#_quote_#_", "`>");
    return input;
  }

  static getHumanReadableFileSize(bytes, si) {
    let thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    var units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  static hasMessagePermission(user) {
    return user.team && ((user.team.status !== 1 && user.team.status !== 3) ||
      (user.team.admin.access_control_state === Constants.ACCESS_CONTROL.ALLOW_ALL) ||
      (user.team.admin.access_control_state === Constants.ACCESS_CONTROL.ALLOW_ALL_EXCEPT && user.team.admin.filtered_users.indexOf(user.user_id) === -1) ||
      (user.team.admin.access_control_state === Constants.ACCESS_CONTROL.BLOCK_ALL_EXCEPT && user.team.admin.filtered_users.indexOf(user.user_id) !== -1))
  }

  static fetch(data, isRaw) {
    return new Promise((resolve, reject) => {

      axios(data).then((response) => {
        if (response.data.ok) {
          resolve(response.data);
        } else {

          //invalid token and we should logout the user
          if (response.data.error === Constants.ERROR_CODES.TOKEN_EXPIRED ||
            response.data.error === Constants.ERROR_CODES.ACCESS_DENIED) {
            this.logout();
          } else if (response.data.error && typeof response.data.error === 'string' && response.data.error.indexOf('Error:') !== -1) {
            store.dispatch({
              type: Constants.ACTIONS.SHOW_FS_ERROR_POPUP,
              payload: {
                isVisible: true,
                title: 'Oops :/',
                message: response.data.error
              }
            });

            reject({
              desc: response.data.error,
              payload: response.data.payload || {}, //Custom payload, will need in auth page
              code: Constants.ERROR_CODES.SLACK_ERROR,
            });

          } else {

            if (response.data.error && typeof response.data.error === "object") {
              reject({
                desc: response.data.error.desc || Constants.ERROR_CODES.UNKNOWN_ERROR,
                payload: {}, //Custom payload, will need in auth page
                code: response.data.error.code
              });
            } else {
              reject({
                desc: response.data.error || Constants.ERROR_CODES.UNKNOWN_ERROR,
                payload: response.data.payload || {}, //Custom payload, will need in auth page
                code: Constants.ERROR_CODES.SERVER_ERROR
              });
            }

          }

        }
      }).catch((err) => {
        reject({
          desc: Constants.ERROR_CODES.SERVER_ERROR,
          payload: {},
          code: 'SERVER_ERROR',
        });
      });

    });
  }

  static getTimeZones() {
    const timeZones = Constants.TIME_ZONES.map((timezone, index) => {
      return {
        "label": timezone[0],
        "value": timezone[1] + "___" + index
      }
    });

    timeZones.unshift({
      "label": "Your time zone",
      "value": "your_time_zone"
    });

    return timeZones;
  }

  static getRecurringTypes() {
    const recurringMessageTypes = Constants.RECURRING_TYPES.map((recurringType) => {
      return {
        "label": recurringType,
        "value": recurringType.toLowerCase().split(" ").join("_")
      }
    });
    return recurringMessageTypes;
  }

  static getHours() {

    let _hours = [];

    //Preparing hours
    for (let i = 1; i < 13; i++) {
      _hours.push(i < 10 ? "0" + i : i.toString());
    }

    const hours = _hours.map((hour) => {
      return {
        "label": hour,
        "value": hour
      }
    });

    return hours;

  }

  static getMins() {
    let _mins = [];

    //Preparing minutes
    for (let i = 0; i < 60; i++) {
      _mins.push(i < 10 ? "0" + i : i.toString());
    }

    const mins = _mins.map((min) => {
      return {
        "label": min,
        "value": min
      }
    });

    return mins;

  }

  static getAmPm() {
    const ampm = [{
      "label": "AM",
      "value": "am"
    }, {
      "label": "PM",
      "value": "pm"
    }];
    return ampm;
  }

}

export default connect()(Utils);