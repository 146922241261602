import Utils from "./Utils";

export default class Processor {

  // Process the message for message list page  
  static processMessageForChannel(props, message) {
    if (message.to_type === 'channel') {

      for (let i = 0; i < props.team_channels.length; i++) {
        if (props.team_channels[i].value === message.to_id) {
          message.target_channel = props.team_channels[i];
          break;
        }
      }

    } else if (message.to_type === 'complex') {
      const targetChannel = props.channel_ids[message.to_id];

      if (targetChannel && targetChannel.is_im) {

        message.target_user = props.user_ids[targetChannel.label];
        message.to_type = 'user';

      } else if (targetChannel && targetChannel.is_mpim) {

        message.to_type = 'group';
        message.target_channel = targetChannel;

        try {

          targetChannel.label = Utils.replaceAll(targetChannel.label, "--", "__");
          const people = targetChannel.label.split('-')[1];
          message.target_group = [];

          people.split('__').forEach(person => {
            for (let i = 0; i < props.team_users.length; i++) {
              if (props.team_users[i].name === person) {
                if (message.target_group.length < 5) {
                  message.target_group.push({
                    name: props.team_users[i].label,
                    avatar: props.team_users[i].image_192
                  });
                  message.more = 0;
                } else {
                  message.more += 1;
                }
                break;
              }
            }
          });

        } catch (ex) {
          message.target_group = [];
        }

      } else {
        message.to_type = 'channel';
        message.target_channel = targetChannel;
      }
    }

    return message;

  }

  static processMessageForUser(props, message) {
    for (let i = 0; i < props.team_users.length; i++) {
      if (message.to_type === 'user') {
        if (props.team_users[i].value === message.to_id) {
          message.target_user = props.team_users[i];
        }
      }
      message.messageHTML = Utils.replaceAll(message.messageHTML, `<span class="User">${props.team_users[i].value}</span>`,
        `<span class="User">@${props.team_users[i].display_name}
          <div class='UserNameTooltip'>
            <img alt="avatar" src='${props.team_users[i].image_192}' />
            <span>${props.team_users[i].label}</span>
          </div>
        </span>`);
    }
    return message;
  }

}