import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const Footer = (props) => {
  return (
    <div className='Footer'>
      <ReactCSSTransitionGroup
        transitionName="SlideIn"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        <div className="Footer__Links">        
          <a href="https://timy.website/">Home</a>·
          <a href="https://timy.website/privacy">Privacy</a>·
          <a href="https://timy.website/terms">Terms of Use</a>·
          <a href="https://timy.website/cookies">Cookie Policy</a>·        
          <a href="mailto:support@serandibsoft.com">Support</a>        
        </div>
      </ReactCSSTransitionGroup>
    </div>
  );
}

export default connect(mapStateToProps)(Footer);