import React from 'react';
import PubSub from 'pubsub-js';
import {
  withRouter
} from 'react-router-dom';
import queryString from 'query-string';
import Constants from '../../utils/Constants';
import Utils from './../../utils/Utils';
import FullScreenLoader from './../../components/FullScreenLoader';
import PreLoginSection from './../../components/PreLoginSection';
import {
  connect
} from 'react-redux';
import {
  getUserData,
  setActivePage
} from './../../actions/index';

class AuthPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      user: {
        avatar: '',
        name: ''
      },
      error_code: '',
      error_desc: ''
    }
  }

  redirectAfterLogIn(url = '') {
    PubSub.publish('initSocket', true);
    if (url) {     
      window.location.href = url;
    } else {    
      window.location.href = "/messages";
      // this.props.history.push('/messages');
    }
  }

  isAuthenticationError() {
    const {
      error,
      state
    } = queryString.parse(this.props.location.search);
    this.from = state;
    return (error && error === 'access_denied' && Constants.AUTH.STATES.indexOf(state) !== -1);
  }

  componentDidMount() {

    this.props.setActivePage('/auth');
    const { state = '' } = queryString.parse(this.props.location.search);

    if (this.isAuthenticationError()) {
      //an error from slack     
      this.setState({
        error_code: Constants.ERROR_CODES.ACCESS_DENIED,
        is_loading: false
      });
    } else if (this.from === 'login') {     
      //Just show the login screen
      this.setState({
        is_loading: false
      });
    } else if (Utils.isLoggedIn() && state !== "fromAddToSlackFiles" && state !== "fromAddToSlackBot") {     
      //user is already logged in, 
      //redirect to the messages
      this.redirectAfterLogIn();
    } else {    
      //user is not logged in
      //first check if the code and state is available in the url.
      //if they are exists and state is valid, the request is coming from slack 
      //after sign in with slack or after add to slack.
      //handle those scenarios in the resolve.
      this.getCodeAndState().then((authData) => {
        this.sendCodeAndState(authData);
      }).catch(() => {
        //no state or valid code is found.
        //redirect to the sign in with slack method to identify the user       
        window.location.href = Constants.AUTH.URLS.TO_LOGIN;
      });
    }
  }

  sendCodeAndState(authData) {
    Utils.fetch({
      method: 'post',
      url: Constants.API.Auth,
      data: {
        code: authData.code,
        state: authData.state
      }
    }).then(response => {

      this.setState({
        is_loading: false
      });

      //Redirect user to correct page
      switch (authData.state) {
        case 'fromAddToSlackFiles': {         
          this.redirectAfterLogIn('/messages?afterFilePermission=true');
          break;
        }
        case 'fromAddToSlackBot': {         
          this.redirectAfterLogIn('/settings?slackBotPermission=true');
          break;
        }
        default: {         
          this.redirectAfterLogIn();
          break;
        }
      }
      this.props.getUserData();

    }).catch(error => {
      this.setState({
        error_code: error.desc,
        error_desc: error.payload.error_desc,
        user: {
          name: error.payload.name,
          avatar: error.payload.avatar,
          team_name: error.payload.team_name
        },
        is_loading: false
      });
    });

  }

  getCodeAndState() {
    return new Promise((resolve, reject) => {
      const {
        code = '', state = ''
      } = queryString.parse(this.props.location.search);

      if (code && state && Constants.AUTH.STATES.indexOf(state) !== -1) {
        resolve({
          state,
          code
        })
      } else {
        reject();
      }
    });
  }

  render() {
    return (
      <div>
        <FullScreenLoader isLoading={this.state.is_loading} />

        {this.state.error_code === Constants.ERROR_CODES.NETWORK_ERROR ?
          <PreLoginSection title="Hmm..."
            isLogo={true}
            message="I couldn't connect to the server. Please make sure you're connected to the internet and give it a try again."
            url={Constants.AUTH.URLS.TO_LOGIN}
            buttonText="Sign in with Slack" /> : null}

        {this.from === 'login' ?
          <PreLoginSection title="Timy"
            isLogo={true}
            message="Sign in with Timy to send scheduled messages in your Slack workspace."
            url={Constants.AUTH.URLS.TO_LOGIN}
            buttonText="Sign in with Slack" /> : null}

        {this.state.error_code === Constants.ERROR_CODES.ACCESS_DENIED && this.from === 'fromAddToSlack' ?
          <PreLoginSection title="Hmm..."
            isLogo={true}
            message="It seems like Timy authentication process is interrupted. Make sure your workspace admin allows you to install apps."
            url={Constants.AUTH.URLS.TO_ADD_TO_SLACK}
            buttonText="Try Again" /> : null}

        {this.state.error_code === Constants.ERROR_CODES.ACCESS_DENIED && this.from === 'fromLogin' ?
          <PreLoginSection title="Hmm..."
            isLogo={true}
            message="It seems like Timy authentication process is interrupted. How about we try again and add timy to your workspace."
            url={Constants.AUTH.URLS.TO_LOGIN}
            buttonText="Try Again" /> : null}

        {Constants.AUTH.COMMON_ERROR_CODES.indexOf(this.state.error_code) !== -1 ?
          <PreLoginSection title="Oops..."
            isLogo={true}
            message="An error occurred while authenticating Timy with your workspace. How about we give it another try."
            url={Constants.AUTH.URLS.TO_LOGIN}
            buttonText="Sign in with Slack" /> : null}

        {Constants.AUTH.LOGIN_AGAIN_ERROR_CODES.indexOf(this.state.error_code) !== -1 ?
          <PreLoginSection title="Hmm..."
            isLogo={true}
            error={this.state.error_desc}
            message="Timy couldn't verify you with Slack. How about we give it another try and see what happens."
            url={Constants.AUTH.URLS.TO_LOGIN}
            buttonText="Sign in with Slack" /> : null}

        {Constants.AUTH.NO_PERMISSION_ERROR_CODES.indexOf(this.state.error_code) !== -1 ?
          <PreLoginSection
            imageUrl={this.state.user.avatar}
            isLogo={true}
            title={"Hi " + this.state.user.name + ","}
            message={"Timy needs your permission to send messages as you in <strong>" + this.state.user.team_name + "</strong>. You can grant permission by adding Timy to your workspace. Make sure your admin allows you to install apps for you."}
            url={Constants.AUTH.URLS.TO_ADD_TO_SLACK}
            buttonText="Authorize timy" /> : null}

        {Constants.AUTH.ADD_TO_SLACK_AGAIN_ERROR_CODES.indexOf(this.state.error_code) !== -1 ?
          <PreLoginSection
            title="Hmm..."
            isLogo={true}
            error={this.state.error_desc}
            message="Timy couldn't connect with your workspace. How about we give it another try."
            url={Constants.AUTH.URLS.TO_ADD_TO_SLACK}
            buttonText="Add timy to Slack" /> : null}
      </div>
    );
  }

}

export default withRouter(connect(null,
  {
    getUserData,
    setActivePage
  })(AuthPage));