import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import {
  showMessagePopup, getChannelListData, getUserListData,
  updateTimeZoneMemory, setMessageData, updateMessagePopup, discardMessagePopup
} from './../actions/index';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { _isValidTime } from './../utils/Validations'
import Utils from './../utils/Utils';
import Select from 'react-select';
import GroupButton from './GroupButton';
import SlideIn from './SlideIn';
import Constants from './../utils/Constants';
import TextEditor from './TextEditor';
import Message from './../pages/messages/Message';
import Processor from './../utils/Processor';
import LocalStorage from './../utils/LocalStorage';
import { PubSub } from 'pubsub-js';


const mapStateToProps = (state) => {
  return {
    user: state.user,
    message_popup: state.message_popup,
    is_team_users_loading: state.is_team_users_loading,
    is_team_channels_loading: state.is_team_channels_loading,
    team_users: state.team_users,
    team_channels: state.team_channels,
    team_direct_messages: state.team_direct_messages,
    channel_ids: state.channel_ids,
    user_ids: state.user_ids
  }
}

class MessagePopup extends Component {

  constructor(props) {
    super(props);

    this.timeZones = Utils.getTimeZones();
    this.recurringTypes = Utils.getRecurringTypes();
    this.hours = Utils.getHours();
    this.mins = Utils.getMins();
    this.ampm = Utils.getAmPm();

    this.dateFrom = new moment().add(-2, 'days');
    this.dateTo = new moment().add(1, 'years');

    this.STATE = {
      message_type: '',
      is_message_type_set: false,

      user_type: '',
      is_user_type_set: false,
      is_user_type_set_half: false,
      is_user_type_set_full: false,

      selected_user: '',
      selected_channel: '',

      message_text: '',
      message_html: '',
      is_message_content_set: false,

      when_type: '',
      is_when_type_set: false,
      is_when_type_set_half: false,
      is_when_type_set_full: false,
      at: '',
      in: '',
      formatted_time: '',

      is_message_type_error: true,
      is_user_type_error: true,
      is_message_text_error: true,
      is_when_type_error: true,
      is_date_set_error: true,

      is_submitted: false,

      is_message_ok: false,
      is_message_sending: false,
      is_message_parsing: false,

      export_message: {},
      is_text_focused: false,

      is_file_progress: false,
      file_data: null,

      timezone: this.timeZones[0],
      recurring_type: this.recurringTypes[0],
      date: new moment(),
      is_date_focused: false,
      hours: null,
      mins: null,
      ampm: null,

      scheduled_description: "",
      message_id: ''
    }

    this.state = { ...this.STATE };

  }

  isAccessAllowed = (fn) => {
    if (Utils.hasMessagePermission(this.props.user)) {
      if (fn && typeof fn === "function") {
        fn();
      }
    } else {
      PubSub.publish('showAccessDeniedPopup');
    }
  }

  componentDidMount() {

    this.handleFilePermissionRestoreState();

    this.handleNewMessageSubscriber = PubSub.subscribe('handleNewMessage', () => {
      this.isAccessAllowed(() => {
        Utils.trackEvent('messages:newMessage');
        this.props.showMessagePopup(true);
      });
    });

    this.handleNewScheduleMessageSubscriber = PubSub.subscribe('handleNewScheduleMessage', () => {
      this.isAccessAllowed(() => {
        this.handleSetMessageType('send');
        this.props.showMessagePopup(true);
      });
    });

    this.handleNewProMessageSubscriber = PubSub.subscribe('handleNewProMessage', () => {
      this.isAccessAllowed(() => {
        if (this.isProUser()) {
          this.handleSetMessageType('pro');
          this.props.showMessagePopup(true);
        } else {
          PubSub.publish('showProFeaturePopup');
        }
      });
    });

    this.handleNewSelfDestructMessageSubscriber = PubSub.subscribe('handleNewSelfDestructMessage', () => {
      this.isAccessAllowed(() => {
        this.handleSetMessageType('delete');
        this.props.showMessagePopup(true);
      });
    });

    this.handleEditProMessageSubscriber = PubSub.subscribe('handleEditProMessage', (action, message) => {
      this.isAccessAllowed(() => {
        this.props.showMessagePopup(true);
        this.props.updateMessagePopup(true);
        this.mapMessageToState(message);
      });
    });

    this.handleCloneProMessageSubscriber = PubSub.subscribe('handleCloneProMessage', (action, message) => {
      this.isAccessAllowed(() => {
        this.mapMessageToState(message, true);
        this.props.showMessagePopup(true);
        setTimeout(() => {
          PubSub.publish("focusTextEdit");
        }, 200);
      });
    });

  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.handleNewScheduleMessageSubscriber);
    PubSub.unsubscribe(this.handleNewMessageSubscriber);
    PubSub.unsubscribe(this.handleNewSelfDestructMessageSubscriber);
    PubSub.unsubscribe(this.handleEditProMessageSubscriber);
    PubSub.unsubscribe(this.handleCloneProMessageSubscriber);
    PubSub.unsubscribe(this.handleNewProMessageSubscriber);
  }

  mapMessageToState = ({ ...message }, isClonnedMessage) => {

    let selected_channel = '';
    if (message.target_channel) {
      message.to_type = "channel";
      selected_channel = message.target_channel;
    }

    this.setState({
      message_type: message.message_type,
      is_message_type_set: true,

      user_type: message.to_type,
      is_user_type_set: true,
      is_user_type_set_half: true,
      is_user_type_set_full: true,

      selected_user: message.target_user ? message.target_user : '',
      selected_channel,

      message_text: isClonnedMessage ? '' : message.message,
      message_html: isClonnedMessage ? '' : message.messageHTML,
      is_message_content_set: true,

      when_type: '',
      is_when_type_set: false,
      is_when_type_set_half: false,
      is_when_type_set_full: false,
      at: '',
      in: '',
      formatted_time: '',

      is_message_type_error: false,
      is_user_type_error: false,
      is_message_text_error: isClonnedMessage ? true : false,
      is_when_type_error: isClonnedMessage ? true : false,
      is_date_set_error: false,

      is_submitted: false,

      is_message_ok: false,
      is_message_sending: false,
      is_message_parsing: false,

      export_message: {},
      is_text_focused: false,

      is_file_progress: false,
      file_data: isClonnedMessage ? null : message.file,

      timezone: {
        label: message.proMessage.timezone.text.text,
        value: message.proMessage.timezone.value
      },
      recurring_type: {
        label: message.proMessage.send.text.text,
        value: message.proMessage.send.value
      },
      date: moment(message.utc_ts, "X"),
      is_date_focused: false,
      hours: {
        label: message.proMessage.hours.text.text,
        value: message.proMessage.hours.value
      },
      mins: {
        label: message.proMessage.mins.text.text,
        value: message.proMessage.mins.value
      },
      ampm: {
        label: message.proMessage.ampm.text.text,
        value: message.proMessage.ampm.value
      },

      scheduled_description: "",
      message_id: message.message_id
    });
  }

  handleFilePermissionRestoreState = () => {
    //Check if this page is redirected after a file permission change
    //If then, restore message content from the local storage and delete it.
    const { afterFilePermission } = queryString.parse(window.location.search);

    if (afterFilePermission && afterFilePermission === "true") {
      try {
        const previousMessageState = JSON.parse(LocalStorage.getItem("messageState"));
        const editorContents = JSON.parse(LocalStorage.getItem("editorContents") || "[]");

        //Fix date with moment
        if (previousMessageState.date) {
          previousMessageState.date = moment(previousMessageState.date, "YYYY-MM-DD");
        }

        if (previousMessageState) {
          this.props.showMessagePopup(true);
          this.setState({
            ...previousMessageState,
            is_text_focused: false
          }, () => {
            setTimeout(() => {
              PubSub.publish('restoreEditorContent', {
                editorContents,
                to: 'MessagePopup'
              });
            }, 200);
          });

          Utils.showToast('success', 'File permissions have been granted successfully.');
          window.history.pushState({}, document.title, window.location.pathname);
          LocalStorage.removeItem("messageState");
          LocalStorage.removeItem("editorContents");
        }
      } catch (ex) { }
    }

  }

  isProUser = () => {
    return (this.props.user.team.status === 1 || this.props.user.team.status === 3);
  }

  handleSetMessageType(type) {
    if (type === "send" || (type === "pro" && this.isProUser())) {

      this.setState({
        is_message_type_set: true,
        message_type: type,
        is_message_type_error: false,
        is_message_text_error: type !== "pro" ? (this.state.message_text === '') : (this.state.message_text === '' && !this.state.file_data)
      });

      // Retrieve all user data if not loaded. This is needed
      // when the popup is initiated from other pages
      if (this.props.team_users.length === 0) {
        this.props.getUserListData().then((res) => { });
      }

      if (this.props.team_channels.length === 0) {
        this.props.getChannelListData().then((res) => { });
      }
    } else {
      PubSub.publish('showProFeaturePopup');
    }

  }

  handleSetUserType(type) {

    this.setState({
      is_user_type_set: true,
      user_type: type
    }, () => {
      switch (type) {
        case 'user': {
          if (this.props.team_users.length === 0) {
            this.props.getUserListData().then((res) => {
              if (!res.ok) {
                //Revert back the selection
                Utils.showToast('error', res.desc);
                this.setState({
                  is_user_type_set: false,
                  is_user_type_error: this.state.selected_user === '',
                  user_type: ''
                });
              } else {
                this.setState({
                  is_user_type_set_half: true,
                  is_user_type_error: this.state.selected_user === ''
                });
                this.refs[type].focus();
              }
            });
          } else {
            this.setState({
              is_user_type_set_half: true,
              is_user_type_error: this.state.selected_user === ''
            });
            this.refs[type].focus();
          }
          break;
        }
        case 'channel': {
          if (this.props.team_channels.length === 0) {
            this.props.getChannelListData().then((res) => {
              if (!res.ok) {
                //Revert back the selection
                Utils.showToast('error', res.desc);
                this.setState({
                  is_user_type_set: false,
                  is_user_type_error: this.state.selected_channel === '',
                  user_type: ''
                });
              } else {
                this.setState({
                  is_user_type_set_half: true,
                  is_user_type_error: this.state.selected_channel === ''
                });
                this.refs[type].focus();
              }
            });
          } else {
            this.setState({
              is_user_type_set_half: true,
              is_user_type_error: this.state.selected_channel === ''
            });
            this.refs[type].focus();
          }
          break;
        }
        case 'group': {
          // if (this.props.team_users.length === 0) {
          //   this.props.getUserListData();
          // }
          break;
        }
        default: {

        }
      }
    });

  }

  handleMessageContent = (text) => {

    this.setState({
      message_text: text,
      is_message_text_error: text === '' && (!this.state.file_data && this.state.message_type === 'pro')
    });

    setTimeout(() => {
      this.setState({
        is_message_content_set: true
      });
    }, 1000);
  }

  handleClearUserType = () => {
    this.setState({
      is_user_type_set_half: false,
      user_type: '',
      is_user_type_error: true
    });
  }

  onUserSelected = (user) => {

    let isUserFilled = true;
    if (!this.state.is_user_type_set_full) {
      isUserFilled = false;
    }

    let initialTimeZone = Object.assign({}, this.timeZones[0]);

    this.props.team_direct_messages.forEach((dm) => {
      if (dm.label === user.value && this.props.user.timeZoneMemory) {
        const _slackInitialTimeZone = this.props.user.timeZoneMemory[dm.value];

        if (_slackInitialTimeZone) {
          initialTimeZone = {
            label: _slackInitialTimeZone.text.text,
            value: _slackInitialTimeZone.value
          }
        }

      }
    });

    this.setState({
      is_user_type_set_full: true,
      selected_user: user,
      is_user_type_error: false,
      timezone: initialTimeZone
    }, () => {
      if (!isUserFilled) {
        PubSub.publish('focusTextEdit');
      }
    });

  }

  onChannelSelected = (channel) => {
    let isChannelFilled = true;
    if (!this.state.is_user_type_set_full) {
      isChannelFilled = false;
    }

    let initialTimeZone = Object.assign({}, this.timeZones[0]);

    if (this.props.user.timeZoneMemory) {
      const _slackInitialTimeZone = this.props.user.timeZoneMemory[channel.value];
      if (_slackInitialTimeZone) {
        initialTimeZone = {
          label: _slackInitialTimeZone.text.text,
          value: _slackInitialTimeZone.value
        }
      }
    }

    this.setState({
      is_user_type_set_full: true,
      selected_channel: channel,
      is_user_type_error: false,
      timezone: initialTimeZone
    }, () => {
      if (!isChannelFilled) {
        PubSub.publish('focusTextEdit');
      }
    });
  }

  handleFileUploaded = (is_file_progress, file_data) => {
    this.setState({
      is_file_progress,
      file_data,
      is_message_content_set: true,
      is_message_text_error: (!file_data && this.state.message_type === 'pro') && this.state.message_text === ''
    })
  }

  handleWhenInInput = () => {
    const time = _isValidTime(this.refs.in.value, 'in');
    this.setState({
      in: this.refs.in.value,
      formatted_time: time,
      is_when_type_error: time === 'INVALID' || (time.h === 0 && time.m === 0)
    });
  }

  handleWhenAtInput = () => {
    const time = _isValidTime(this.refs.at.value, 'at');
    this.setState({
      at: this.refs.at.value,
      formatted_time: time,
      is_when_type_error: time === 'INVALID'
    });
  }

  handleSetWhenType(type) {
    this.setState({
      is_when_type_set: true,
      when_type: type
    }, () => {
      this.setState({
        is_when_type_set_half: true,
        in: '',
        at: ''
      });
      this.refs[type].focus();
    });
  }

  handleClearWhenType = () => {
    this.setState({
      is_when_type_set_half: false,
      when_type: '',
      is_when_type_error: true
    });
  }

  handleFormClose = () => {
    Utils.trackEvent('messages:newMessageCancel');
    this.props.showMessagePopup(false);
    this.setState({ ...this.STATE });
  }

  isValidForm(type) {
    return !this.state.is_message_type_error &&
      !this.state.is_user_type_error &&
      !this.state.is_message_text_error &&
      (this.state.message_type !== 'pro' ? !this.state.is_when_type_error : true) &&
      (this.state.message_type === 'pro' ? !this.state.is_date_set_error : true);
  }

  handleFormBack = () => {
    this.setState({
      is_message_ok: false
    });
  }

  handleTextBlur = () => {
    this.setState({
      is_text_focused: false
    });
  }

  handleTextFocus = () => {
    this.setState({
      is_text_focused: true
    });
  }

  getTo() {
    switch (this.state.user_type) {
      case 'user': {
        return this.state.selected_user;
      }
      case 'channel': {
        return this.state.selected_channel;
      }
      case 'group': {
        break;
      }
      default: { }
    }
  }

  getWhenTime() {
    switch (this.state.when_type) {
      case 'in': {
        return this.state.in;
      }
      case 'at': {
        return this.state.at;
      }
      default: { }
    }
  }

  handleMessageOK = () => {

    this.setState({
      is_submitted: true
    });

    const isValidForm = this.isValidForm();

    if (isValidForm) {

      this.setState({
        is_message_parsing: true
      });

      Utils.fetch({
        method: 'post',
        url: Constants.API.Message_Parse,
        data: {
          message: this.state.message_text,
          time: this.getWhenTime(),
          when_type: this.state.when_type,
          message_type: this.state.message_type,
          date: this.state.date ? this.state.date.format("YYYY-MM-DD") : new moment().format("YYYY-MM-DD"),
          timezone: this.state.timezone,
          recurring_type: this.state.recurring_type,
          hours: this.state.hours,
          mins: this.state.mins,
          ampm: this.state.ampm,
          file_data: this.state.file_data
        }
      }).then(response => {

        let export_message = {
          message_type: this.state.message_type,
          to_id: this.getTo().value,
          to_type: this.state.user_type !== 'user' ? 'complex' : 'user',
          messageHTML: response.data.messageHTML,
          messageLinkify: response.data.messageLinkify,
          message: this.state.message_text,
          version: 1,
          utc_ts: response.data.utcTS,
          is_next_day: response.data.isNextDay,
          scheduled_date: response.data.localDateTime
        }

        if (response.data.type === "send|delete") {

          export_message = Processor.processMessageForChannel(this.props,
            Processor.processMessageForUser(this.props, export_message));
          export_message.recurring_type = "Only once";
          export_message.utc_ts = response.data.utcTS;
          export_message.timezone = "Your time zone";
          export_message.file = null;

          this.setState({
            is_message_parsing: false,
            export_message,
            is_message_ok: true,
            scheduled_description: ''
          });

        } else if (response.data.type === "pro") {

          export_message.scheduled_date = response.data.date;
          export_message.file = this.state.file_data;
          export_message.timezone = this.state.timezone.label;
          export_message.recurring_type = this.state.recurring_type.label;
          export_message.is_active = true;

          export_message = Processor.processMessageForChannel(this.props,
            Processor.processMessageForUser(this.props, export_message));

          this.setState({
            is_message_parsing: false,
            export_message,
            is_message_ok: true,
            scheduled_description: response.data.dateScheduleDesc
          });

        }

      }).catch(error => {
        console.log(error);
        if (error.desc) {
          Utils.showToast('error', Utils.replaceAll(error.desc, "`", ""));
        } else {
          Utils.showToast('error', Constants.ERROR_CODES.UNKNOWN_ERROR);
        }
        this.setState({
          is_message_parsing: false,
          is_message_ok: false
        });
      });
    } else {
      this.setState({
        is_message_ok: false
      });
    }

  }

  handleMessageSend = () => {

    this.setState({
      is_message_sending: true
    });

    let to_id = this.getTo().value;
    let to_type = 'complex';

    if (this.state.user_type === 'user') {
      for (let i = 0; i < this.props.team_direct_messages.length; i++) {
        if (to_id === this.props.team_direct_messages[i].label) {
          to_id = this.props.team_direct_messages[i].value;
          break;
        }
      }
    }

    if (this.state.message_type === 'send') {
      Utils.trackEvent('messages:sendNewScheduledMessage');
    } else if (this.state.message_type === 'delete') {
      Utils.trackEvent('messages:sendNewSelfDestructMessage');
    } else if (this.state.message_type === 'pro') {
      Utils.trackEvent('messages:sendNewProMessage');
    }

    Utils.fetch({
      method: 'post',
      url: Constants.API.Message_Post,
      data: {
        from_id: `${this.props.user.team_id}___${this.props.user.user_id}`,
        message_type: this.state.message_type,
        to_type,
        to_id,
        message: this.state.export_message.messageLinkify,
        file_data: this.state.file_data,
        timezone: this.state.timezone,
        recurring_type: this.state.recurring_type,
        date: this.state.date.format("YYYY-MM-DD"),
        hours: this.state.hours,
        mins: this.state.mins,
        ampm: this.state.ampm,
        when_type: this.state.when_type,
        time: this.getWhenTime(),
        is_update: this.props.message_popup.is_update,
        message_id: this.state.message_id
      }
    }).then(response => {

      Utils.showToast('success', this.props.message_popup.is_update ? Constants.MESSAGES.MESSAGE_UPDATE_SUCCESS :
        Constants.MESSAGES.MESSAGE_POST_SUCCESS);

      this.props.setMessageData(response.data.messages);

      if (this.props.message_popup.is_update) {
        PubSub.publish('updateMessageTime', {
          id: this.state.message_id
        });
      }

      if (this.state.message_type === 'pro') {
        this.props.updateTimeZoneMemory({
          ...this.props.user.timeZoneMemory,
          [to_id]: {
            text: {
              text: this.state.timezone.label
            },
            value: this.state.timezone.value
          }
        });
      }

      this.handleFormClose();
      this.setState({
        is_message_sending: false
      });
    }).catch(error => {
      if (error.desc) {
        Utils.showToast('error', Utils.replaceAll(error.desc, "`", ""));
      } else {
        Utils.showToast('error', Constants.ERROR_CODES.UNKNOWN_ERROR);
      }

      this.setState({
        is_message_sending: false
      });
    });

  }

  handleGrantFilePermission = (editorContents) => {
    let currentState = { ...this.state };
    currentState.date = currentState.date.format("YYYY-MM-DD");
    LocalStorage.setItem('messageState', currentState, true);
    LocalStorage.setItem('editorContents', editorContents, true);
    window.location.href = Constants.AUTH.URLS.TO_ADD_TO_SLACK_FILES;
  }

  onRecurringTypeSelected = (selectedRecurringType) => {
    this.setState({
      recurring_type: selectedRecurringType
    });
  }

  onTimeZoneSelected = (selectedTimeZone) => {
    this.setState({
      timezone: selectedTimeZone
    });
  }

  handleDateChange = (date) => {
    this.setState({
      date
    }, () => {
      this.checkIsValidDateSet();
    });
  }

  handleDateFocusChange = (focuser) => {
    this.setState({
      is_date_focused: focuser.focused
    });
  }

  handleIsDateOutsideRange = (date) => {
    if (date.isBetween(this.dateFrom, this.dateTo)) {
      return false;
    } else {
      return true;
    }
  }

  checkIsValidDateSet = () => {
    const isValidDate = (this.state.hours && this.state.mins && this.state.ampm);
    this.setState({
      is_date_set_error: !isValidDate
    });
    return isValidDate;
  }

  onHoursSelected = (hours) => {
    this.setState({
      hours
    }, () => {
      this.checkIsValidDateSet();
    });
  }

  onMinsSelected = (mins) => {
    this.setState({
      mins
    }, () => {
      this.checkIsValidDateSet();
    });
  }

  onAmPmSelected = (ampm) => {
    this.setState({
      ampm
    }, () => {
      this.checkIsValidDateSet();
    });
  }

  handleDiscardMessage = () => {
    this.props.discardMessagePopup(true);
    this.setState({
      message_text: ''
    }, () => {
      PubSub.publish("setFileData", {
        file_data: this.state.file_data,
        from: 'MessagePopup'
      });
      PubSub.publish("focusTextEdit");
    });
  }

  customUserSingleValue = ({ data }) => (
    <div className="select__single-value CustomSelectValue">
      <img alt="Avatar" className="CustomSelectValue__Avatar" src={data.image_192} />
      <span title={data.label} className="CustomSelectValue__Text">{data.label}</span>
      {data.is_bot ? <span className="AppTag">APP</span> : null}
    </div>
  );

  customUserOptionLabel = (data) => (
    <>
      <img alt="Avatar" className="CustomSelectValue__Avatar" src={data.image_192} />
      <span title={data.label} className="CustomSelectValue__Text">{data.label}</span>
      {data.is_bot ? <span className="AppTag">APP</span> : null}
    </>
  );

  customChannelOptionLabel = (data) => {

    let label = '';

    if (data.is_private && data.is_mpim) {
      label = Utils.replaceAll(data.label, "mpdm-", "");
      label = Utils.replaceAll(label, "--", "__").split("__").join(" + ");
    } else {
      label = data.label;
    }

    return (
      <>
        {data.is_private && !data.is_mpim ? <div className="Message__Avatar--Channel" >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
            viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="11"
              width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
          </svg>
        </div> : ((data.is_private && data.is_mpim) ? <div className="Message__Avatar--Channel" >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
            fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4">
            </circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75">
            </path>
          </svg></div> : <div className="Message__Avatar--Channel" >#</div>)}
        <span title={label} className="CustomSelectValue__Text">{label}</span>
      </>);
  }

  customChannelSingleValue = ({ data }) => {

    let label = '';

    if (data.is_private && data.is_mpim) {
      label = Utils.replaceAll(data.label, "mpdm-", "");
      label = Utils.replaceAll(label, "--", "__").split("__").join(" + ");
    } else {
      label = data.label;
    }

    return (
      <div className="select__single-value CustomSelectValue">
        {(data.is_private && !data.is_mpim) ? <div className="Message__Avatar--Channel" >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
            viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="11"
              width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
          </svg>
        </div> : ((data.is_private && data.is_mpim) ? <div className="Message__Avatar--Channel" >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
            fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4">
            </circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75">
            </path>
          </svg></div> : <div className="Message__Avatar--Channel" >#</div>)}
        <span title={label} className="CustomSelectValue__Text">{label}</span>
      </div>);
  }



  render() {
    return (
      <div role="dialog" aria-labelledby="newMessage" tabIndex="-1" aria-describedby="createNewMessage"
        className={"Popup__Overlay" + (this.props.message_popup.is_visible ? ' Open' : '')}>
        <div role="document" className="Popup__Container">
          <div className="Popup__Head">
            <SlideIn isShow={!this.state.is_message_ok && !this.props.message_popup.is_update}><h3>New Message</h3></SlideIn>
            <SlideIn isShow={!this.state.is_message_ok && this.props.message_popup.is_update}><h3>Edit Message</h3></SlideIn>
            <SlideIn isShow={this.state.is_message_ok}><h3>Please Confirm</h3></SlideIn>
          </div>

          <div className={"Popup__Body" + (!this.state.is_message_ok ? " " : " Hide")}>

            <div className="Popup__Action__Item">
              <div className={"Popup__Action__Item__Title" + (this.state.is_submitted && this.state.is_message_type_error ? ' Error' : '')}>
                1. What kind of message ?
              </div>
              <div className="Popup__Action__Item__Content">
                <div className="Button__Panel Relative">
                  <button onClick={this.handleSetMessageType.bind(this, 'pro')}
                    className={"Button Group__Button Margin-Right--12 Min__Width--88" +
                      (this.state.message_type === 'pro' ? ' Selected' : '')}>Schedule
                  </button>

                  {this.props.user.team && (this.props.user.team.status === 0 || this.props.user.team.status === 2) ? <span className="ProBadge ScheduleButton__ProBadge"><b>PRO</b></span> : null}

                  {!this.props.message_popup.is_update ? <button onClick={this.handleSetMessageType.bind(this, 'send')}
                    className={"Button Group__Button Margin-Right--12 Min__Width--88" +
                      (this.state.message_type === 'send' ? ' Selected' : '')}>Send</button> : null}

                  {/* <button onClick={this.handleSetMessageType.bind(this, 'delete')}
                    className={"Button Group__Button Min__Width--88" +
                      (this.state.message_type === 'delete' ? ' Selected' : '')}>Delete</button> */}
                </div>
              </div>
            </div>

            {this.state.is_message_type_set ?
              <ReactCSSTransitionGroup
                transitionName="SlideIn"
                transitionAppear={true}
                className="Item To_Whom"
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeave={false}>

                <div className="Popup__Action__Item">
                  <div className={"Popup__Action__Item__Title" + (this.state.is_submitted && this.state.is_user_type_error ? ' Error' : '')}>
                    2. To whom ?
                  </div>
                  <div className="Popup__Action__Item__Content Min__Height--40">

                    <div className={"Button__Panel Z--Index--2 Absolute" + (this.state.is_user_type_set_half ? ' Hide' : '')}>

                      <GroupButton
                        isDisabled={this.props.is_team_users_loading}
                        onClick={this.handleSetUserType.bind(this, 'user')}
                        isSelected={this.state.user_type === 'user'}
                        classNames="Group__Button Margin-Right--12"
                        isLoading={this.props.is_team_users_loading}
                        text="User" />

                      <GroupButton
                        isDisabled={this.props.is_team_channels_loading}
                        onClick={this.handleSetUserType.bind(this, 'channel')}
                        isSelected={this.state.user_type === 'channel'}
                        classNames="Group__Button Margin-Right--12"
                        isLoading={this.props.is_team_channels_loading}
                        text="Channel" />

                      {/* <GroupButton
                        isDisabled={this.props.is_team_channels_loading}
                        onClick={this.handleSetUserType.bind(this, 'group')}
                        isSelected={this.state.user_type === 'group'}
                        classNames="Group__Button"
                        isLoading={this.props.is_team_channels_loading}
                        text="Group" /> */}

                    </div>

                    <div className={"Button__Panel Z--Index--2 Absolute Hide" +
                      (this.state.is_user_type_set_half && this.state.user_type === 'user' ? ' Show' : '')}>
                      <div className="Input__Group">
                        <button className="Button Left__Radius Group__Button Selected Margin--0 height--40">
                          User
                        </button>

                        <Select
                          className="User__Search"
                          classNamePrefix="select"
                          defaultValue={null}
                          isDisabled={false}
                          isLoading={false}
                          menuPlacement="top"
                          maxMenuHeight={185}
                          isClearable={false}
                          isRtl={false}
                          openMenuOnFocus={true}
                          isSearchable={true}
                          noOptionsMessage={() => 'No users found'}
                          placeholder=""
                          onChange={this.onUserSelected}
                          ref="user"
                          name="users"
                          components={{ SingleValue: this.customUserSingleValue }}
                          formatOptionLabel={this.customUserOptionLabel}
                          value={this.state.selected_user}
                          options={this.props.team_users} />


                        <svg onClick={this.handleClearUserType} className="Clear__Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                          strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line></svg>
                      </div>
                    </div>

                    <div className={"Button__Panel Z--Index--2 Absolute Hide" +
                      ((this.state.is_user_type_set_half && this.state.user_type === 'channel') ? ' Show' : '')}>
                      <div className="Input__Group">
                        <button className="Button Left__Radius Group__Button Selected Margin--0 height--40">
                          Channel
                        </button>

                        <Select
                          className="User__Search"
                          classNamePrefix="select"
                          defaultValue={null}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          menuPlacement="top"
                          maxMenuHeight={185}
                          openMenuOnFocus={true}
                          isRtl={false}
                          noOptionsMessage={() => 'No channels found'}
                          placeholder=""
                          onChange={this.onChannelSelected}
                          isSearchable={true}
                          ref="channel"
                          value={this.state.selected_channel}
                          name="channels"
                          formatOptionLabel={this.customChannelOptionLabel}
                          components={{ SingleValue: this.customChannelSingleValue }}
                          options={this.props.team_channels} />

                        <svg onClick={this.handleClearUserType} className="Clear__Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                          strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line></svg>
                      </div>
                    </div>

                    {/*<div className={"Button__Panel Absolute Hide" +
                      ((this.state.is_user_type_set_half && this.state.user_type === 'group') ? ' Show' : '')}>
                      <div className="Input__Group">
                        <button className="Button Left__Radius Group__Button Selected">
                          User Group
                        </button>
                        <input placeholder="Group Name"
                          onChange={this.handleGroupTextInput}
                          value={this.state.group_name}
                          ref="group"
                          className="User__Search" type="text" />
                        <svg onClick={this.handleClearUserType} className="Clear__Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                          strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line></svg>
                      </div>
                      </div>*/}

                  </div>
                </div>
              </ReactCSSTransitionGroup> : null}


            <SlideIn className="Item" isShow={this.state.is_user_type_set_full}>

              <div className={"Popup__Action__Item Message__Text Z--Index--0" + (this.state.is_text_focused ? ' Z--Index--5' : '')}>
                <div className={"Popup__Action__Item__Title" + (this.state.is_submitted && this.state.is_message_text_error ? ' Error' : '')}>
                  3. Saying what ?
                  </div>
                <div className="Popup__Action__Item__Content">
                  <div className="Button__Panel">

                    <SlideIn isShow={this.props.message_popup.is_update && !this.props.message_popup.is_discard}>
                      <div className="MessageContainer Padding--0">
                        <div className="Message NoShadow">
                          <div className="Message__Body">
                            <div dangerouslySetInnerHTML={{ __html: this.state.message_html }} className="Message_Content"></div>
                            {this.state.file_data ? <a className="Link__Plain" href={this.state.file_data.url} target="_blank" rel="noopener noreferrer"><div className="MessageComposerParent MessageFileBlock">
                              <div className="FileBlock">
                                <div className="MessageCompletedBlock">
                                  <svg className="MessageCompletedBlock__Icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
                                    stroke="#5a44ff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M13 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V9l-7-7z" /><path d="M13 3v6h6" />
                                  </svg>
                                  <div title={this.state.file_data.name} className="MessageCompletedBlock__Desc">
                                    <p className="MessageCompletedBlock__Desc--Title">{this.state.file_data.name}</p>
                                    <p className="MessageCompletedBlock__Desc--SubTitle">{this.state.file_data.size} {this.state.file_data.type}</p>
                                  </div>
                                </div>
                              </div>
                            </div></a> : null}
                          </div>
                        </div>
                        <svg title="Clear message content" onClick={this.handleDiscardMessage} className="Message__Discard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                      </div>
                      <div className="InformationNotice PaddingLeft--24">
                        Message text is not editable. You can either clear or clone this message.
                        <svg className="Left--0" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#ffd400"
                          stroke="#4c4c4c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line>
                          <line x1="12" y1="8" x2="12" y2="8"></line>
                        </svg>
                      </div>
                    </SlideIn>

                    <SlideIn isShow={!(this.props.message_popup.is_update && !this.props.message_popup.is_discard)}>
                      <TextEditor
                        onChange={this.handleMessageContent}
                        selectedUser={this.state.selected_user}
                        selectedChannel={this.state.selected_channel}
                        userType={this.state.user_type}
                        onFocus={this.handleTextFocus}
                        onBlur={this.handleTextBlur}
                        messageType={this.state.message_type}
                        isFileSupported={true}
                        onFileUploaded={this.handleFileUploaded}
                        onGrantFilePermission={this.handleGrantFilePermission}
                        channelName={this.state.selected_channel.label}></TextEditor>
                    </SlideIn>


                  </div>
                </div>
              </div>
            </SlideIn>

            {this.state.is_message_content_set && this.state.message_type !== "pro" ?
              <ReactCSSTransitionGroup
                transitionName="SlideIn"
                transitionAppear={true}
                className="Item When"
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeave={false}>

                <div className="Popup__Action__Item">
                  <div className={"Popup__Action__Item__Title" + (this.state.is_submitted && this.state.is_when_type_error ? ' Error' : '')}>
                    4. When ?
                  </div>

                  <div className={"Popup__Action__Item__Content Min__Height--40"}>

                    <div className={"Button__Panel Absolute" + (this.state.is_when_type_set_half ? ' Hide' : '')}>
                      <GroupButton
                        isDisabled={false}
                        onClick={this.handleSetWhenType.bind(this, 'in')}
                        isSelected={this.state.when_type === 'In'}
                        classNames="Group__Button Margin-Right--12"
                        text="In" />
                      <GroupButton
                        isDisabled={false}
                        onClick={this.handleSetWhenType.bind(this, 'at')}
                        isSelected={this.state.when_type === 'At'}
                        classNames="Group__Button Margin-Right--12"
                        text="At" />
                    </div>

                    <div className={"Button__Panel Absolute Hide" +
                      (this.state.is_when_type_set_half && this.state.when_type === 'in' ? ' Show' : '')}>

                      <div className="Input__Group">
                        <button className="Button Left__Radius Group__Button Selected Margin--0 height--40">
                          In
                        </button>

                        <input placeholder="1m  &#183; 1h  &#183; 2h30m  &#183; 24h"
                          onChange={this.handleWhenInInput}
                          value={this.state.in}
                          ref="in"
                          className="Time__Search" type="text" />

                        <svg onClick={this.handleClearWhenType} className="Clear__Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                          strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line></svg>
                      </div>
                    </div>

                    <div className={"Button__Panel Absolute Hide" +
                      ((this.state.is_when_type_set_half && this.state.when_type === 'at') ? ' Show' : '')}>

                      {/*<SlideIn isShow={this.state.is_when_type_set_half && this.state.when_type === 'at' && this.state.at === ''}>
                        <div className="Time_Tip">
                          <p>8pm &#183; 13.35 &#183; 8:24pm &#183; 1.10am &#183; 3.24</p>
                        </div>
                       </SlideIn>*/}

                      <div className="Input__Group">

                        <button className="Button Left__Radius Group__Button Selected Margin--0 height--40">
                          At
                        </button>

                        <input placeholder="8pm &#183; 13.35 &#183; 8:24pm &#183; 3.24"
                          onChange={this.handleWhenAtInput}
                          value={this.state.at}
                          ref="at"
                          className="Time__Search" type="text" />

                        <svg onClick={this.handleClearWhenType} className="Clear__Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                          strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line></svg>
                      </div>

                    </div>

                  </div>


                </div>

              </ReactCSSTransitionGroup> : null}


            {this.state.is_message_content_set && this.state.message_type === "pro" ?
              <ReactCSSTransitionGroup
                transitionName="SlideIn"
                transitionAppear={true}
                className="Item When"
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeave={false}>

                <div className="Popup__Action__Item">
                  <div className="Popup__Action__Item__Title">
                    4. Time Zone ?
                  </div>

                  <div className={"Popup__Action__Item__Content Min__Height--40"}>

                    <div className={"Button__Panel Z--Index--2"}>
                      <div className="Input__Group">

                        <Select
                          className="TimeZone"
                          classNamePrefix="select"
                          defaultValue={this.state.timezone}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          openMenuOnFocus={true}
                          isRtl={false}
                          noOptionsMessage={() => 'No time zones found'}
                          placeholder=""
                          value={this.state.timezone}
                          onChange={this.onTimeZoneSelected}
                          isSearchable={true}
                          ref="timezone"
                          name="timezone"
                          options={this.timeZones} />

                      </div>

                    </div>

                  </div>

                </div>

              </ReactCSSTransitionGroup> : null}

            {this.state.is_message_content_set && this.state.message_type === "pro" ?
              <ReactCSSTransitionGroup
                transitionName="SlideIn"
                transitionAppear={true}
                className="Item When"
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeave={false}>

                <div className="Popup__Action__Item">
                  <div className="Popup__Action__Item__Title">
                    5. How often ?
                  </div>

                  <div className={"Popup__Action__Item__Content Min__Height--40"}>

                    <div className={"Button__Panel Z--Index--2"}>

                      <div className="Input__Group">

                        <button className="Button Left__Radius Group__Button Selected Margin--0 height--40">
                          Send
                        </button>

                        <Select
                          className="Repeat__Select"
                          classNamePrefix="select"
                          defaultValue={this.state.recurring_type}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          openMenuOnFocus={true}
                          menuPlacement="bottom"
                          maxMenuHeight={160}
                          isRtl={false}
                          noOptionsMessage={() => 'No matching recurring types found'}
                          placeholder=""
                          onChange={this.onRecurringTypeSelected}
                          isSearchable={true}
                          ref="recurring_type"
                          name="recurring_type"
                          options={this.recurringTypes} />

                      </div>

                    </div>

                  </div>

                </div>

              </ReactCSSTransitionGroup> : null}

            {this.state.is_message_content_set && this.state.message_type === "pro" ?
              <ReactCSSTransitionGroup
                transitionName="SlideIn"
                transitionAppear={true}
                className="Item When"
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeave={false}>

                <div className="Popup__Action__Item">
                  <div className={"Popup__Action__Item__Title" + (this.state.is_submitted && this.state.is_date_set_error ? ' Error' : '')}>
                    6. When ?
                  </div>

                  <div className={"Popup__Action__Item__Content Min__Height--40"}>

                    <div className={"Button__Panel Z--Index--2"}>

                      <div className="Input__Group">

                        {['only_once', 'every_2_weeks', 'every_month', 'every_year'].indexOf(this.state.recurring_type.value) !== -1 ?
                          <SingleDatePicker
                            date={this.state.date}
                            onDateChange={this.handleDateChange}
                            focused={this.state.is_date_focused}
                            onFocusChange={this.handleDateFocusChange}
                            numberOfMonths={1}
                            firstDayOfWeek={1}
                            openDirection='up'
                            readOnly={true}
                            isOutsideRange={this.handleIsDateOutsideRange}
                            transitionDuration={300}
                            hideKeyboardShortcutsPanel={true}
                            id="datePicker" /> : null}

                        <Select
                          className={['only_once', 'every_2_weeks', 'every_month', 'every_year'].indexOf(this.state.recurring_type.value) !== -1 ? "Middle__Select" : "LeftEdge__Select"}
                          classNamePrefix="select"
                          defaultValue={this.state.hours}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          openMenuOnFocus={true}
                          isRtl={false}
                          menuPlacement="top"
                          noOptionsMessage={() => 'Invalid hour'}
                          placeholder="hh"
                          onChange={this.onHoursSelected}
                          isSearchable={true}
                          ref="hours"
                          name="hours"
                          options={this.hours} />

                        <Select
                          className="Middle__Select"
                          classNamePrefix="select"
                          defaultValue={this.state.mins}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          openMenuOnFocus={true}
                          isRtl={false}
                          menuPlacement="top"
                          noOptionsMessage={() => 'Invalid minute'}
                          placeholder="mm"
                          onChange={this.onMinsSelected}
                          isSearchable={true}
                          ref="mins"
                          name="mins"
                          options={this.mins} />

                        <Select
                          className="RightEdge__Select"
                          classNamePrefix="select"
                          defaultValue={this.state.ampm}
                          isDisabled={false}
                          isLoading={false}
                          menuPlacement="top"
                          isClearable={false}
                          openMenuOnFocus={true}
                          isRtl={false}
                          noOptionsMessage={() => 'Invalid option'}
                          placeholder="a"
                          onChange={this.onAmPmSelected}
                          isSearchable={true}
                          ref="ampm"
                          name="ampm"
                          options={this.ampm} />

                      </div>

                    </div>

                  </div>

                </div>

              </ReactCSSTransitionGroup> : null}

          </div>



          <SlideIn isShow={this.state.is_message_ok}>
            <div className={"Popup__Body Summary" + (this.state.is_message_sending ? " Grayscale" : "")}>
              <div className="MessageContainer">
                <Message
                  message={this.state.export_message}
                  isActionable={false}
                  isUsersLoaded={true}
                  isChannelsLoaded={true}>
                </Message>
                {this.state.scheduled_description ?
                  <div className="InformationNotice">
                    {this.state.scheduled_description}
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#ffd400"
                      stroke="#4c4c4c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line>
                      <line x1="12" y1="8" x2="12" y2="8"></line>
                    </svg>
                  </div>
                  : null}
              </div>
            </div>
          </SlideIn>

          <div className="Popup__Footer">
            <div className="Popup__Footer__Actions">

              <SlideIn isShow={!this.state.is_message_ok}>
                <fieldset disabled={this.props.is_team_users_loading || this.props.is_team_channels_loading || this.state.is_message_parsing ||
                  (this.state.is_file_progress && this.state.message_type === 'pro')}>
                  <button onClick={this.handleFormClose}
                    className="Button Button--Secondary Margin-Right--12">Cancel</button>
                  <GroupButton
                    onClick={this.handleMessageOK}
                    classNames="Button--Primary"
                    isLoading={this.state.is_message_parsing}
                    text="Next >" />
                </fieldset>
              </SlideIn>

              <SlideIn isShow={this.state.is_message_ok}>
                <fieldset disabled={this.props.is_team_users_loading || this.props.is_team_channels_loading || this.state.is_message_sending}>
                  <button onClick={this.handleFormBack}
                    className="Button Button--Secondary Margin-Right--12">Back</button>
                  <GroupButton
                    onClick={this.handleMessageSend}
                    classNames="Button--Primary"
                    isLoading={this.state.is_message_sending}
                    text="OK" />
                </fieldset>
              </SlideIn>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  showMessagePopup,
  getUserListData,
  getChannelListData,
  setMessageData,
  updateMessagePopup,
  discardMessagePopup,
  updateTimeZoneMemory
})(MessagePopup);