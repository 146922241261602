import React from 'react';
import { connect } from 'react-redux';
import { showMessagePopup } from './../../actions/index';
import SlideIn from '../../components/SlideIn';
import { PubSub } from 'pubsub-js';

class NoMessagesComponent extends React.Component {

  handleNewMessage = ()=>{
    PubSub.publish('handleNewMessage');    
  }

  render() {
    return (
      <SlideIn isShow={this.props.isShow}>
        <div className="NoMessages Minimal">
          <div className="NoMessages__Desc__Container">
            <div className="NoMessages__Logo"></div>
            <h3 className="NoMessages__Title">
              It's empty :\</h3>
            <p className="NoMessages__Text">
              Let's send a <span role="button" onClick={this.handleNewMessage} className="Inline--Link">New Message</span> and all your messages will appear here.
            </p>
          </div>
        </div>
      </SlideIn>
    )
  }
}

export default connect(null, { showMessagePopup })(NoMessagesComponent);