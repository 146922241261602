import Constants from './../utils/Constants';
import Utils from '../utils/Utils';

const initialState = {
  is_user_loading: false,
  active_page: '',
  user: {
    settings: {

    }
  },
  fullscreen_error_popup: {
    isVisible: false,
    title: '',
    message: ''
  },
  message_popup: {
    is_visible: false,
    is_update: false,
    is_discard: false
  },
  is_team_users_loading: false,
  is_team_channels_loading: false,
  team_users: [],
  team_channels: [],
  team_groups: [],
  channel_ids: {},
  user_ids: {},
  team_direct_messages: [],
  messages: [],
  is_messages_loaded: false,
  is_channels_loaded: false,
  is_users_loaded: false,
  is_groups_loaded: false,
  is_direct_messages_loaded: false
};

function rootReducer(state = initialState, action) {

  if (action.type === Constants.ACTIONS.SET_USER_LOADER) {
    return Object.assign({}, state, {
      is_user_loading: action.payload
    });
  }

  if (action.type === Constants.ACTIONS.GET_USER_DATA) {
    return Object.assign({}, state, {
      user: action.payload
    });
  }

  if (action.type === Constants.ACTIONS.UPDATE_TIMEZONE_MEMORY) {
    return Object.assign({}, state, {
      user: {
        ...state.user,
        timeZoneMemory: {
          ...action.payload
        }
      }
    });
  }

  if (action.type === Constants.ACTIONS.SHOW_FS_ERROR_POPUP) {
    Utils.setBodyScroll(action.payload.isVisible);
    return Object.assign({}, state, {
      fullscreen_error_popup: action.payload
    });
  }

  if (action.type === Constants.ACTIONS.SET_ACTIVE_PAGE) {
    return Object.assign({}, state, {
      active_page: action.payload
    });
  }

  if (action.type === Constants.ACTIONS.MESSAGE_POPUP_SHOW) {
    return Object.assign({}, state, {
      message_popup: {
        ...state.message_popup,
        is_visible: action.payload,
        is_update: false,
        is_discard: false
      }
    });
  }

  if (action.type === Constants.ACTIONS.MESSAGE_POPUP_UPDATE) {
    return Object.assign({}, state, {
      message_popup: {
        ...state.message_popup,
        is_update: action.payload
      }
    });
  }

  if (action.type === Constants.ACTIONS.DISCARD_POPUP_UPDATE) {
    return Object.assign({}, state, {
      message_popup: {
        ...state.message_popup,
        is_discard: action.payload
      }
    });
  }

  if (action.type === Constants.ACTIONS.SET_USERS_LIST_LOADING) {
    return Object.assign({}, state, {
      is_team_users_loading: action.payload
    });
  }

  if (action.type === Constants.ACTIONS.SET_CHANNEL_LIST_LOADING) {
    return Object.assign({}, state, {
      is_team_channels_loading: action.payload
    });
  }

  if (action.type === Constants.ACTIONS.SET_MESSAGES_LOADING) {
    return Object.assign({}, state, {
      is_messages_loading: action.payload
    });
  }

  if (action.type === Constants.ACTIONS.SET_USER_LIST_DATA) {
    return Object.assign({}, state, {
      team_users: action.payload,
      is_users_loaded: true
    });
  }

  if (action.type === Constants.ACTIONS.SET_USER_MESSAGES_DATA) {
    return Object.assign({}, state, {
      messages: action.payload,
      is_messages_loaded: true
    });
  }

  if (action.type === Constants.ACTIONS.SET_CHANNEL_LIST_DATA) {
    return Object.assign({}, state, {
      team_channels: action.payload,
      is_channels_loaded: true
    });
  }

  if (action.type === Constants.ACTIONS.SET_GROUP_LIST_DATA) {
    return Object.assign({}, state, {
      team_groups: action.payload,
      is_groups_loaded: true
    });
  }

  if (action.type === Constants.ACTIONS.SET_DIRECT_MESSAGE_LIST_DATA) {
    return Object.assign({}, state, {
      team_direct_messages: action.payload,
      is_direct_messages_loaded: true
    });
  }

  if (action.type === Constants.ACTIONS.SET_CHANNEL_IDS) {
    return Object.assign({}, state, {
      channel_ids: action.payload,
      is_channels_loaded: true
    });
  }

  if (action.type === Constants.ACTIONS.SET_USER_IDS) {
    return Object.assign({}, state, {
      user_ids: action.payload,
      is_users_loaded: true
    });
  }

  if (action.type === Constants.ACTIONS.UPDATE_USER_SETTINGS) {
    return Object.assign({}, state, {
      user: {
        ...state.user,
        settings: {
          ...action.payload
        }
      }
    });
  }

  if (action.type === Constants.ACTIONS.UPDATE_TEAM_SETTINGS) {
    return Object.assign({}, state, {
      user: {
        ...state.user,
        team: {
          ...action.payload
        }
      }
    });
  }

  return state;
};

export default rootReducer;