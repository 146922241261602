import React from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { NavLink } from 'react-router-dom';
import Utils from '../utils/Utils';
import { showMessagePopup } from './../actions/index';
import SlideIn from './SlideIn';
import Constants from './../utils/Constants';
import TextLoop from "react-text-loop";
import { PubSub } from 'pubsub-js';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    active_page: state.active_page
  }
}

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggingOut: false
    };
    this.headerPages = ['/messages', '/cheatsheet', '/settings', '/billing'];
  }

  isActivePage = (pagePath) => {
    if (this.props.active_page === "/billing" && pagePath === "/settings") {
      return true;
    } else {
      return this.props.active_page === pagePath;
    }
  }

  componentDidMount = () => {
    PubSub.subscribe('handleLogout', () => {
      this.logout();
    });
  }

  logout = () => {

    Utils.trackEvent('logout');

    this.setState({
      isLoggingOut: true
    });

    Utils.fetch({
      method: 'post',
      url: Constants.API.User_Logout
    }).then(response => {
      Utils.logout();
      this.setState({
        isLoggingOut: false
      });
    }).catch(error => {
      Utils.showToast('error', error.desc);
      this.setState({
        isLoggingOut: false
      });
    });
  }

  handleNewMessage = () => {    
    PubSub.publish('handleNewMessage');
  }

  handleStartTrial = () => {
    PubSub.publish('showTrialStarter');
  }

  render() {
    return (
      <div className='Header'>

        {this.props.user.slack_profile && this.headerPages.indexOf(this.props.active_page) !== -1 ?
          <ReactCSSTransitionGroup
            transitionName="SlideIn"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>
            <div className='Header__Controls'>
              <div onClick={this.handleNewMessage} tabIndex="1" className='NewMessage'>
                New Message
              </div>
              <section tabIndex="0" className='Profile'>

                <div className="Profile__Image__Wrapper">
                  <img alt='avatar' className='Profile__Image' src={this.props.user.slack_profile.profile.image_72}></img>
                  {this.props.user ? <SlideIn isShow={this.props.user.team.status === 0 || this.props.user.team.status === 2}>
                    <div className="PlanTag Free">FREE</div>
                  </SlideIn> : null}
                  {this.props.user ? <SlideIn isShow={this.props.user.team.status === 1 || this.props.user.team.status === 3}>
                    <div className="PlanTag Pro">PRO</div>
                  </SlideIn> : null}
                </div>

                <div className="Profile_Details">
                  <span className="Profile_Details--Name">@{this.props.user.display_name}</span>
                  <span className="Profile_Details--Plan">{this.props.user.team_name}</span>
                  {/*<span className="Profile_Details--Plan">{this.props.user.plan.type} Plan</span>*/}
                </div>
                <svg className="Down__Arrow" xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                  viewBox="0 0 24 24" fill="none" stroke="#757575"
                  strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M6 9l6 6 6-6" />
                </svg>

                <div className="Popover">

                  <div onClick={this.logout} className="Popover__Item">
                    <p className="Popover__Item__Text">Sign out</p>
                  </div>

                </div>
              </section>
            </div>

            {this.props.user ? <SlideIn isShow={this.props.user.team.status === 0}>
              <div className="Info">
                <div className="Info__Desc">
                  <p className="Info__Text">
                    <span onClick={this.handleStartTrial} className="ActionLink">Start a 14 day trial</span> of PRO plan to,
                  </p>
                  <TextLoop interval={3000}>
                    <p>Send recurring messages (Every weekday, Every month...)</p>
                    <p>Send unlimited messages in any time zone.</p>
                    <p>Increase scheduling time limit up to <b>one year</b>.</p>
                    <p>Save time zone mappings for each user or channel.</p>
                    <p>Pause recurring messages if you are away.</p>
                    <p>Attach files to scheduling messages via web app.</p>
                    <p>Get Slackbot notifications for scheduled messages.</p>
                    <p>Edit/Clone scheduled messages.</p>
                    <p>Control individual Timy permissions (Admin only)</p>
                  </TextLoop>
                </div>
              </div>
            </SlideIn> : null}

            {this.props.user ? <SlideIn isShow={this.props.user.team.status === 1}>
              <div className="Info">
                <div className="Info__Desc">
                  <p className="Info__Text">
                    <NavLink className="Link__Plain" to="/billing">
                      <span className="ActionLink">Upgrade to PRO Plan</span>
                    </NavLink>
                  </p>
                  <p className="NoTextTruncate">Your workspace has <b>{this.props.user.team.pro.remainingTrialDates} {this.props.user.team.pro.remainingTrialDates > 1 ? 'days' : 'day'}</b> left in the trial period. Unless you upgrade to
                  the <b>PRO</b> plan, <span className="SlashCommand">/schedule</span>messages won't be delivered after the trial period.</p>
                </div>
              </div>
            </SlideIn> : null}

            {this.props.user ? <SlideIn isShow={this.props.user.team.status === 2}>
              <div className="Info">
                <div className="Info__Desc">
                  <p className="Info__Text">
                    <NavLink className="Link__Plain" to="/billing">
                      <span className="ActionLink">Upgrade to PRO Plan</span>
                    </NavLink> and you'll be able to,
                  </p>
                  <TextLoop className="TextLoop" interval={3000}>
                    <p>Send recurring messages (Every weekday, Every month...)</p>
                    <p>Send unlimited messages in any time zone.</p>
                    <p>Increase scheduling time limit up to <b>one year</b>.</p>
                    <p>Save time zone mappings for each user or channel.</p>
                    <p>Pause recurring messages if you are away.</p>
                    <p>Attach files to scheduling messages via web app.</p>
                    <p>Get Slackbot notifications for scheduled messages.</p>
                    <p>Edit/Clone scheduled messages.</p>
                    <p>Control individual Timy permissions (Admin only).</p>
                  </TextLoop>
                </div>
              </div>
            </SlideIn> : null}


            <nav className={"Navigation " + ((this.props.user && this.props.user.team && this.props.user.team.status !== 3) ? ' PaddingTop--16' : '')}>
              <NavLink to="/messages" isActive={this.isActivePage.bind(this, '/messages')} activeClassName="Nav--Selected">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                Messages
                </NavLink>
              <NavLink to="/cheatsheet" isActive={this.isActivePage.bind(this, '/cheatsheet')} activeClassName="Nav--Selected">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg>
                Cheatsheet</NavLink>
              <NavLink className="SettingsLink" to="/settings" isActive={this.isActivePage.bind(this, '/settings')} activeClassName="Nav--Selected">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                  fill="none" stroke="#929292" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line>
                  <line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line>
                  <line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line>
                  <line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line>
                </svg>
                Settings</NavLink>
            </nav>
          </ReactCSSTransitionGroup>
          : null}
      </div>
    );
  };

}

export default connect(mapStateToProps, { showMessagePopup })(Header);