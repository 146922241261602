import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class InfiniteProgressBar extends Component {

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="FadeIn"
        className={this.props.classNames}
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        {this.props.isShow ? <div className="Progress">
          <div className="Indeterminate"></div>
        </div> : null}
      </ReactCSSTransitionGroup>
    );
  }
}

export default InfiniteProgressBar;