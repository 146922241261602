import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class SlideIn extends Component {

  render() {    
    return (
      this.props.isShow ? 
      <ReactCSSTransitionGroup
      className={this.props.className}
      transitionName="SlideIn"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnterTimeout ={500}
      transitionEnter={true}
      transitionLeave={false}>     
        {this.props.children} 
      </ReactCSSTransitionGroup>: null     
     
    );
  }
}

export default SlideIn;